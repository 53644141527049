<script setup>
import { computed } from 'vue'

const props = defineProps({
	node: {
		type: Object,
		required: true
	},
	UserLevel: {
		type: Number,
		required: true
	}
})

const levelAvailable = computed(() => {
	console.log('node:', props.node.name, 'level:', props.node.level, 'userLevel:', props.UserLevel)
	return props.node.level <= props.UserLevel
})

// 获取地区代码
const regionCode = computed(() => {
	const match = props.node.name.match(/^([A-Za-z]{2})-/)
	return match ? match[1].toLowerCase() : 'un'
})

// 节点类型标签
const typeLabel = computed(() => {
	return {
		ssr: 'SSR',
		trojan: 'Trojan',
		vmess: 'V2Ray'
	}[props.node.type.toLowerCase()] || props.node.type
})

// 简化等级显示
const levelBadge = computed(() => {
	return {
		color: [
			'light',
			'secondary',
			'info',
			'danger'
		][props.node.level] || 'light',
		text: `Level ${props.node.level}`
	}
})

const getSpeedlimit = (speedlimit) => {
	return speedlimit === 0 ? '不限速' : `${speedlimit} Mbps`
}
</script>

<template>
	<div class="card node-card h-100" 
		 :class="{ 'node-card-disabled': !node.available }"
		 @click="$emit('click')">
		<div class="card-body p-3">
			<!-- 节点标题和状态 -->
			<div class="d-flex justify-content-between align-items-center mb-2">
				<div class="d-flex align-items-center">
					<!-- 地区旗帜和名称 -->
					<div class="me-2">
						<i :class="`flag flag-${regionCode}`"></i>
					</div>
					<div class="node-info">
						<h6 class="mb-0 text-sm">{{ node.name }}</h6>
						<span class="text-xxs lh-1 text-muted d-block mt-n1">{{ typeLabel }}</span>
					</div>
				</div>
				<span :class="`badge bg-gradient-${levelBadge.color} px-2 py-1`">
					{{ levelBadge.text }}
				</span>
			</div>

			<!-- 节点信息 -->
			<div class="mt-3">
				<div class="d-flex justify-content-between align-items-center">
					<span v-if="!levelAvailable" class="text-xs text-danger">
						<i class="fas fa-lock me-1"></i>
						等级不足
					</span>
					<span v-else-if="!node.online" class="text-xs text-danger">
						<i class="fas fa-times-circle me-1"></i>
						节点离线
					</span>
					<span v-else class="text-xs text-success">
						<i class="fas fa-check-circle me-1"></i>
						可用
					</span>
					<span class="text-xs text-muted">
						<i class="fas fa-tachometer-alt me-1"></i>
						{{ getSpeedlimit(node.speedlimit) }}
					</span>
				</div>
			</div>

			<!-- 等级不足遮罩 -->
			<div v-if="!levelAvailable" class="node-overlay cursor-not-allowed">
				<button class="btn btn-sm btn-primary" @click.stop="$router.push('/user/plan')">
					<i class="fas fa-arrow-up me-2"></i>
					升级套餐
				</button>
			</div>
		</div>
	</div>
</template>

<style scoped>
.node-card {
	transition: all 0.2s ease;
	cursor: pointer;
	border: 1px solid #e9ecef;
	position: relative;
	overflow: hidden;
}

.node-card:hover {
	transform: translateY(-3px);
	box-shadow: 0 3px 12px 0 rgba(0,0,0,.1);
	border-color: #5e72e4;
}

.node-card-disabled {
	opacity: 0.8;
}

.node-card-disabled:hover {
	transform: none;
	border-color: #e9ecef;
	box-shadow: none;
}

.node-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(255,255,255,0.9);
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	transition: opacity 0.2s ease;
}

.node-card:hover .node-overlay {
	opacity: 1;
}

/* 旗帜图标样式调整 */
.flag {
	width: 24px;
	height: 18px;
	display: inline-block;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 2px;
	box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.node-info {
	line-height: 1.2;
}

.mt-n1 {
	margin-top: -0.125rem !important;
}
</style>