<script setup>
import dataTable            from '@/components/argon-dashboard-pro-2/dataTable.vue';

import { useUserApi }       from '@/composables/useUserApi';
import { useCheckEmpty }    from '@/composables/useCheckEmpty';
</script>

<template>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-md-6 col-12 mt-2">
                <div class="card overflow-hidden h-100">
                    <div class="card-header pb-1">
                        <div class="row">
                            <div class="col-6">
                                <h5 class="mb-0 text-capitalize">页面公告</h5>
                            </div>
            
                            <div class="col-6 d-flex justify-content-end">
                                <button class="btn btn-xs btn-outline-info text-body disabled">此处可滑动</button>
                            </div>
                        </div>
                    </div>
            
                    <div class="card-body hidden-scrollbar pt-1" style="overflow-y: scroll;">
                        <p>大部分Log仅存留 72小时，无法提供更长时间的日志</p>
                        <p>查询任何类型的日志均会产生记录，请注意</p>
                        <p>请勿截图此页面，请勿传播此页面内容</p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-12 mt-2">
                <div class="card">
                    <div class="card-header">
                        <h5 class="mb-0 text-capitalize">日志类型</h5>
                    </div>

                    <div class="card-body pt-0">
                        <label>日志类型</label>
                        <SlimSelect v-model="logsType" :settings="{showSearch: false}">
                            <option value="">请选择</option>
                            <option value="login">登录日志</option>
                            <option value="audit">审计日志</option>
                            <option value="subscribe">订阅日志</option>
                            <option value="email">邮件日志</option>
                            <option value="invoice">账单日志</option>
                            <option value="order">订单日志</option>
                        </SlimSelect>

                        <button class="btn btn-primary mt-3 w-100" @click="getLogsData">点击获取</button>
                    </div>
                </div>
            </div>

            <div class="col-12 d-flex justify-content-center align-items-center" style="height: 60vh;" v-if="loading">
                <div class="spinner-border text-primary" role="status"></div>
                <span class="ms-3 text-dark text-bold">Loading...</span>
            </div>

            <dataTable tableName="日志数据" :tableClass="tableClass" :rawTableData="tableData" v-if="!loading && tableClass !== null" />
        </div>
    </div>
</template>

<script>
export default {
    name: '日志中心',
    components: { dataTable },
    data() {
        return {
            logsType: null,
            loading: false,
            tableData: {},
            tableClass: null,
        }
    },
    methods: {
        getLogsData() {
            if (useCheckEmpty().checkEmpty(this.logsType)) {
                this.$showToast('操作失败', '请选择日志类型', 'error');
                return;
            }

            this.loading = true;

            useUserApi().getMiscData(this.logsType)
            .then(result => {
                if (result.data.length === 0) {
                    this.$showToast('操作失败', '目标类型不存在符合条件的数据', 'error');
                    return;
                }

                this.tableData  = result.data;
                this.tableClass = this.logsType;
            })
            .catch(err => { console.error(err); })
            .finally(() => { 
                setTimeout(() => {
                    this.loading = false;
                }, 450);
            });
        },
    },
}
</script>