export function useCheckEmpty() {
    /**
     * 检查值是否为空
     * @param {any} obj - 要检查的值
     * @param {boolean} isCollection - 是否检查集合内的所有值
     * @returns {boolean} - 如果为空返回 true，否则返回 false
     */
    const checkEmpty = (obj, isCollection = false) => {
        // 基础空值检查
        if (obj === null || obj === undefined || obj === '') {
            return true;
        }
    
        // 字符串检查
        if (typeof obj === 'string') {
            return obj.trim() === '';
        }

        // 数字检查
        if (typeof obj === 'number') {
            return isNaN(obj);
        }

        // 集合检查
        if (isCollection) {
            // 数组检查
            if (Array.isArray(obj)) {
                if (obj.length === 0) return true;
                return obj.some(item => checkEmpty(item));
            }
            
            // 对象检查
            if (typeof obj === 'object') {
                if (Object.keys(obj).length === 0) return true;
                return Object.values(obj).some(value => checkEmpty(value));
            }
        }
    
        return false;
    };

    /**
     * isEmpty 的别名函数，保持向后兼容
     */
    const isEmpty = (obj, isCollection = false) => {
        return checkEmpty(obj, isCollection);
    };

    return {
        checkEmpty,
        isEmpty
    };
}