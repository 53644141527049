<script setup>
    import axios from '@/Plugins/axios';
</script>

<template>
    <div class="container-fluid py-4">
        <div class="row d-flex justify-content-center">
            <div class="card shadow-xl p-0 w-100 w-xxl-80">
                <div class="banner" :style="`background-image: url(${bannerURL})`" v-if="bannerURL"></div>
            </div>
        </div>

        <div class="row mt-5" v-if="enabled">
            <div class="col-3"></div>

            <div class="col-lg-6 col-12 mb-3">
                <div class="card border-dashed border-2 border-secondary border-radius-md w-100 hidden-scrollbar">
                    <div class="card-hedader text-center mt-4 pb-0" v-if="countdown">
                        <span class="fs-5 fw-bolder text-dark letter-spacing-5">{{ waitText }}</span>
                    </div>

                    <div class="card-body pt-0 m-4 mt-0 d-flex flex-row justify-content-center" v-if="countdown">
                        <div class="d-flex flex-row" v-for="(countdown, index) in countdown" :key="index">
                            <div class="d-flex flex-column">
                                <span class="countDown-text">{{ countdown }}</span>
                                <span class="countDown-desc">{{ index }}</span>
                            </div>
                            <!-- if not last item -->
                            <span class="countDown-divide" v-if="index !== 'seconds'">:</span>
                        </div>
                    </div>

                    <div class="card-body m-4 my-0 d-flex flex-row justify-content-center" v-else>
                        <div class="d-flex flex-column">
                            <span class="mb-0 fs-2 fw-bold text-primary text-center">活动正在进行中</span>
                            <span class="fs-6 fw-bold fst-italic text-center">Special Sales is in progress</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-12">
                <div class="card border-dashed border-2 border-success border-radius-md h-100">
                    <div class="card-header pb-1 d-flex justify-content-between">
                        <h5 class="mb-0">>_ 年度限定</h5>

                        <button class="btn btn-xs btn-dark mb-0" @click="getSaleData">前往购买</button>
                    </div>

                    <div class="card-body m-3 pb-0">
                        <div class="row">

                            <div class="col-12 px-0">
                                <div class="d-flex flex-column text-start justify-content-center">
                                    <div class="fs-2 color-sales mb-3 fw-bold">
                                        限量一折Lv.3 年付套餐释出
                                    </div>

                                    <div class="fs-4 color-normal fw-bold">
                                        在 <code class="color-sales">2025-01-29 00:00:00</code> 开放购买
                                    </div>

                                    <div class="fs-6 text-bold text-sm">
                                        *限购套餐无法通过账单购买，请确保账户余额充足。
                                    </div>

                                    <div class="fs-6 text-bold text-sm mt-2">
                                        *限购套餐无法续费，无法使用任何优惠，购买流程与限购套餐相同。
                                    </div>

                                    <div class="fs-6 text-bold text-sm mt-2">
                                        *限购套餐购买后可能需要10-15分钟确认，请耐心等待。
                                    </div>

                                    <div class="fs-6 text-bold text-sm mt-2 mb-4">
                                        *重复购买的用户将被取消多余订单并退款
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-12 mt-md-0 mt-3">
                <div class="card border-dashed border-2 border-success border-radius-md h-100">
                    <div class="card-header pb-1">
                        <h5 class="mb-0">>_ 随机优惠码</h5>
                    </div>

                    <div class="card-body m-3 pb-0">
                        <div class="row">

                            <div class="col-12 px-0">
                                <div class="d-flex flex-column text-start justify-content-center">
                                    <div class="form-group w-50 w-sm-100 mb-0">
                                        <label class="form-control-label text-dark">优惠码</label>
                                        <input type="text" class="form-control mb-0" v-model="randomCode" readonly>
                                    </div>
                                    <!-- <div class="fs-2 color-sales mb-3 fw-bold">
                                        
                                    </div> -->

                                    <div class="fs-4 color-sales fw-bold mb-2">
                                        随机抽取 4-8 折优惠数值
                                    </div>

                                    <div class="fs-6 text-bold text-sm">
                                        *随机优惠码仅限购买套餐时使用，允许续费 1次
                                    </div>


                                    <div class="fs-6 text-bold text-sm mt-2">
                                        *24小时内最多尝试 20 次，超出需要等待计数器重置
                                    </div>

                                    <div class="fs-6 text-bold text-sm mt-2">
                                        *滥用行为将导致订单被取消并退款
                                    </div>
                                    <div class="fs-6 text-bold text-sm mt-2 mb-4">
                                        *重复购买的用户将被取消多余订单并退款
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <hr class="horizontal dark my-5" />

            <div class="col-12">
                <div class="card border-dashed border-2 border-danger border-radius-md">
                    <div class="card-header pb-1 d-flex justify-content-between">
                        <h5 class="mb-0">>_ 活动优惠</h5>

                        <button class="btn btn-xs btn-dark mb-0" @click="getSaleData">刷新数据</button>
                    </div>

                    <div class="card-body m-3 pb-0">
                        <div class="row overflow-hidden">

                            <div class="col-lg-4 col-12 px-0">
                                <div class="d-flex flex-column text-start justify-content-center">
                                    <div class="fs-2 color-sales mb-3 fw-bold">
                                        在活动截止前
                                    </div>

                                    <div class="fs-4 color-normal fw-bold">
                                        在<span class="color-sales">购买套餐</span>时应用优惠码即可获得价格优惠
                                    </div>

                                    <div class="fs-6 text-bold text-sm">
                                        *优惠码可能存在使用限制，且余额不足的情况下生成的订单可能因剩余使用次数不足（已被其他用户使用）而导致退单，请尽量保证在余额充足的情况下生成订单。
                                    </div>

                                    <div class="fs-6 text-bold text-sm mt-2 mb-4">
                                        *优惠码如未特别说明，则最大续费次数为 1次
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-8 col-12 discountGroup hidden-scrollbar px-0" roles="discount">
                                
                                <div class="position-absolute top-0 start-0 shake" style="animation-iteration-count: 3;">
                                    <span class="badge badge-pill badge-success ms-1">左右滑动查看更多</span>
                                </div>

                                <div class="row discountRow">
                                    <div class="col-xxl-4 col-md-6 col-12 mb-2 ps-0" v-for="(discount, index) in discounts" :key="index" v-if="discounts">
                                        <div class="card border border-1 border-dark border-radius-md">
                                            <div class="card-body py-3">
                                                <div class="row">
                                                    <div class="col-6">
                                                        <span class="text-xs text-muted mb-2"># {{ index + 1 }}</span>

                                                        <p class="fs-4 fw-bold mb-0 text-nowrap text-dark">{{ discount.name }}</p>
                                                        <p class="text-sm fw-bold mb-0 text-nowrap">
                                                            剩余次数：
                                                            <span class="fw-bold">{{ discount.type === 3 ? '无限制' : `${discount.remain}次` }} </span>
                                                        </p>
                                                        <p class="text-sm fw-bold mb-0 text-nowrap">
                                                            限制套餐：
                                                            <span class="fw-bold">{{ discount.limit_shop }}</span>
                                                        </p>
                                                    </div>

                                                    <div class="col-6 text-end">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p class="fs-3 fw-bold color-sales mb-0">{{ discount.value }}%</p>
                                                                <p class="fs-6 fst-italic mt-n2">OFF</p>
                                                            </div>

                                                            <div class="col-12 d-flex justify-content-end">
                                                                <div class="form-group mb-0 mt-n2">
                                                                    <label class="form-control-label text-sm fw-bold text-end">优惠码</label>
                                                                    <input type="text" class="form-control form-control-sm text-center border border-danger" :value="discount.code" readonly>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="col-12">
                                <hr class="horizontal dark mb-5">
                            </div>

                            <div class="col-lg-4 col-12 px-0">
                                <div class="d-flex flex-column text-start justify-content-center">
                                    <div class="fs-2 color-sales mb-3 fw-bold">
                                        优惠码操作太麻烦？
                                    </div>

                                    <div class="fs-4 color-normal fw-bold">
                                        选择<span class="color-sales">限购套餐</span>，点击立即购买即可完成订购
                                    </div>

                                    <div class="fs-6 text-bold text-sm">
                                        *限购套餐不可使用优惠码，且不可续费，无法在余额不足的情况下生成订单。限购套餐会在特定时间后开放购买，具体信息请参照套餐中心页面展示。
                                    </div>

                                </div>
                            </div>

                            <div class="col-lg-8 col-12 discountGroup hidden-scrollbar px-0" roles="limitShops">
                                <div class="position-absolute top-0 start-0 shake" style="animation-iteration-count: 3;">
                                    <span class="badge badge-pill badge-success ms-1">左右滑动查看更多</span>
                                </div>

                                <div class="row discountRow">
                                    <div class="col-xxl-4 col-md-6 col-12 mb-2 ps-0" v-for="(shop, index) in limitShops" :key="index" v-if="limitShops">
                                        <div class="card border border-1 border-dark border-radius-md">
                                            <div class="card-header pb-0">
                                                <div class="d-flex justify-content-between">
                                                    <p class="text-xs text-muted mb-0"># {{ index + 1 }}</p>

                                                    <p class="text-sm fw-bold mb-0 text-nowrap">
                                                        剩余：
                                                        <span class="fw-bolder">{{ shop.stock }} 份</span>
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="card-body pt-0">
                                                <div class="row text-dark">
                                                    <div class="col-12">
                                                        <p class="fs-5 fw-bold mb-0 text-nowrap">{{ shop.name }}</p>
                                                    </div>

                                                    <div class="col-12 text-end">
                                                        <div class="row">
                                                            <div class="col-12 d-none">
                                                                <p class="fs-3 fw-bold color-sales mb-0 text-nowrap">{{ shop.price }}</p>
                                                                <p class="fs-6 fst-italic mt-n2">元 / CNY</p>
                                                            </div>

                                                            <div class="col-12">
                                                                <div class="d-flex justify-content-between">
                                                                    <p class="text-sm fw-bold mb-0 text-nowrap">
                                                                        价格：
                                                                        <span class="fw-bold">{{ shop.price }} 元</span>
                                                                    </p>

                                                                    <div>
                                                                        <router-link class="btn btn-xs btn-outline-secondary mb-0" type="button" :to="{ name: '套餐中心' }" v-if="shop.stock !== 0">
                                                                            前往购买
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-12 text-center pt-2 mb-n2">
                                                        <!-- if date early then releasedAt(to timestamp) -->
                                                        <div v-if="new Date().getTime() < new Date(shop.released_at).getTime()">
                                                            <span class="text-bold text-sm color-normal">{{ shop.released_at }} 起售</span>
                                                        </div>

                                                        <div v-else>
                                                            <span class="text-bold text-sm color-normal">此套餐已售罄，请等待补充</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>

            <hr class="horizontal dark my-5" />

            <div class="col-12">
                <div class="card border-dashed border-2 border-primary border-radius-md">
                    <div class="card-header pb-1">
                        <h5 class="mb-0">>_ 消费返利</h5>
                    </div>

                    <div class="card-body m-3">
                        <div class="row">
                            <div class="col-12 mb-3">
                                <div class="fs-3 color-sales mb-1 fw-bold">
                                    买的越多，省的越多
                                </div>

                                <div class="fs-3 color-normal fw-bold">
                                    最高可返还 <span class="color-sales">15%</span> 累计消费金额
                                </div>
                            </div>

                            <div class="col-lg-4 col-12 mb-2 px-0 pe-2" v-for="(item, index) in payback" :key="index">
                                <div class="card border border-1 border-dark">
                                    <div class="card-header color-sales fs-6 fw-bold pb-0">
                                        累计消费 {{ item.value }} 元（CNY）
                                    </div>

                                    <div class="card-body color-normal fs-3 fw-bolder py-0">
                                        返还 <span class="color-sales">{{ item.value * item.payback }}</span> 元（CNY）
                                    </div>

                                    <div class="card-footer color-normal fs-4 fw-bolder pt-0 mt-3">
                                        <i class="ni ni-money-coins color-sales vertical-middle"></i>
                                        以账户余额形式
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p class="text-muted text-bold text-xs">*此活动不适用于余额消费，且仅在活动结束后统一结算，返还余额不支持转移，提现。</p>
                    </div>
                </div>
            </div>

            <hr class="horizontal dark my-5" />

            <div class="col-lg-6 col-12 mb-3 mb-lg-0">
                <div class="card border-dashed border-2 border-warning border-radius-md">
                    <div class="card-header pb-1">
                        <h5 class="mb-0">>_ 限定抽奖</h5>
                    </div>

                    <div class="card-body m-3">
                        <div class="row">
                            <div class="col-12 mb-3">
                                <div class="fs-3 color-sales mb-1 fw-bold">
                                    满足条件即可参与
                                </div>

                                <div class="fs-3 color-normal fw-bold">
                                    让我康康你是分子还是分母
                                </div>
                            </div>

                            <div class="col-xl-8 col-lg-12 col-auto mb-2 ms-md-3 ms-0" v-for="(item, index) in lotterys" :key="index" v-if="lotterys">
                                <div class="card border border-1 border-dark">
                                    <div class="card-header color-sales fs-6 fw-bold pb-0">
                                        {{ item.name }}
                                    </div>

                                    <div class="card-body">
                                        <div class="d-flex justify-content-between" v-for="(content, desc) in item.awards" :key="index">
                                            <div class="fs-6 fw-bold color-normal">
                                                <i class="ni ni-trophy color-sales pe-2"></i>
                                                <span class="fw-bold text-dark">
                                                    {{ desc }}
                                                </span>
                                            </div>

                                            <div class="fs-6 fw-bold color-normal">
                                                <span class="fw-bold text-dark">
                                                    {{ content }}
                                                </span>
                                            </div>
                                        </div>

                                        <!-- <div class="row" v-for="(content, desc) in item.awards" :key="index">
                                            <div class="col-lg-4 col-12 text-nowrap">
                                                <i class="ni ni-trophy color-sales pe-2"></i>
                                                <span class="fs-6 fw-bold text-dark">
                                                    {{ desc }}
                                                </span>
                                            </div>

                                            <div class="col-lg-8 col-12">
                                                <span class="fs-6 fw-bold text-dark">
                                                    {{ content }}
                                                </span>
                                            </div>
                                        </div> -->
                                    </div>

                                    <div class="card-footer d-flex justify-content-between pt-0">
                                        <p class="fs-5 fw-bolder color-normal mb-0">
                                            {{ item.desc }}
                                        </p>
                                        <a class="btn btn-sm btn-outline-secondary color-normal mb-0" :href="item.link" target="_blank">立即参与</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p class="text-muted text-bold text-xs mb-1">*详细抽奖规则详见底部明细</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-12">
                <div class="card border-dashed border-2 border-info border-radius-md h-100">
                    <div class="card-header pb-1">
                        <h5 class="mb-0">>_ 杂项内容</h5>
                    </div>

                    <div class="card-body m-3">
                        <div class="fs-3 color-sales mb-1 fw-bold">
                            随机彩蛋内容
                        </div>

                        <div class="fs-3 color-normal fw-bold">
                            每日发放一定数量的彩蛋兑换码，内容不定
                        </div>

                        <p class="text-muted text-bold text-xs">*兑换彩蛋时必须遵守附带规则，否则将取消当次发放的所有所得。</p>

                        <hr class="horizontal dark my-5" />

                        <div class="fs-3 color-sales mb-1 fw-bold">
                            邀请返利限时UP
                            <i class="ni ni-bold-up text-danger position-relative" style="right: 6px; bottom: 6px;"></i>
                            <i class="ni ni-bold-up text-danger position-relative" style="right: 36px; top: 9px"></i>
                        </div>

                        <div class="fs-3 color-normal fw-bold">
                            活动期间，邀请返利无条件上升 <span class="color-sales">5%</span>
                        </div>
                    </div>
                </div>
            </div>

            <hr class="bg-gradient-dark my-5" />

            <div class="col-12 px-0 px-lg-6" v-if="steps">
                <p class="fs-5 fw-bold">活动须知</p>
                <span class="tips-text">a. 活动期间指 {{ formatStartTime }} (CST) 至 {{ formatEndTime }} (CST)，除额外说明外，其余活动内容均仅在此时间段内有效。</span>
                <span class="tips-text">b. 本次活动分为 {{ stepCount }} 个波次，其时间分别为{{ stepText }}，每波次均会补充一定数量的限购套餐或优惠码，具体数量以实际情况为准。</span>
                <span class="tips-text">c. 优惠码与限购套餐限量供应，一旦售馨或使用次数达到上限，所有处于未完成状态的订单将立刻被取消并退款至账户余额。</span>
                <span class="tips-text">d. 消费返利活动将在活动结束后统一结算，金额返还至账户余额。结算数据不计算活动前的余额消费数据。</span>

                <p class="fs-5 fw-bold mt-4">抽奖须知</p>
                <span class="tips-text">a. 抽奖活动中奖者需要主动联系工作人员进行奖品发放，在指定时间前未领取的奖品将被视为自动放弃。</span>
                <span class="tips-text">b. 抽奖活动参与者需要满足所参与抽奖项目的附加条件，否则将被视为无效并不计入抽奖名单。</span>
            </div>
        </div>

        <div class="row mt-5" v-else>
            <div class="card">
                <div class="card-header">
                    <p class="fs-3 fw-bolder text-center text-dark">活动内容未完成编辑</p>
                    <div class="d-flex justify-content-center">
                        <div class="loader-dots"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '活动页',
    data() {
        return {
            time: null,
            enabled: false,
            
            bannerURL: null,
            waitText: null,
            steps: null,

            countdown: null,
            countdownFn: null,
            countDownTitle: '',
            countDownTips: '',
            discounts: [],
            randomCode: '******',
            limitShops: [],
            payback: [
                { value: 50, payback: 0.05 },
                { value: 100, payback: 0.10 },
                { value: 200, payback: 0.15 },
            ],
            lotterys: [],

            // startAt: '2023-12-15 12:00',
        }
    },
    computed: {
        stepCount() {
            return this.steps.length;
        },
        stepText() {
            let stepText = '';
            for (let i = 0; i < this.stepCount; i++) {
                stepText += `${this.steps[i]}，`;
                stepText = i === this.stepCount - 1 ? stepText.slice(0, -1) : stepText;
            }
            stepText = `(${stepText})`;
            return stepText;
        },
        formatStartTime() {
            return new Date(this.time.start * 1000).toLocaleString('zh-CN', { hour12: false }).replace(/\//g, '-');
        },
        formatEndTime() {
            return new Date(this.time.end * 1000).toLocaleString('zh-CN', { hour12: false }).replace(/\//g, '-');
        },
    },
    mounted() {
        this.getSaleData();
    },
    unmounted() {
        // * 清除定时器
        clearTimeout(this.countdownFn);
    },
    methods: {
        getSaleData(trigger = null) {
            const dataHandle = (data) => {
                this.time       = data.time;
                this.bannerURL  = data.banner;
                this.enabled    = data.enabled;
                this.waitText   = data.waitText;
                this.steps      = data.step;
                this.randomCode = data.randomCode;
                this.lotterys   = data.lotterys;

                // * 如果 start 晚于当前时间，开始倒计时
                if (this.time.start > Date.now() / 1000) {
                    this.startCountDown(this.time.start);
                }

                // if later then start, but before countdown
                const now = Date.now() / 1000;
                if (now > this.time.start && now < this.time.countdown) {
                    this.startCountDown(this.time.countdown);
                }

                this.discounts  = data.discountCodes;
                this.limitShops = data.limitShops;
            }

            if (trigger) trigger.target.disabled = true;

            axios.get(this.$getApiURL('getSaleData'))
            .then(res => {
                this.$showToast('获取数据成功', '数据已更新', 'success');

                dataHandle(res.data);
            })
            .catch(err => {
                this.$showToast('获取数据失败', '请尝试刷新页面或联系管理员', 'error');
            })
            .finally(() => {
                if (trigger) trigger.target.disabled = false;
            });
        },
        startCountDown(time) {
            // ! 如果已经存在倒计时，清除
            if (this.countdownFn) clearTimeout(this.countdownFn);

            // * 计算剩余时间
            const distance = (time - Date.now() / 1000) * 1000;

            if (distance < 0) {
                this.countdown = null;
                if (this.countdownFn) {
                    clearTimeout(this.countdownFn);
                }

                this.getSaleData();
                return;
            }

            const countdown = {
                days: Math.floor(distance / (1000 * 60 * 60 * 24)),
                hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
                seconds: Math.floor((distance % (1000 * 60)) / 1000),
            };

            Object.keys(countdown).forEach(key => {
                countdown[key] = countdown[key].toString().length === 1 ? `0${countdown[key]}` : countdown[key];
            });

            this.countdown = countdown;

            // * 每秒更新一次
            this.countdownFn = setTimeout(() => { this.startCountDown(time) }, 1000);
        },
    },
}
</script>

<style scoped>
    .loader-dots {
        height: 15px;
        aspect-ratio: 5;
        background: radial-gradient(closest-side at 37.5% 50%,#000 94%,#0000) 0/calc(80%/3) 100%;
        animation: loader-dots-frames .75s infinite;
    }

    @keyframes loader-dots-frames {
        100% {background-position: 36.36%}
    }

    .banner {
        /* background-image: url('@/assets/img/6th.png'); */
        background-size: cover;
        background-position: center;
        position: relative;
        height: 750px;
        border-radius: 10px;
        box-shadow:0 0 30px 10px rgba(255,255,255,.7) inset;
    }

    .color-sales {
        color: #de273e !important;
    }

    .color-normal {
        color: #5e5e5e !important;
    }

    .discountGroup {
        display: flex;
        flex-direction: row;
        flex: 1;
        padding: 2rem;
        transition: all 0.3s ease;
        box-shadow: 0 0 20px 0 rgb(0 0 0, .5);
        align-items: center;
        justify-content: space-between;
        position: relative;
        max-width: 1000px;
        overflow: hidden;
        overflow-x: scroll;
    }

    .discountBox {
        display: flex;
        flex-direction: column;
        flex: 1;
        /* position: absolute; */
        border: 1px solid #3e3e3e;
        border-radius: 10px;
        padding: 2rem;
        margin-right: 18px;
        cursor: pointer;
        min-width: 255px;
        
        color: #5e5e5e;
    }

    .discountBox:hover {
        background-color: #f5f5f5;
        border-color: #de273e;
    }

    .tips-text {
        border-bottom: 1px dashed #5d5d5d;
        line-height: 1.5;
        font-size: 14px;
        margin-bottom: 10px;
        padding-bottom: 10px;
        display: block;
        width: max-content;
    }

    .countDown-text {
        margin-bottom: 0;
        font-size: 2.25rem;
        font-weight: 600;
        color: #344767;
        text-align: center;
    }

    .countDown-desc {
        font-size: 1rem;
        font-weight: 600;
        white-space: nowrap;
        font-style: italic;
    }

    .countDown-divide {
        margin-bottom: 0;
        font-size: 2.25rem;
        font-weight: 600;
        padding: 0 1.5rem;
        color: #344767;
    }

    .discountRow {
        display: contents !important;
    }

    /* Screen < 1200px */
    @media (max-width: 1200px) {
        .banner {
            height: 500px;
        }
    }

    /* Screen < 997px */
    @media (max-width: 997px) {
        .discountBox {
            margin-right: 0;
            margin-bottom: 9px;
            max-width: 100%;
            width: 100%;
        }

        .discountGroup {
            flex-direction: column;
            overflow: unset;
        }

        .countDown-divide {
            padding: 0 0.5rem;
        }

        .discountRow {
            display: flex !important;
            padding-left: 12px;
        }

        .tips-text {
            width: auto;
        }

        .banner {
            height: 400px;
        }
    }

    /* Screen < 768px */
    @media (max-width: 768px) {
        .banner {
            height: 300px;
        }
    }
</style>