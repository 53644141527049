// VueX Store File
// This file is used to store the state of the application
// Path: src\store\index.js

import { createStore } from 'vuex'
import { useUserApi } from '../composables/useUserApi'

const store = createStore({
    state: {
        token: localStorage.getItem('token') || null,
        user: null,

        apis: {},

        isNotified: false,
    },
    mutations: {
        setToken(state, token) {
            state.token = token
            localStorage.setItem('token', token)
        },
        setUser(state, user) {
            state.user = user
        },
        clearToken(state) {
            state.token = null
            localStorage.removeItem('token')
        }
    },
    getters: {
        getToken(state) {
            return state.token
        },
        getToast(state) {
            return state.toastInstance
        }
    },
    actions: {
        async getUserData({ state, commit }) {
            if (state.user) return state.user

            return await useUserApi().getUserData()
            .then(res => {
                commit('setUser', res.data)
                return res
            })
        },
        async refreshUserData({ state, commit }) {
            return await useUserApi().getUserData(false)
            .then(res => {
                commit('setUser', res.data)
                return res
            })
        }
    },
    modules: {
        // 
    }
})

export default store