<script setup>
import { ref, onMounted } 	from 'vue'
import { useStore } 		from 'vuex'
import { useUserApi } 		from '@/composables/useUserApi'
import { useRouter } 		from 'vue-router'

import ShopSelect 	 from './newPlan/ShopSelect.vue'
import RechargeCard  from './newPlan/RechargeCard.vue'
import RedeemCard 	 from './newPlan/RedeemCard.vue'
import OrderHistory  from './newPlan/OrderHistory.vue'
import CurrentPlan 	 from './newPlan/CurrentPlan.vue'
import PlansDetail 	 from './newPlan/PlansDetail.vue'
import LimitPlanCard from './newPlan/LimitPlanCard.vue'

const store = useStore()
const shopData = ref(null)
const limitPlans = ref(null)
const showPlansDetail = ref(false)
const router = useRouter()

// 切换显示套餐详情
const togglePlansDetail = () => {
	showPlansDetail.value = !showPlansDetail.value
}

onMounted(async () => {
	if (!store.state.user) {
		await store.dispatch('getUserData')
	}

	try {
		const res = await useUserApi().getShopData()
		shopData.value = res.common
		limitPlans.value = res.limit || {}
	} catch (err) {
		console.error(err)
	}
})
</script>

<template>
	<div class="container-fluid py-4 w-100 w-xl-90">
		<div class="row">
			<div class="col-lg-8 col-12 mx-auto">
				<!-- button back to old plan -->
				<button class="btn btn-primary" @click="router.push({ name: '旧版套餐中心' })">返回旧版页面</button>
			</div>

			<div class="col-lg-8 col-12 mx-auto" v-if="!showPlansDetail">
				<!-- 当前套餐 -->
				<div class="row mb-4">
					<div class="col-12">
						<CurrentPlan :onShowDetail="togglePlansDetail" />
					</div>
				</div>

				<!-- 快捷操作 -->
				<div class="row mb-4">
					<div class="col-lg-6 col-12 mb-lg-0 mb-4">
						<RechargeCard :money="store.state.user?.money" />
					</div>
					<div class="col-lg-6 col-12">
						<RedeemCard />
					</div>
				</div>

				<!-- 限购套餐 -->
				<LimitPlanCard 
					:limitPlans="limitPlans" 
					class="mb-4" 
					v-if="limitPlans && Object.keys(limitPlans).length"
				/>

				<!-- 套餐选择 -->
				<ShopSelect 
					:shopData="shopData" 
					class="mb-4" 
					v-if="shopData" 
				/>

				<!-- 订单历史 -->
				<div class="row">
					<div class="col-12">
						<OrderHistory />
					</div>
				</div>
			</div>

			<div class="col-12" v-else>
				<PlansDetail @close="togglePlansDetail" />
			</div>
		</div>
	</div>
</template>
