<script setup>
    import annCard      from '@/components/argon-dashboard-pro-2/annCard.vue';
    import checkout     from '@/views/Shop/checkout.vue';
    import dataTable    from '@/views/Shop/dataTable.vue';
    import reChargeCard from '@/views/Shop/recharge.vue';
    import redeemCard   from '@/views/Shop/redeem.vue';
    import planList     from '@/views/Shop/planList.vue';

    import { reactive, ref, computed, onMounted } from 'vue';
    import { useStore } from 'vuex';

    const store = useStore();

    const user         = computed(() => store.state.user);
    const alert        = reactive({ order: false, invoice: false });
    const currentViews = ref('store');

    onMounted(() => {
        if (!user.value) {
            store.dispatch('getUserData');
        }
    })
</script>

<template>
    <div class="container-fluid py-4">
        
        <div class="row">
            <div class="col-12" v-show="alert.order">
                <div class="alert alert-primary alert-dismissible text-white" role="alert">
                    当前有等待中的订单 <a class="alert-link text-white" href="#" @click="currentViews = 'orders'">点击查看</a>.
                    <button type="button" class="btn-close text-lg py-3 opacity-10" data-bs-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            </div>

            <div class="col-12" v-show="alert.invoice">
                <div class="alert alert-primary alert-dismissible text-white" role="alert">
                    当前有等待中的账单 <a class="alert-link text-white" href="#" @click="currentViews = 'invoices'">点击查看</a>.
                    <button type="button" class="btn-close text-lg py-3 opacity-10" data-bs-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            </div>
        </div>

        <Suspense>
            <div class="row">
                <div class="col-md-4 col-12 mt-2">
                    <annCard maxHeight="242" type="3" />
                </div>

                <div class="col-md-4 col-12 mt-2">
                    <reChargeCard />
                </div>

                <div class="col-md-4 col-12 mt-2">
                    <redeemCard />
                </div>

                <div class="col-md-2 col-0"></div>
                <div class="col-md-8 col-12 mt-4 mb-3">
                    <div class="d-flex justify-content-center hidden-scrollbar" style="overflow-x: scroll;">
                        <button class="btn btn-outline-dark mb-0 w-100 mx-2 mt-1 shopCenterViews"
                            :class="{'shopCenterViews_active': currentViews === item}"
                            @click="currentViews = item"
                            v-for="item in ['store', 'userPlans', 'dataTables']">
                            {{ { 'store': '套餐选购', 'userPlans': '可用套餐', 'dataTables': '数据列表' }[item] }}
                        </button>
                    </div>
                </div>
                <div class="col-md-2 col-0"></div>

                <checkout v-if="currentViews === 'store'" />

                <planList v-if="currentViews === 'userPlans'" />

                <dataTable v-if="currentViews === 'dataTables'" />

            </div>
        </Suspense>
    </div>
</template>

<style scoped>
.shopCenterViews {
    word-break: break-all;
    word-wrap: normal;
    background-color: #fff !important;
}

.shopCenterViews_active {
    background-color: #5e72e4 !important;
    border-color: #5e72e4 !important;
    color: #fff !important;
}
</style>