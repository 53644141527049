<script setup>
    import login    from '@/views/Auth/login.vue';
    import register from '@/views/Auth/register.vue';
    import reset    from '@/views/Auth/reset.vue';

    import { useThirdPartyReq } from '@/composables/useThirdPartyReq';
    import { useCheckEmpty }    from '@/composables/useCheckEmpty';
</script>

<template>
    <div class="page-header min-vh-100">
        <img class="position-absolute vh-100 vw-100 bg-bing-img" v-if="bingImgUrl" :src="bingImgUrl" alt="backgroud">
        <img class="position-absolute vh-100 vw-100 bg-bing-img" v-else src="@/assets/img/repeating-triangles.svg" alt="backgroud">

        <a :href="bingImgSrc" target="_blanket">
            <i class="ni ni-align-center text-primary position-absolute" style="left: 94%; top:5%; z-index: 3"></i>
        </a>

        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-7" v-if="loading">
                    <div class="card border-0 mb-0 authCard">
                        <div class="d-flex justify-content-center align-items-center m-5">
                            <div class="spinner-border text-primary" role="status"></div>
                            <span class="ms-3 text-dark text-bold">Loading...</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-7" v-else>
                    <login :preEmail="preEmail" :prePassword="prePassword" @back="type = $event" v-if="type === 'login'" />
                    <reset :preToken="preToken" @back="type = $event" v-if="type === 'reset'" />
                    <register :preReferral="preReferral" @registerSuccess="registerSuccess" @back="type = $event" v-if="type === 'register'" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const { checkEmpty } = useCheckEmpty();

export default {
    name: '授权相关',
    inject: ['SwitchFramework'],
    data() {
        return {
            type: 'login',
            loading: false,

            preReferral: '',
            preEmail:    '',
            prePassword:  '',
            preToken:    '',

            bingImgUrl:  null,
            bingImgSrc:  null,
        }
    },
    mounted() {
        // * 隐藏部分页面元素
        this.SwitchFramework(true);

        // * 获取必应背景图
        useThirdPartyReq().getBingImage()
        .then(res => {
            this.bingImgUrl = res?.base64;
            this.bingImgSrc = res?.data?.url;
        })

        // * 检查推荐码
        if (!checkEmpty(this.$route.query.code)) {
            this.preReferral = this.$route.query.code;
            this.type = 'register';
        }

        // * 检查密码重置密钥
        if (this.$route.name === '重置密码' && !checkEmpty(this.$route.params.token)) {
            this.preToken = this.$route.params.token;
            this.type = 'reset';
        }

        // * 检查指定类型
        if (!checkEmpty(this.$route.query.type)) {
            let type = this.$route.query.type.toLowerCase().replace('to', '');
                type = type.split('&')[0];
            this.type = { reg: 'register', login: 'login', reset: 'reset' }[type];
        }
    },
    beforeUnmount() {
        this.SwitchFramework(false);
    },
    methods: {
        registerSuccess(val) {
            this.preEmail    = val.email;
            this.prePassword = val.password;

            this.type = 'login';
        },
    },
    watch: {
        type(val) {
            this.loading = true;

            setTimeout(() => { this.loading = false; }, 400);
        }
    }
}
</script>

<style scoped>
.bg-bing-img {
    /* background-size: cover;
    background-position: center; */
    object-fit: cover;
    object-position: center center;
    user-select: none;
    pointer-events: none;
}
.custom-button {
    cursor: pointer;
    border: 10px solid transparent;
    border-left: 0;
}

.authCard {
    background: #ffffff63 !important;
    backdrop-filter: blur(15px) !important;
    box-shadow: 0 0 10px 2px #00000094 !important;
}

@media (prefers-color-scheme: dark) {
    .authCard {
        background: #1f1f1f !important;
    }

    .bg-bing-img {
        opacity: 0.2;
    }
}
</style>