<script setup>
import { DataTable }                          from "simple-datatables"

import { useDataTable } from "@/composables/useDataTable";
import { useCheckEmpty } from "@/composables/useCheckEmpty";
</script>

<template>
    <div class="col-12 mt-3">
        <div class="card">
            <div class="card-header d-flex justify-content-between">
                <h5 class="mb-0 text-capitalize">{{ tableName }}</h5>
                <slot></slot>                
            </div>

            <div class="card-body pt-0">
                <div class="table-responsive">
                    <table class="table table-flush" ref="table">
                        <thead class="thead-light"></thead>
                        <tbody></tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const { checkEmpty } = useCheckEmpty();
const { columnTrans, dataTrans } = useDataTable();

export default {
    name: "dataTable",
    props: {
        tableName: {
            type: String,
            required: true,
        },
        rawTableData: {
            type: Array,
            required: true,
            default: () => [],
        },
        tableClass: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            tableContainer: null,
            table: null,
        };
    },
    mounted() {
        this.initTable();

        window.jumpTo = this.jumpTo;
    },
    unmounted() {
        window.jumpTo = null;
    },
    methods: {
        initTable() {
            const data      = this.rawTableData ?? [];
            const dataClass = this.tableClass;
            const havaOP    = ['order', 'ticket'].includes(dataClass);

            // * 设置表头，使用columnTrans函数进行翻译
            let heading = [];
            if (havaOP) heading.push('操作');
            if (!checkEmpty(data)) {
                for (let key in data[0]) {
                    key = columnTrans(dataClass, key);
                    heading.push(key);
                }
            }

            // * 设置表格数据，使用dataTrans函数进行翻译
            let tabledata = [];
            for (let i = 0; i < data.length; i++) {
                let row = [];
                for (let key in data[i]) {
                    if (havaOP && key === 'id') {
                        row.push(`<button class="btn btn-xs btn-primary my-n1" onclick="jumpTo('${dataClass}', ${data[i][key]})">点击查看</button>`);
                    }
                    row.push(dataTrans(dataClass, key, data[i][key]));
                }
                tabledata.push(row);
            }

            // * 合并表头和表格数据
            tabledata = { headings: heading, data: tabledata };

            // * 初始化表格
            // const tableElement = this.$el.querySelector('table');
            this.table = new DataTable(this.$refs.table, {
                labels: {
                    placeholder: '搜索...',
                    perPage: ' 项/页',
                    noRows: '无符合项',
                    info: '正展示 {start} - {end} 项，共 {rows} 项记录'
                },
                data: tabledata,
            });
        },
        jumpTo(dataClass, id) {
            if (dataClass === 'order') {
                this.$router.push({ name: '订单页', params: { id: id } });
            }

            if (dataClass === 'ticket') {
                // this.$router.push({ name: '工单页', params: { id: id } });
                this.$emit('ticket_detail', id);
            }
        },
    },
}
</script>