<script setup>
    import SimpleDT         from '@/components/argon-dashboard-pro-2/dataTable.vue';
    import { useUserApi }   from '@/composables/useUserApi';
</script>

<template>
    <div class="col-0 col-lg-1"></div>

    <div class="col-12 col-lg-10">
        <SimpleDT tableName="订单列表" tableClass="order" :rawTableData="orders" v-if="ready" />
        
    </div>

    <hr>

    <div class="col-0 col-lg-1"></div>

    <div class="col-12 col-lg-10">
        <SimpleDT tableName="账单列表" tableClass="invoice" :rawTableData="invoices" v-if="ready" />
    </div>
    
</template>

<script>
const { getMiscData } = useUserApi();

export default {
    name: 'dataTable',
    data() {
        return {
            invoices: [],
            orders: [],
            ready: false,
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await getMiscData('invoice')
            .then(res => {
                this.invoices = res.data;
            })

            await getMiscData('order')
            .then(res => {
                this.orders = res.data;
            })

            this.ready = true;
        }
    },
}
</script>