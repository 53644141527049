<template>
	<div class="card h-100">
		<div class="card-header p-3 pb-0">
			<h6 class="mb-0 fw-bold">规则说明</h6>
		</div>
		<div class="card-body p-3 ps-4">
			<div class="border-start border-3 border-dark ps-3 mb-3">
				<div class="d-flex align-items-center">
					<h6 class="text-dark text-sm mb-0">推广须知</h6>
				</div>
			</div>
			<ul class="list-group">
				<li class="list-group-item border-0 ps-0">
					<div class="d-flex align-items-center">
						<div class="me-3 text-danger">
							<i class="fas fa-exclamation-circle"></i>
						</div>
						<div class="d-flex flex-column">
							<span>请勿在友商，国内社媒，线下进行宣传推广</span>
						</div>
					</div>
				</li>
				<li class="list-group-item border-0 ps-0">
					<div class="d-flex align-items-center">
						<div class="me-3 text-danger">
							<i class="fas fa-exclamation-circle"></i>
						</div>
						<div class="d-flex flex-column">
							<span>请勿违规循环推广，违规直接删除账户</span>
						</div>
					</div>
				</li>
				<li class="list-group-item border-0 ps-0">
					<div class="d-flex align-items-center">
						<div class="me-3 text-info">
							<i class="fas fa-lightbulb"></i>
						</div>
						<div class="d-flex flex-column">
							<span>邀请新用户时请提供必要的使用指导</span>
						</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>