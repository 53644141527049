<script setup>
import { ref, inject, onUnmounted } from 'vue'
import axios 			 from '@/Plugins/axios'
import { useRouter } 	 from 'vue-router'
import { useCache } 	 from '@/composables/useCache'
import { useCheckEmpty } from '@/composables/useCheckEmpty'
import { useApiURL }     from '@/composables/useApiURL'
import { useShowToast }  from '@/composables/useShowToast'

const { showToast }  = useShowToast()
const { getApiURL }  = useApiURL()
const router 	     = useRouter()
const { checkEmpty } = useCheckEmpty()
const { deleteCache, refreshCache } = useCache()

const props = defineProps({
	limitPlans: {
		type: Object,
		default: () => ({})
	}
})

const isLoading = ref(false)
const now       = ref(new Date().getTime())

// 更新当前时间
const timer = setInterval(() => {
	now.value = new Date().getTime()
}, 1000)

// 清理定时器
onUnmounted(() => {
	clearInterval(timer)
})

const { checkModalHandler } = inject('checkModalHandler')

const getCountdown = (deadline) => {
	deadline = deadline * 1000
	const difference = deadline - now.value
	
	if (difference <= 0) return null

	const days    = Math.floor(difference / (1000 * 60 * 60 * 24))
	const hours   = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
	const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
	const seconds = Math.floor((difference % (1000 * 60)) / 1000)

	return {
		days: days.toString().padStart(2, '0'),
		hours: hours.toString().padStart(2, '0'),
		minutes: minutes.toString().padStart(2, '0'),
		seconds: seconds.toString().padStart(2, '0')
	}
}

const purchase = async (id) => {
	isLoading.value = true

	try {
		const { data } = await axios.post(getApiURL('purchase'), { id })

		if (!checkEmpty(data.order)) {
			showToast('余额不足', '请确保账户余额充足', 'warning')
			setTimeout(() => {
				router.push({ name: '订单页', params: { id: data.order } })
			}, 1200)
			return
		}

		showToast('操作成功', '套餐订购成功', 'success')
		deleteCache('miscData-invoice')
		deleteCache('miscData-order')
		refreshCache('shop')
		checkModalHandler({
			id: Number(data.order),
			type: 'order',
			url: null,
			show: true
		})
	} catch (err) {
		console.error(err)
	} finally {
		refreshCache('shop')

		setTimeout(() => {
			isLoading.value = false
		}, 450)
	}
}

const getDateFromTimestamp = (timestamp) => {
	const date = new Date(timestamp * 1000).toLocaleString('zh-CN', { timeZone: 'Asia/Shanghai' })
	return date.replace(/\//g, '-').replace(/\s/g, ' ')
}
</script>

<template>
	<div class="col-xxl-10 col-12 mx-auto mt-3">
		<div class="card">
			<div class="card-header">
				<h5 class="mb-0">限购套餐</h5>
			</div>

			<div class="card-body pt-0">
				<div class="alert alert-danger alert-dismissible text-white p-2 px-3" role="alert">
					<span class="fw-bold">
						<i class="ni ni-bell-55"></i>
						请注意，限购套餐无法通过账单购买，请确保账户余额充足。
					</span>
				</div>

				<div class="row g-3">
					<div v-for="limit in limitPlans" :key="limit.id" class="col-lg-4 col-md-6 col-12">
						<div class="card h-100 shadow-none border border-secondary">
							<div class="card-body p-3">
								<div class="d-flex justify-content-between align-items-start mb-1">
									<div>
										<p class="m-0 fw-bold">
											{{ limit.name }}
											<br />
											<span class="text-success fw-bold mb-0">价格：￥{{ limit.price }}</span>
										</p>
									</div>

									<button class="btn btn-xs btn-primary mb-0" @click="purchase(limit.id)" :disabled="isLoading" v-if="!getCountdown(limit.released_at)">
										<span v-if="isLoading" class="spinner-border spinner-border-sm me-2"></span>
										点击购买
									</button>

									<button class="btn btn-xs btn-primary mb-0" disabled v-else>
										等待开售
									</button>
								</div>

								<p class="my-0 fs-6 text-bold">{{ getDateFromTimestamp(limit.released_at) }} CST 起售</p>

								<p class="my-0 text-sm text-bold">
									<template v-if="!getCountdown(limit.released_at)">
										<span class="text-danger">正在售卖中...</span>
									</template>

									<template v-else>
										<span class="text-danger">
											<template v-for="(val, key) in getCountdown(limit.released_at)" :key="key">
												{{ val }}
												<span class="text-dark" :class="key === 'seconds' ? 'pe-0' : 'pe-1'">
													{{ { 'days': '天', 'hours': '时', 'minutes': '分', 'seconds': '秒' }[key] }}
												</span>
												<span v-if="key === 'seconds'" class="text-dark px-0">后起售</span>
											</template>
										</span>
									</template>
								</p>

								<div class="mt-2">
									<p class="mb-0">
										<span class="text-success">●</span>
										<span class="text-xs" v-if="limit.stock > 0">
											剩余 <code class="fs-5">{{ String(limit.stock).padStart(2, '0') }}</code> 份
										</span>
										<span class="text-sm" v-else>
											<span class="text-danger fw-bold letter-spacing-3 ps-2">已售罄</span>
										</span>
									</p>

									<p class="mb-0">
										<span class="text-info">●</span>
										<span class="text-xs">
											流量 <code class="fs-5">{{ limit.traffic }}G</code>
											<i class="ni ni-fat-delete ps-1"></i>
											可用 <code class="fs-5">{{ limit.duration }}天</code>
										</span>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>