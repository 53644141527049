<script setup>
import { ref, onMounted } from 'vue'
import { useUserApi } from '@/composables/useUserApi'
import SubTokenCard from './Sub/TokenCard.vue'
import SubBuilderCard from './Sub/BuilderCard.vue'
import dataTable        from '@/components/argon-dashboard-pro-2/dataTable.vue';

const subData = ref(null)
const tableData = ref(null)
const showLogs = ref(false)
const { getSubData, getMiscData } = useUserApi()

const getSubscribe = async (useCache = true) => {
	try {
		const res = await getSubData(useCache)
		subData.value = res.data
	} catch (err) {
		console.error(err)
	}
}

const getLogs = async () => {
	try {
		const result = await getMiscData('subscribe')
		tableData.value = result.data

		showLogs.value = true
	} catch (err) {
		console.error(err)
	}
}

onMounted(() => {
	getSubscribe()

	getLogs()
})
</script>

<template>
	<div class="container-fluid py-4">
		<div class="row">
			<!-- 订阅构建器 -->
			<div class="col-lg-8 col-12 mx-auto mb-4">
				<SubBuilderCard 
					:subData="subData" 
					v-if="subData" 
				/>
			</div>

			<!-- 订阅令牌 -->
			<div class="col-lg-8 col-12 mx-auto mb-4">
				<SubTokenCard 
					:tokens="subData?.tokens" 
					:remaining="subData?.remaining ?? 3"
					v-if="subData"
					@refresh="getSubscribe(false)"
				/>
			</div>

			<!-- 订阅日志 -->
			<div class="col-lg-8 col-12 mx-auto mb-4">
				<dataTable tableName="订阅日志" tableClass="subscribe" :rawTableData="tableData" v-if="showLogs" />
			</div>
		</div>
	</div>
</template> 