<script setup>
import { ref, watch } from 'vue'
import { useShowToast } from '@/composables/useShowToast'

const { showToast } = useShowToast()

const props = defineProps({
	subData: {
		type: Object,
		required: true
	}
})

const builderUrl = ref('')
const select = ref({
	host: '',
	token: '',
	type: ''
})

const options = ref({
	host: [{ text: '选择主机', value: '' }],
	token: [{ text: '选择密钥', value: '' }],
	type: [{ text: '选择类型', value: '' }]
})

function initOptions() {
	// 主机选项
	options.value.host = [
		{ text: '选择主机', value: '' },
		...props.subData.hosts.map(host => ({
			text: host.replace('https://', ''),
			value: host.replace('https://', '')
		}))
	]

	// 令牌选项
	options.value.token = [
		{ text: '选择密钥', value: '' },
		...props.subData.tokens.map(token => ({
			text: token.token,
			value: token.token
		}))
	]

	// 类型选项
	options.value.type = [
		{ text: '选择类型', value: '' },
		...props.subData.schemas.map(schema => ({
			text: schema.name,
			value: schema.strModel
		}))
	]
}

async function copyToClipboard() {
	try {
		await navigator.clipboard.writeText(builderUrl.value)
		showToast('操作成功', '已复制到剪贴板')
	} catch (err) {
		showToast('操作失败', '复制失败，请手动复制', 'error')
	}
}

watch(() => props.subData, initOptions, { immediate: true })

watch(select, (val) => {
	if (!val.host || !val.token || !val.type) {
		builderUrl.value = ''
		return
	}

	builderUrl.value = val.type
		.replace('$domain$', val.host)
		.replace('$token$', val.token)
}, { deep: true })
</script>

<template>
	<div class="card">
		<div class="card-header p-3 pb-0">
			<div class="d-flex justify-content-between align-items-center">
				<h6 class="mb-0 fw-bold">订阅配置</h6>
				<a 
					href="https://docs.wsl.icu" 
					target="_blank"
					class="btn btn-sm btn-outline-info mb-0"
				>
					<i class="fas fa-book me-1"></i>
					使用文档
				</a>
			</div>
		</div>
		<div class="card-body p-3">
			<div class="row">
				<div class="col-lg-4 col-md-6 col-12 mb-3">
					<label class="form-label">订阅主机</label>
					<SlimSelect 
						v-model="select.host" 
						:data="options.host" 
						:settings="{ showSearch: false }"
					/>
				</div>
				<div class="col-lg-4 col-md-6 col-12 mb-3">
					<label class="form-label">订阅密钥</label>
					<SlimSelect 
						v-model="select.token" 
						:data="options.token" 
						:settings="{ showSearch: false }"
					/>
				</div>
				<div class="col-lg-4 col-md-6 col-12 mb-3">
					<label class="form-label">订阅类型</label>
					<SlimSelect 
						v-model="select.type" 
						:data="options.type" 
						:settings="{ showSearch: false }"
					/>
				</div>
				<div class="col-12">
					<div class="input-group">
						<input 
							type="text" 
							class="form-control" 
							:value="builderUrl" 
							readonly
							placeholder="请选择以上选项生成订阅链接"
						>
						<button 
							class="btn btn-outline-primary mb-0" 
							@click="copyToClipboard"
							:disabled="!builderUrl"
						>
							<i class="fas fa-copy me-1"></i>
							复制
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template> 