<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore }     from 'vuex'
import { useShowToast } from '@/composables/useShowToast'
import { useApiURL }    from "@/composables/useApiURL"
import axios            from '@/Plugins/axios'

const store         = useStore()
const { showToast } = useShowToast()
const { getApiURL } = useApiURL()

const showDetail  = ref(false)
const newUserName = ref('')
const isLoading   = ref(false)
const userName    = computed(() => store.state.user?.user_name || 'is loading...')

onMounted(() => {
    if (!store.state.user) {
        store.dispatch('getUserData')
    }
})

const updateUserName = async () => {
    if (!newUserName.value) {
        showToast('请输入新的用户名', 'warning')
        return
    }

    // only allow chinese, english, number, and _,-
    if (!/^[a-zA-Z0-9_\u4e00-\u9fa5\-\s]+$/.test(newUserName.value)) {
        showToast('用户名只允许使用中文、英文、数字、下划线、中划线、非起头空格', 'warning')
        return
    }

    // check is the newUserName is the same as the oldUserName
    if (newUserName.value === userName.value) {
        showToast('新用户名不能与旧用户名相同', 'warning')
        return
    }

    isLoading.value = true

    try {
        const res = await axios.put(getApiURL('updateUserName'), {
            name: newUserName.value
        })
        
        showToast('操作成功', '用户名已更新', 'success')
        userName.value    = res.data.newName
        showDetail.value  = false
        newUserName.value = ''
        
        // 更新 store 中的用户名
        store.dispatch('refreshUserData')
    } catch (err) {
        console.error(err)
    } finally {
        isLoading.value = false
    }
}
</script>

<template>
    <div class="card">
        <div class="card-header border-bottom p-3 px-4 text-dark">
            <div class="d-flex align-items-center">
                <i class="ni ni-single-02 me-2"></i>
                <span>用户名设置</span>
            </div>
        </div>

        <div class="card-body">
            <div v-if="!showDetail">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="d-block text-sm">当前用户名</label>
                            <input type="text"class="form-control":value="userName"readonly />
                        </div>
                    </div>
                </div>

                <div class="mt-md-3 pt-md-3 mt-2 pt-2 border-top">
                    <h6 class="text-sm fw-bold text-dark" style="margin-bottom: 0.75rem;">相关提示</h6>
                    <ul class="tips-list">
                        <li>用户名仅用于显示，不影响登录</li>
                        <li>修改用户名无需重新登录</li>
                    </ul>
                </div>

                <button type="button" class="btn btn-primary w-100 mt-4" @click="showDetail = true">
                    修改用户名
                </button>
            </div>

            <form v-else>
                <div class="form-group">
                    <label class="d-block text-sm">新用户名</label>
                    <input type="text" class="form-control" v-model="newUserName" required placeholder="请输入新的用户名" />
                </div>

                <div class="d-flex gap-3 mt-4">
                    <button class="btn btn-secondary flex-grow-1" type="button" @click="showDetail = false">
                        取消
                    </button>
                    <button class="btn btn-primary flex-grow-1" type="button" @click="updateUserName" :disabled="isLoading">
                        <span v-if="isLoading" class="spinner-border spinner-border-sm me-2"></span>
                        确认修改
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<style scoped>
i {
    font-size: 1.125rem;
    color: var(--bs-primary);
}

.tips-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.tips-list li {
    position: relative;
    padding-left: 1.25rem;
    font-size: 0.8125rem;
    color: #67748e;
    margin-bottom: 0.5rem;
}

.tips-list li:last-child {
    margin-bottom: 0;
}

.tips-list li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0.5rem;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #67748e;
}
</style> 