<script setup>
import { ref } from 'vue'
import axios from '@/Plugins/axios'

import { useShowToast } from '@/composables/useShowToast'
import { useApiURL } from '@/composables/useApiURL'

const { showToast } = useShowToast()
const { getApiURL } = useApiURL()

const amount = ref('')
const isLoading = ref(false)

const emit = defineEmits(['refresh'])

async function handleConvert() {
	const api = `${getApiURL('referral')}/convert`;
	if (!amount.value || isLoading.value) return
	
	isLoading.value = true
	
	try {
		const res = await axios.post(api, {
			amount: amount.value
		})
		
		amount.value = ''

		showToast('操作成功', res.data.message, 'success')

		emit('refresh')
	} catch (err) {
		console.error(err)
	} finally {
		isLoading.value = false
	}
}
</script>

<template>
	<div class="card">
		<div class="card-header p-3 pb-0">
			<h6 class="mb-0 fw-bold">收益转换</h6>
		</div>
		<div class="card-body p-3">
			<!-- 转换操作 -->
			<div class="input-group">
				<span class="input-group-text">¥</span>
				<input 
					type="number" 
					class="form-control" 
					v-model="amount"
					placeholder="输入转换金额"
					:disabled="isLoading"
				>
				<button 
					class="btn btn-primary mb-0" 
					@click="handleConvert"
					:disabled="!amount || isLoading"
				>
					<span v-if="isLoading" class="spinner-border spinner-border-sm me-1"></span>
					转换为余额
				</button>
			</div>

			<!-- 收益说明 -->
			<div class="mt-2 ms-2">
				<ul class="list-group">
					<li class="list-group-item border-0 ps-0">
						<div class="d-flex align-items-center">
							<div class="me-3 text-primary">
								<i class="fas fa-info-circle"></i>
							</div>
							<div class="d-flex flex-column">
								<span>返利需手动转入余额后方可使用</span>
							</div>
						</div>
					</li>
					<li class="list-group-item border-0 ps-0">
						<div class="d-flex align-items-center">
							<div class="me-3 text-primary">
								<i class="fas fa-info-circle"></i>
							</div>
							<div class="d-flex flex-column">
								<span>如需提现请咨询工单（100.00起）</span>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template> 