<script setup>
import { ref, onMounted } from 'vue'
import { useShowToast } from '@/composables/useShowToast'
import { useApiURL }    from '@/composables/useApiURL'
import axios            from '@/Plugins/axios'

const { showToast } = useShowToast()
const { getApiURL } = useApiURL()

const isLoading = ref(false)
const isBound   = ref(false)
const command   = ref('')
const timer     = ref(null)
const botURL    = ref('')
const botName   = ref('')

onMounted(() => {
    getTelegramData()
})

const getTelegramData = async () => {
    isLoading.value = true

    await axios.get(getApiURL('telegram'))
    .then(res => {
        isBound.value = res.data.status
        if (!isBound.value) {
            botName.value = res.data.data.bot
            botURL.value  = `https://t.me/${botName.value}`
            if (!isBound.value) {
                command.value = res.data.data.command
            }

            // expires refresh
            const now = new Date().getTime()
            const diff = new Date(res.data.data.expires * 1000) - now
            timer.value = setTimeout(() => {
                getTelegramData()
            }, diff)
        }
    })
    .catch(err => {
        console.error(err)
    })
    .finally(() => {
        isLoading.value = false
    })
}

const unBind = async () => {
    isLoading.value = true

    try {
        const res =await axios.post(getApiURL('telegram'), {
            action: 'unbind',
        })
        
        showToast('操作成功', res.data.message, 'success')

        getTelegramData()
    } catch (err) {
        console.error(err)
    } finally {
        isLoading.value = false
    }
}
</script>

<template>
    <div class="card">
        <div class="card-header border-bottom p-3 px-4 text-dark">
            <div class="d-flex align-items-center">
                <i class="ni ni-send me-2"></i>
                <span>Telegram 绑定</span>
            </div>
        </div>

        <div class="card-body">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="isBound" class="form-label text-sm invisible">绑定状态</label>
                        <div class="form-check form-switch">
                            <input class="form-check-input opacity-10" type="checkbox" :checked="isBound" disabled>
                            <label class="form-check-label fw-bold opacity-10">当前{{ isBound ? '已绑定' : '未绑定' }}</label>
                        </div>
                    </div>
                </div>

                <div class="col-md-6" v-if="!isBound">
                    <div class="form-group">
                        <label class="form-label text-sm">绑定命令</label>
                        <input 
                            type="text"
                            class="form-control"
                            v-model="command"
                            maxlength="6"
                            autocomplete="off"
                            readonly
                        >
                    </div>
                </div>
            </div>

            <div class="mt-md-3 pt-md-3 mt-2 pt-2 border-top">
                <h6 class="text-sm fw-bold text-dark" style="margin-bottom: 0.75rem;">相关提示</h6>
                <ul class="tips-list">
                    <li>请复制绑定命令，发送给机器人 <a :href="botURL" target="_blank">@{{ botName }}</a></li>
                    <li>如需接受消息通知，请勿停用机器人</li>
                </ul>
            </div>

            <button class="btn btn-primary w-100 mt-4" @click="unBind" :disabled="isLoading" v-if="isBound">
                <span v-if="isLoading" class="spinner-border spinner-border-sm me-2"></span>
                解除绑定
            </button>

            <button class="btn btn-primary w-100 mt-4" @click="getTelegramData" :disabled="isLoading" v-else>
                <span v-if="isLoading" class="spinner-border spinner-border-sm me-2"></span>
                刷新数据
            </button>
        </div>
    </div>
</template>

<style scoped>
i {
    font-size: 1.125rem;
    color: var(--bs-primary);
}

.tips-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.tips-list li {
    position: relative;
    padding-left: 1.25rem;
    font-size: 0.8125rem;
    color: #67748e;
    margin-bottom: 0.5rem;
}

.tips-list li:last-child {
    margin-bottom: 0;
}

.tips-list li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0.5rem;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #67748e;
}
</style> 