<script setup>
    import { ref, computed } from 'vue'
    import { useRouter, useRoute } from 'vue-router';
    import { useStore } from 'vuex';

    import NewModal from '@/components/newModal.vue';
    import axios from '@/Plugins/axios';

    import { useSideNavToggle } from '@/composables/useSideNavToggle';
    import { useApiURL }        from '@/composables/useApiURL'
    import { useShowToast }     from '@/composables/useShowToast';

    const store             = useStore();
    const { sidenavToggle } = useSideNavToggle();
    const { showToast }     = useShowToast()
    const { getApiURL }     = useApiURL();

    const router    = useRouter();
    const route     = useRoute();
    const showLogoutModal = ref(false);
    const pageName  = computed(() => route.name);    

    const logout = async (redirect = true) => {
        try {
            await axios.post(getApiURL('logout'))
            .then(res => {
                showToast('登出成功', '正在返回登录', 'success');

                const whitList = ['hitokoto' ];
                const sessionKeys = Object.keys(sessionStorage);
                for (let i = 0; i < sessionKeys.length; i++) {
                    if (whitList.includes(sessionKeys[i])) continue;

                    sessionStorage.removeItem(sessionKeys[i]);
                }

                store.commit('clearToken');
                showLogoutModal.value = false;

                if (!redirect) return;
                setTimeout(() => { 
                    router.push({ name: '登录' });
                }, 1500);
            })
            .catch(err => { console.error(err); });
        } catch (err) {
            console.error(err);
        }
    }
</script>

<template>
    <nav class="navbar navbar-main navbar-expand-lg navbar-light bg-secondary px-0 mx-4 shadow-blur border-radius-xl z-index-sticky mt-3" data-scroll="false">
        <div class="container-fluid py-1 px-3 text-nowrap">

            <nav class="d-flex flex-row justify-content-center align-items-center" aria-label="breadcrumb">
                <div class="pe-3 border-end">
                    <button class="btn btn-outline-primary mb-0" style="padding: 3px 12px;" @click="sidenavToggle">
                        菜单
                    </button>
                </div>

                <ol class="breadcrumb bg-transparent fw-bold mb-0 py-0 px-0 ms-md-4 ms-4 d-flex align-items-center" style="scale: 110%;">
                    <li class="breadcrumb-item text-sm d-flex">
                        <i class="ni ni-compass-04" style="scale: 120%"></i>

                        <!-- <a class="" @click="sidenavToggle">
                            <i class="ni ni-bullet-list-67"></i>
                        </a> -->
                    </li>

                    <li class="breadcrumb-item text-sm" v-if="pageName !== '用户中心'">
                        <router-link :to="{ name: '用户中心' }" class="opacity-5" exact>用户中心</router-link>
                    </li>

                    <li class="breadcrumb-item text-sm active" aria-current="page" v-else>用户中心</li>

                    <li class="breadcrumb-item text-sm active" aria-current="page" v-if="pageName !== '用户中心'">{{ pageName }}</li>
                </ol>
            </nav>

            <div class="collapse navbar-collapse justify-content-end position-absolute end-0">
                <ul class="navbar-nav">
                    <li class="nav-item px-3 d-flex align-items-center">
                        <a class="nav-link p-0" href="javascript:;" @click="showLogoutModal = true">
                            <i class="ni ni-button-power cursor-pointer text-danger d-flex"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

    <!-- 登出确认模态框 -->
    <NewModal 
        v-model="showLogoutModal"
        title="登出确认"
        size="sm"
        :show-close="true"
    >
        <div class="text-center">
            <p class="mb-0">请确认是否退出本次登录？</p>
        </div>

        <template #buttons>
            <button 
                class="btn btn-sm btn-primary mb-0 me-2"
                @click="logout()"
            >
                确认登出
            </button>
        </template>
    </NewModal>
</template>