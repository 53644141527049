<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useApiURL } 	from '@/composables/useApiURL'
import { useShowToast } from '@/composables/useShowToast'
import axios 			from '@/Plugins/axios'
import { useRouter } 	from 'vue-router'

// Props 定义
const props = defineProps({
	shopData: {
		type: Array,
		required: true
	}
})

// Composables
const { getApiURL } = useApiURL()
const { showToast } = useShowToast()
const router = useRouter()

/**
 * 常量配置
 */
const DURATIONS = [
	{ value: 1,  label: '月付', discount: 1 },
	{ value: 3,  label: '季付', discount: 1 },
	{ value: 6,  label: '半年付', discount: 1 },
	{ value: 12, label: '年付', discount: 0.85, tag: '-16.67%' }
]

const LEVELS = [
	{ value: 1, label: '基础' },
	{ value: 2, label: '进阶' },
	{ value: 3, label: '专业' }
]

const DURATION_LABELS = {
	30: '月付',
	90: '季付',
	180: '半年付',
	360: '年付'
}

/**
 * 响应式状态
 */
// 数据状态
const grouped = ref([])    // 分组后的套餐列表（用于展示）
const packages = ref([])   // 原始套餐数据

// 加载状态
const priceLoading = ref(false)

// 表单状态
const formState = ref({
	showDetail: false,        // 是否显示详情页
	selected: null,           // 当前选中的套餐
	selectedDuration: 1,      // 选中的时长
	selectedLevel: 1,         // 选中的等级
	isMerge: true,           // 是否合并套餐
	isAutoRenew: true,       // 是否自动续费
	isLoading: false,        // 加载状态
	couponCode: '',          // 优惠码
	isCheckingCoupon: false, // 优惠码验证状态
	couponMessage: {         // 优惠码消息
		type: '',
		text: ''
	}
})

// 价格状态
const priceState = ref({
	total: 0,    // 套餐原价
	balance: 0,  // 余额抵扣
	remark: 0,   // 批次抵扣
	discount: 0, // 优惠抵扣
	final: 0     // 最终价格
})

/**
 * 计算属性
 */
// 检查套餐是否可用
const isAvailable = computed(() => {
	if (!packages.value?.length) return () => false
	
	return (level, duration) => packages.value.some(p => 
		p.level === level && 
		p.duration === duration * 30
	)
})

// 当前选中套餐信息
const selectedPackageInfo = computed(() => {
	if (!formState.value.selected) return null

	const pkg = formState.value.selected
	return {
		name: `${pkg.name} - ${DURATION_LABELS[pkg.duration]}`,
		features: [
			`${pkg.traffic}GB 流量`,
			`${pkg.content.ipLimit}个在线限制`,
			pkg.content.speedlimit ? `限速${pkg.content.speedlimit}Mbps` : '不限速',
			'文档支持'
		]
	}
})

/**
 * 方法定义
 */
// 处理套餐数据
const handleShopData = () => {
	if (!props.shopData?.length) return
	
	const getFeatures = (item) => [
		`每月${item.traffic}GB 流量起`,
		item.content.ipLimit ? `${item.content.ipLimit}个在线限制` : '不限设备数',
		item.content.speedlimit ? `限速${item.content.speedlimit}Mbps` : '不限速',
		'文档支持'
	]
	
	const alias = { 1: '基础', 2: '进阶', 3: '专业' }
	
	// 处理展示用的套餐列表
	grouped.value = props.shopData
		.filter(item => item.duration === 30)
		.map(item => ({
			...item,
			name: `${alias[item.level]}套餐`,
			price: Number(item.price),
			features: getFeatures(item)
		}))

	// 保存原始数据
	packages.value = props.shopData
}

// 更新价格信息
const updatePrice = async (checkingCoupon = false) => {
	if (!formState.value.selected?.id) return
	
	priceLoading.value = true
	
	try {
		const res = await axios.get(`${getApiURL('shop')}/${formState.value.selected.id}`, {
			params: formState.value.couponCode ? { discount: formState.value.couponCode } : {}
		})
		
		const { amount } = res.data
		// 更新所有价格字段
		Object.keys(priceState.value).forEach(key => {
			priceState.value[key] = Number(amount[key]).toFixed(2)
		})

		// 更新优惠码状态
		if (checkingCoupon) {
			formState.value.couponMessage = {
				type: amount.discount > 0 ? 'success' : 'error',
				text: amount.discount > 0 
					? `优惠码有效，可抵扣 ¥${amount.discount}` 
					: '优惠码无效'
			}
		}
		
		return amount
	} catch (error) {
		console.error(error)
		if (checkingCoupon) {
			formState.value.couponMessage = {
				type: 'error',
				text: error.response?.data?.message || '优惠码验证失败'
			}
		}
		return null
	} finally {
		priceLoading.value = false
	}
}

// 处理套餐选择
const handleSelect = (level = null) => {
	const nextLevel = level || formState.value.selectedLevel
	const nextDuration = formState.value.selectedDuration || 1

	const matchedPackage = packages.value.find(pkg => 
		pkg.level === nextLevel && 
		pkg.duration === nextDuration * 30
	)

	if (matchedPackage) {
		// 记录之前的选择状态
		const prevSelected = formState.value.selected?.id
		
		// 更新选择
		formState.value.selectedLevel = nextLevel
		formState.value.selected = {
			...matchedPackage,
			name: `${LEVELS.find(l => l.value === nextLevel).label}套餐`
		}

		// 如果是首次选择或者套餐ID发生变化，才更新价格
		if (!prevSelected || prevSelected !== matchedPackage.id) {
			updatePrice()
		}

		// 最后才更新显示状态，避免watch触发时的重复请求
		if (!formState.value.showDetail) {
			formState.value.showDetail = true
		}
	}
}

// 创建订单
const createOrder = async () => {
	if (formState.value.isLoading) return
	formState.value.isLoading = true
	
	try {
		const res = await axios.post(getApiURL('purchase'), {
			id: formState.value.selected.id,
			renew: formState.value.isAutoRenew,
			merge: formState.value.isMerge,
			discount: formState.value.couponCode || undefined
		})

		// 处理响应
		if (res.data.needPay) {
			showToast('余额不足', '正在跳转至订单页', 'warning')
		} else {
			showToast('操作成功', '套餐订购成功', 'success')
		}

		// 跳转到订单页
		setTimeout(() => {
			router.push({ name: '订单页', params: { id: res.data.order } })
		}, 1200)
	} catch (error) {
		showToast('错误', error.response?.data?.message || '创建订单失败', 'error')
	} finally {
		formState.value.isLoading = false
	}
}

// 检查优惠码
const checkCoupon = async () => {
	if (!formState.value.couponCode || formState.value.isCheckingCoupon) return
	
	formState.value.isCheckingCoupon = true
	formState.value.couponMessage = { type: '', text: '' }
	
	await updatePrice(true)
	formState.value.isCheckingCoupon = false
}

/**
 * 生命周期和监听器
 */
onMounted(() => handleShopData())

// 监听数据源变化
watch(() => props.shopData, handleShopData, { deep: true })

// 监听套餐选择变化
watch(
	[
		() => formState.value.selectedLevel,
		() => formState.value.selectedDuration
	],
	() => {
		if (formState.value.showDetail) {
			handleSelect()
		}
	},
	{ deep: true }
)
</script>

<template>
	<div v-if="!formState.showDetail" class="row g-4">
		<!-- 套餐列表 -->
		<div class="col-12">
			<h6 class="mb-n3">
				<i class="fas fa-shopping-cart me-2"></i>套餐列表
			</h6>
		</div>
		
		<!-- 套餐卡片 -->
		<div v-for="pkg in grouped" :key="pkg.id" class="col-lg-4 col-md-6">
			<div class="card border h-100">
				<div class="card-header text-center p-3">
					<h5 class="mb-0">
						<i class="fas fa-cube me-2"></i>{{ pkg.name }}
					</h5>
					<span class="badge bg-gradient-primary mt-2">
						￥{{ pkg.price }} 起
					</span>
				</div>
				<div class="card-body p-3">
					<ul class="list-unstyled mb-0">
						<li v-for="(feature, index) in pkg.features" 
							:key="index"
							class="d-flex align-items-center mb-2"
						>
							<i class="fas fa-check-circle text-success me-2"></i>
							<span class="text-sm">{{ feature }}</span>
						</li>
					</ul>
				</div>
				<div class="card-footer text-center p-3">
					<button 
						class="btn btn-primary mb-0 w-100"
						@click="handleSelect(pkg.level)"
						:disabled="formState.isLoading"
					>
						<i class="fas fa-shopping-cart me-2"></i>
						<span v-if="formState.isLoading" class="spinner-border spinner-border-sm me-1"></span>
						立即购买
					</button>
				</div>
			</div>
		</div>
	</div>

	<!-- 订单确认 -->
	<div v-else class="card">
		<div class="card-header p-3 pb-0">
			<h6 class="mb-0">
				<i class="fas fa-clipboard-check me-2"></i>确认套餐
			</h6>
		</div>
		<div class="card-body p-3">
			<!-- 套餐信息 -->
			<div class="border rounded p-3 mb-4">
				<div class="d-flex align-items-center">
					<i class="fas fa-cube me-3 text-dark fa-lg"></i>
					<div>
						<h6 class="mb-1">{{ selectedPackageInfo.name }}</h6>
						<p class="text-sm mb-0">
							<span 
								v-for="(feature, index) in selectedPackageInfo.features"
								:key="index"
								class="me-3"
							>
								<i class="fas fa-check text-success me-1"></i>
								{{ feature }}
							</span>
						</p>
					</div>
				</div>
			</div>

			<!-- 等级选择 -->
			<div class="form-group mb-4">
				<label class="form-label">
					<i class="fas fa-layer-group me-2"></i>选择等级
				</label>
				<div class="select-group">
					<div 
						v-for="level in LEVELS"
						:key="level.value"
						class="select-option"
						:class="{ 
							active: formState.selectedLevel === level.value,
							'opacity-50': !isAvailable(level.value, formState.selectedDuration)
						}"
						@click="isAvailable(level.value, formState.selectedDuration) && 
							(formState.selectedLevel = level.value)"
					>
						<div class="select-content">
							{{ level.label }}(Lv.{{ level.value }})
						</div>
					</div>
				</div>
			</div>

			<!-- 时长选择 -->
			<div class="form-group mb-4">
				<label class="form-label">
					<i class="fas fa-clock me-2"></i>选择时长
				</label>
				<div class="select-group">
					<div 
						v-for="duration in DURATIONS"
						:key="duration.value"
						class="select-option"
						:class="{ 
							active: formState.selectedDuration === duration.value,
							'opacity-50': !isAvailable(formState.selectedLevel, duration.value)
						}"
						@click="isAvailable(formState.selectedLevel, duration.value) && 
							(formState.selectedDuration = duration.value)"
					>
						<div class="select-content">
							{{ duration.label }}
						</div>
						<span v-if="duration.tag" class="discount-tag">{{ duration.tag }}</span>
					</div>
				</div>
			</div>

			<!-- 附加选项 -->
			<div class="form-group mb-2">
				<div class="form-check mb-2">
					<input 
						class="form-check-input" 
						type="checkbox"
						v-model="formState.isMerge"
						id="mergeCheck"
					>
					<label class="form-check-label" for="mergeCheck">
						<i class="fas fa-object-group me-2"></i>合并套餐
						<small class="d-block text-secondary">
							选择此项将把旧套餐时长合并到新套餐中
						</small>
					</label>
				</div>
				<div class="form-check">
					<input 
						class="form-check-input" 
						type="checkbox"
						v-model="formState.isAutoRenew"
						id="autoRenewCheck"
					>
					<label class="form-check-label" for="autoRenewCheck">
						<i class="fas fa-sync-alt me-2"></i>自动续费
						<small class="d-block text-secondary">
							开启后将在套餐到期时自动续费，可随时取消
						</small>
					</label>
				</div>
			</div>

			<!-- 优惠码 -->
			<div class="form-group mb-3">
				<label class="form-label">
					<i class="fas fa-ticket-alt me-2"></i>优惠码
				</label>
				<div class="input-group">
					<input 
						type="text" 
						class="form-control"
						v-model="formState.couponCode"
						placeholder="如有优惠码请在此输入"
						:disabled="formState.isLoading || formState.isCheckingCoupon"
					>
					<button 
						class="btn btn-outline-primary mb-0"
						@click="checkCoupon"
						:disabled="!formState.couponCode || formState.isCheckingCoupon || formState.isLoading"
					>
						<span v-if="formState.isCheckingCoupon" class="spinner-border spinner-border-sm"></span>
						<span v-else>应用</span>
					</button>
				</div>
				<!-- 优惠码状态提示 -->
				<div v-if="formState.couponCode && formState.couponMessage.text" 
					class="mt-2"
					:class="{
						'text-success': formState.couponMessage.type === 'success',
						'text-danger': formState.couponMessage.type === 'error'
					}"
				>
					<i :class="`fas fa-${formState.couponMessage.type === 'success' ? 'check' : 'times'}-circle me-1`"></i>
					{{ formState.couponMessage.text }}
				</div>
			</div>

			<!-- 价格展示 -->
			<div class="alert alert-light mb-4">
				<div class="d-flex justify-content-between align-items-start mb-2">
					<div>
						<h6 class="mb-1">
							<i class="fas fa-receipt me-2"></i>价格明细
						</h6>
						<p class="text-xs text-muted mb-0">
							实际价格以订单页显示为准
						</p>
					</div>
					<h3 class="mb-0 fw-bold text-primary">
						<div v-if="priceLoading" class="text-center">
							<div class="spinner-border text-primary" role="status">
								<span class="visually-hidden">Loading...</span>
							</div>
						</div>
						<span v-else>￥{{ priceState.total }}</span>
					</h3>
				</div>

				<!-- 价格明细 -->
				<div class="border-top pt-3 mt-3">
					<!-- 优惠/批次抵扣 -->
					<div class="d-flex justify-content-between align-items-center mb-2" 
						v-if="priceState.discount > 0 || (priceState.remark > 0 && priceState.discount == 0)"
					>
						<span class="text-sm text-muted">
							{{ priceState.discount > 0 ? '优惠抵扣' : '批次抵扣' }}
						</span>
						<div class="d-flex align-items-center">
							<div v-if="priceLoading" class="spinner-border spinner-border-sm text-primary" role="status">
								<span class="visually-hidden">Loading...</span>
							</div>
							<span v-else class="text-sm fw-bold text-secondary">
								-¥{{ priceState.discount > 0 ? priceState.discount : priceState.remark }}
							</span>
						</div>
					</div>

					<!-- 余额抵扣 -->
					<div class="d-flex justify-content-between align-items-center mb-2" v-if="priceState.balance > 0">
						<span class="text-sm text-muted">余额抵扣</span>
						<div class="d-flex align-items-center">
							<div v-if="priceLoading" class="spinner-border spinner-border-sm text-primary" role="status">
								<span class="visually-hidden">Loading...</span>
							</div>
							<span v-else class="text-sm fw-bold text-secondary">-¥{{ priceState.balance }}</span>
						</div>
					</div>

					<!-- 应付金额 -->
					<div class="border-top pt-2 mt-2">
						<div class="d-flex justify-content-between align-items-center">
							<span class="text-sm fw-bold text-dark">应付金额</span>
							<div class="d-flex align-items-center">
								<div v-if="priceLoading" class="spinner-border spinner-border-sm text-primary" role="status">
									<span class="visually-hidden">Loading...</span>
								</div>
								<span v-else class="fs-6 fw-bolder text-primary">¥{{ priceState.final }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 操作按钮 -->
			<div class="d-flex justify-content-end gap-2">
				<button class="btn btn-secondary mb-0" @click="formState.showDetail = false">
					<i class="fas fa-arrow-left me-2"></i>返回
				</button>

				<button class="btn btn-primary mb-0" @click="createOrder" :disabled="formState.isLoading">
					<i class="fas fa-check me-2"></i>
					<span v-if="formState.isLoading" class="spinner-border spinner-border-sm me-1"></span>
					创建订单
				</button>
			</div>
		</div>
	</div>
</template>

<style scoped>
.select-group {
	display: flex;
	gap: 1rem;
	width: 100%;
}

.select-option {
	flex: 1;
	background: #fff;
	border: 1px solid #e9ecef;
	border-radius: 0.5rem;
	padding: 1rem;
	cursor: pointer;
	transition: all 0.2s ease;
	position: relative;
	min-height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: visible;
}

.select-option:hover {
	border-color: #5e72e4;
	background: #f8f9fe;
}

.select-option.active {
	border-color: #5e72e4;
	background: #eaecfb;
}

.select-content {
	text-align: center;
	font-weight: 500;
	color: #344767;
	position: relative;
}

.discount-tag {
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(10%, -50%);
	background: #2dce89;
	color: white;
	padding: 0.15rem 0.5rem;
	border-radius: 0.25rem;
	font-size: 0.75rem;
	font-weight: 600;
	white-space: nowrap;
	z-index: 1;
}

/* 响应式调整 */
@media (max-width: 768px) {
	.select-group {
		flex-direction: column;
		gap: 0.5rem;
	}

	.select-option {
		min-height: 50px;
	}
}

.alert-light {
	background-color: #f8f9fa;
	border: 1px solid #e9ecef;
}

.border-top {
	border-top: 1px solid #e9ecef !important;
}
</style>