export function useDataTable() {
    /**
     * * 数据翻译
     * 
     * @param {String} field 
     * @param {String} key 
     * @param {*} value 
     */
    const dataTrans = (field, key, value) => {
        const transData = {
            invoice: {
                id: `<span class="text-sm">#${value}</span>`,
                plan_id: value === null ? '余额储值' : `套餐购买 #${value}`,
                status: value == 0 ? '<i class="text-danger ni ni-fat-remove"></i>未支付' : '<i class="text-success ni ni-check-bold"></i>已支付',
                amount: value + ' CNY',
            },
            order: {
                id: `<span class="text-sm">#${value}</span>`,
                discount_id:        value === null ? '无' : `<span class="text-sm">#${value}</span>`,
                shop_id:            value === null ? '无' : `<span class="text-sm">#${value}</span>`,
                plan_id:            value === null ? '无' : `<span class="text-sm">#${value}</span>`,
                invoice_id:         value === null ? '无' : `<span class="text-sm">#${value}</span>`,
                payment_id:         value === null ? '无' : `<span class="text-sm">#${value}</span>`,
                paid_at:            value === null ? '未记录' : value,
                total_amount:       value,
                discount_amount:    value,
                balance_amount:     value,
                handle_amount:      value,
                type: [
                    '未知',
                    '<span class="text-success">新购套餐</span>',
                    '<span class="text-primary">续费套餐</span>',
                    '<span class="text-warning">增值服务</span>',
                    '<span class="text-info">余额储值</span>',
                    '<span class="text-info">套餐合并</span>'
                ][value],
                status: [
                    '<i class="text-primary ni ni-delivery-fast me-2"></i>等待中',
                    '<i class="text-success ni ni-check-bold me-2"></i>已完成',
                    '<i class="text-danger ni ni-fat-remove me-2"></i>已取消',
                    '<i class="text-warning ni ni-money-coins me-2"></i>已支付'
                ][value],
            },
            referral: {
                userid: `<small>#${value}</small>`,
                ref_total: value + ' CNY',
                ref_get: value + ' CNY',
                ref_count: value + ' 次',
            },
            subscribe: {
                id: `<small>#${value}</small>`,
            },
        }
    
        // * 如果没有对应的翻译数据
        if (!transData[field] && !transData[key]) return value;
    
        let transValue;
    
        if (transData[key]) {
            // * 如果有对应的翻译数据
            transValue = transData[key];
        } else if (transData[field][key]) {
            // * 如果有对应的翻译数据且有对应的字段
            transValue = transData[field][key];
        } else {
            // * 如果没有对应的字段
            transValue = value;
        }
    
        return transValue;
    }

    /**
     * * 列名翻译
     * 
     * @param {String} field 
     * @param {String} key 
     * @returns 
     */
    const columnTrans = (field, key) => {
        const transData = {
            invoice: {
                plan_id:  '类型',
                amount:   '金额',
                status:   '状态',
                transaction_id: '编码',
            },
            order: {
                shop_id:         '商品 ID',
                plan_id:         '套餐 ID',
                invoice_id:      '账单 ID',
                discount_id:     '优惠 ID',
                content:         '内容',
                type:            '订单类型',
                total_amount:    '订单总价',
                discount_amount: '优惠金额',
                balance_amount:  '余额抵扣',
                handle_amount:   '实际金额',
                status:          '订单状态',
                paid_at:         '支付时间',
            },
            referral: {
                invoice_id: '账单ID',
                ref_total: '总计收益',
                ref_count: '收益次数',
                ref_get:   '收益金额',
                userid:    '用户ID',
            },
            subscribe: {
                token: '订阅密钥',
                ip: '请求IP',
                ua: '请求UA',
                request_ip: '请求IP',
                request_user_agent: '请求UA',
                subscribe_token: '请求密钥',
                subscribe_type: '订阅类型',
            },
            ticket: {
                operator: '操作者',
            },
            status: '状态',
            title: '标题',
            type: '类型',
            created_at: '创建时间',
            updated_at: '更新时间',
            expired_at: '过期时间',
        };
    
        if (!transData[field] && !transData[key]) {
            return key;
        }
    
        let transText;
    
        if (transData[key]) {
            transText = transData[key];
        } else if (transData[field][key]) {
            transText = transData[field][key];
        } else {
            transText = key;
        }
    
        return transText;
    }

    return {
        dataTrans,
        columnTrans,
    }
}