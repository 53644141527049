export function useSideNavToggle() {
    const sidenavToggle = () => {
        const sidenavShow = document.querySelector('.g-sidenav-show');

        if (!sidenavShow) {
            console.error('Element with class "g-sidenav-show" not found.');
            return;
        }
    
        if (!sidenavShow.classList.contains('g-sidenav-hidden')) {
            sidenavShow.classList.replace('g-sidenav-pinned', 'g-sidenav-hidden');
        } else {
            sidenavShow.classList.replace('g-sidenav-hidden', 'g-sidenav-pinned');
        }
    
        if (document.querySelector('meta[name="isNavListened"]') === null) {
            const meta = document.createElement('meta');
            meta.setAttribute('name', 'isNavListened');
            document.head.insertBefore(meta, document.querySelector('meta[name="payment"]'));

            document.querySelector('body').addEventListener('click', function(e) {
                if (document.querySelector('body').classList.contains('g-sidenav-pinned')) {
                    sidenavToggle();
                }
            }, true);
        }
    }

    return { sidenavToggle };
}