<script setup>
import axios from '@/Plugins/axios';
import { defineAsyncComponent, markRaw } from 'vue';

// import tipTap           from '@/components/argon-dashboard-pro-2/tipTap.vue';
import SimpleDT         from '@/components/argon-dashboard-pro-2/dataTable.vue';

import { useUserApi }   from '@/composables/useUserApi';
</script>

<template>
    <div class="container-fluid py-4">
        <div class="row" v-if="currentTicketType === 1">

            <!-- * ticket list -->
            <div class="col-xl-3 col-md-4 col-12 mb-sm-0">
                <div class="text-center">
                    <button class="btn btn-sm btn-primary mt-2 mb-3 w-90" @click="currentView = 'newTicket'">+ 创建工单</button>
                </div>

                <div class="card">
                    <div class="card-header pb-0 p-3">
                        <h5 class="text-capitalize mb-0">最近的工单</h5>
                    </div>

                    <div class="card-body p-3">
                        <div v-if="currentTicketType === 1">
                            <label>开启的工单</label>
                            <ul class="list-group">
                                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg" v-for="ticket in tickets.opening">
                                    <div class="d-flex align-items-center">
                                        <div class="icon icon-shape icon-sm me-3 bg-gradient-success shadow text-center">
                                            <i class="ni ni-bell-55 fs-6 pt-1 text-white opacity-10"></i>
                                        </div>
                                        <div class="d-flex flex-column">
                                            <h6 class="mb-1 text-dark text-sm">{{ ticket.title }}</h6>
                                            <span class="text-xs">{{ ticket.created_at }}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <button class="btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right my-auto" @click="getTicket(ticket.id)"><i class="ni ni-bold-right" aria-hidden="true"></i></button>
                                    </div>
                                </li>

                                <li class="list-group-item border-0 my-0 py-0 border-radius-lg" v-if="tickets.opening.length === 0">
                                    <div class="d-flex justify-content-start">
                                        <h6 class="mb-0 text-dark text-sm">#当前没有处理中的工单</h6>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div  v-if="currentTicketType === 0">
                            <label>关闭的工单</label>
                            <ul class="list-group hidden-scrollbar" style="overflow-y: scroll;">
                                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg" v-for="ticket in tickets.closed">
                                    <div class="d-flex align-items-center">
                                        <div class="icon icon-shape icon-sm me-3 bg-gradient-secondary shadow text-center">
                                            <i class="ni ni-fat-remove fs-5 pt-1 text-white opacity-10"></i>
                                        </div>
                                        <div class="d-flex flex-column">
                                            <h6 class="mb-1 text-dark text-sm">{{ ticket.title }}</h6>
                                            <span class="text-xs">{{ ticket.created_at }}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <button class="btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right my-auto" @click="getTicket(ticket.id)"><i class="ni ni-bold-right" aria-hidden="true"></i></button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="card-footer text-end pt-0 pb-3">
                        <button class="btn btn-xs btn-outline-secondary mb-0" @click="currentTicketType ^= true">查看所有</button>
                    </div>
                </div>
            </div>

            <!-- * current ticket content -->
            <div class="col-xl-7 col-md-8 col-12">

                <div class="card mt-4 mt-md-0" v-if="currentView === 'ticketDetail'">
                    <div class="card-header">
                        <h5 class="text-capitalize mb-0">{{ currentTicket.title }}</h5>
                    </div>

                    <!-- <Simplebar> -->
                    <div class="card-body overflow-auto overflow-x-hidden hidden-scrollbar ticketMessages pt-0 mt-n2" id="currentTicketMessages">

                        <div class="row">
                            <div class="col-12">
                                <span class="badge bg-gradient-success me-2" v-if="!currentTicket.status">处理中</span>
                                <span class="badge bg-gradient-danger me-2" v-else>已结单</span>
                                <span class="badge bg-gradient-secondary me-2">#{{ currentTicket.id }}</span>
                            </div>

                            <div class="col-12 text-center">
                                <span class="badge text-dark py-0">{{ currentTicket.created_at }} 创建</span>
                                <br/>
                                <span class="badge text-dark py-0">此处可上下滑动查看更多内容</span>
                            </div>

                            <div class="col-12">
                                <div v-for="message in currentTicket.messages">
                                    <div class="row text-right my-4" :class="{ 'justify-content-end': message.operator === 0 }">
                                        <div class="col-xl-5 col-sm-6 col-12" style="min-width: 30%">
                                            <div class="card border-1 border-secondary" :class="{'bg-gray-100': message.operator === 0}">
                                                <div class="card-body pb-2 pt-3 px-3">
                                                    <span class="text-xs text-secondary" v-if="message.operator === 0">
                                                        <i class="ni ni-single-02 text-dark"></i> 你
                                                    </span>

                                                    <div class="ticketMessage ps-3 pt-1" v-html="message.message"></div>
                                                    <div class="d-flex align-items-center text-sm opacity-6" :class="{ 'justify-content-end': message.operator === 0 }">
                                                        <i class="ni ni-check-bold text-sm me-1"></i>
                                                        <small>{{ message.created_at }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 text-center">
                                <span class="badge text-dark" ref="ticketEnd">已经到底了</span>
                            </div>
                        </div>
                    </div>
                    <!-- </Simplebar> -->
                    <div class="card-footer pt-0">
                        <form class="align-items-center">
                            <div class="row">
                                <div class="col-12">
                                    <component v-model="currentTicket.replyMessage" v-if="tiptapComponent" :is="tiptapComponent" />
                                    <!-- <tipTap v-model="currentTicket.replyMessage" /> -->
                                </div>

                                <div class="col-12 mt-3">
                                    <button class="btn btn-primary w-100" @click="replyTicket($event)" v-if="!currentTicket.status">发送回复</button>
                                    <button class="btn btn-secondary w-100 disabled" v-else>工单已关闭</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="row" v-if="currentView === 'newTicket'">
                    <div class="col-md-8 col-12">
                        <div class="card h-100">
                            <div class="card-header">
                                <h5 class="text-capitalize mb-0">提示信息</h5>
                            </div>

                            <div class="card-body pt-0">
                                <p class="mb-0">请在提交工单前仔细阅读以下内容</p>
                                <p class="mb-0">1. 请勿以任何形式及途径辱骂处理人员，否则可能以违反TOS条例为由封禁账号</p>
                                <p class="mb-0">2. 工单处理非即时聊天，一般在24小时内进行处理</p>
                                <p class="mb-0">3. 请准确清晰的描述问题及问题相关环境（网络，系统，终端等相关因素）</p>
                                <p class="mb-0">4. 传输图片请使用图床如（https://jpg.dog）</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 col-12 mt-md-0 mt-2">
                        <div class="card h-100">
                            <div class="card-header">
                                <h5 class="text-capitalize mb-0">工单信息</h5>
                            </div>

                            <div class="card-body pt-0">
                                <label>工单标题</label>
                                <input type="text" class="form-control mb-2" placeholder="请输入工单标题" v-model="newTicketData.title">

                                <label>优先级</label>
                                <SlimSelect v-model="newTicketData.priority" :settings="{showSearch: false}">
                                    <option value="low">低 - Low</option>
                                    <option value="medium">中 - Medium</option>
                                    <option value="high">高 - High</option>
                                </SlimSelect>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mt-4">
                        <div class="card">
                            <div class="card-header">
                                <h5 class="text-capitalize mb-0">工单内容</h5>
                            </div>

                            <div class="card-body">
                                <component v-model="newTicketData.content" v-if="tiptapComponent" :is="tiptapComponent" />
                                <!-- <tipTap v-model="newTicketData.content" /> -->
                            </div>

                            <div class="card-footer">
                                <button class="btn btn-primary w-100" @click="newTicket(true, $event)">确认提交</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" v-if="currentView === 'waiting'">
                    <div class="col-12 d-flex justify-content-center align-items-center custom-ticket-bg-text" style="height: 60vh;">
                        <p class="mb-0 fs-3">请选择或创建工单</p>
                    </div>
                </div>

                <div class="row" v-if="currentView === 'loading'">
                    <div class="col-12 d-flex justify-content-center align-items-center" style="height: 60vh;">
                        <div class="spinner-border text-primary" role="status"></div>
                        <span class="ms-3 text-dark text-bold">Loading...</span>
                    </div>
                </div>
                
            </div>
        </div>

        <div class="row" v-else>
            <SimpleDT tableName="工单列表" tableClass="ticket" :rawTableData="tickets.raw" @ticket_detail="getTicket($event)">
                <button class="btn btn-sm bg-gradient-dark mb-0" @click="currentTicketType = 1">返回</button>
            </SimpleDT>
        </div>
    </div>
</template>

<script>
const { getTicketsData, getTicketData } = useUserApi();

export default {
    name: 'ticketCenterView',
    // components: { tipTap },
    data() {
        return {
            tiptapComponent: markRaw(
                defineAsyncComponent({
                    'loader': () => import('@/components/argon-dashboard-pro-2/tipTap.vue'),
                    'loadingComponent': {
                        template: '<div class="spinner-border text-primary" role="status"></div>'
                    },
                    'errorComponent': {
                        template: '<div class="text-danger">加载失败，请刷新页面</div>'
                    },
                    'delay': 200,
                    'timeout': 3000
                }),
            ),

            currentView: 'waiting',
            currentTicketType: 1,
            tickets: {
                raw: [],
                opening: [],
                closed: []
            },
            currentTicket: {
                id: '等待加载',
                title: '等待加载',
                status: true,
                messages: [],
                replyMessage: null
            },
            newTicketData: {
                title: '',
                priority: 'low',
                content: ''
            }
        }
    },
    mounted() {
        this.getTickets()
    },
    methods: {
        getTickets(useCache = true) {
            getTicketsData(useCache)
            .then((res) => {
                let tickets = res.data;
                // 只取前20个对象
                tickets = tickets.slice(0, 5); 

                this.tickets.raw = tickets

                this.setTickets(tickets)
            })
            .catch(err => { console.error(err); })
        },
        getTicket(id) {
            if (this.currentTicketType === 0) {
                this.currentTicketType = 1
            }

            this.currentView = 'loading'

            getTicketData(id)
            .then((res) => {
                let data = res.data;
                this.currentTicket = data;

                // 格式化时间
                data.created_at = new Date(data.created_at).toLocaleString('zh-CN', { hour12: false });
                data.updated_at = data.updated_at === null ? data.created_at : new Date(data.updated_at).toLocaleString('zh-CN', { hour12: false });

                setTimeout(() => {  this.currentView = 'ticketDetail'; }, 350);

                 setTimeout(() => {
                    const end = this.$refs.ticketEnd;

                    if (end !== undefined) {
                        end.scrollIntoView({ behavior: 'smooth' });
                    }
                 }, 550);
            })
            .catch(err => { console.error(err); })
        },
        setTickets(data) {
            let opening = []
            let closed  = []

            data.forEach((ticket) => {
                ticket.created_at = new Date(ticket.created_at).toLocaleString('zh-CN', { hour12: false })
                ticket.status === 0 ? opening.push(ticket) : closed.push(ticket)
            })

            this.tickets.opening = opening
            this.tickets.closed  = closed
        },
        newTicket(isSubmit = false, $event) {
            if (isSubmit === false) {
                this.currentView = 'loading'
                setTimeout(() => { this.currentView = 'newTicket'; }, 350);
                return;
            }

            $event.target.disabled = true

            axios.post(this.$getApiURL('ticket'), {
                title:    this.newTicketData.title,
                message:  this.newTicketData.content,
                priority: this.newTicketData.priority,
            })
            .then((res) => {
                this.$showToast('操作成功', res.data.message, 'success');

                // * 刷新工单数据
                this.getTickets(false);
                this.getTicket(res.data.data.id);
            })
            .catch(err => { console.error(err); })
            .finally(() => { $event.target.disabled = false })
        },
        replyTicket($event) {
            $event.target.disabled = true

            axios.post(`${this.$getApiURL('ticket')}/${this.currentTicket.id}`, {
                message:  this.currentTicket.replyMessage
            })
            .then((res) => {
                this.$showToast('操作成功', res.data.message, 'success');

                // * 刷新工单数据
                this.getTickets(false);
                this.getTicket(this.currentTicket.id);

                // * 滑动到底部
                document.getElementById('currentTicketMessages').scrollTop = document.getElementById('currentTicketMessages').scrollHeight
            })
            .catch(err => { console.error(err); })
            .finally(() => { $event.target.disabled = false })
        }
    }
}
</script>

<style>
/* col lg xl height 800 */
@media (min-width: 1200px) {
    .ticketMessages {
        height: 600px;
    }

    .list-group {
        max-height: 70vh
    }
}
/* col md height 600 */
@media (min-width: 768px) and (max-width: 1199px) {
    .ticketMessages {
        height: 600px;
    }

    .list-group {
        max-height: 100vh
    }
}
/* col sm height 400 */
@media (min-width: 576px) and (max-width: 767px) {
    .ticketMessages {
        height: 400px;
    }

    .list-group {
        max-height: 40vh
    }
}

/* col xs height 600 */
@media (max-width: 575px) {
    .ticketMessages {
        height: 400px;
    }

    .list-group {
        max-height: 40vh
    }
}

.custom-ticket-bg-text>p {
    filter: grayscale(20%);
    font-weight: 700;
    opacity: 0.8;
}

.ticketMessage>p {
    margin-bottom: 0;
}

td>p {
    margin-bottom: 0;
}
</style>