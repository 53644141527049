<script setup>
import { ref } from 'vue'
import axios from '@/Plugins/axios'
import { useShowToast } from '@/composables/useShowToast'
import { useApiURL } from '@/composables/useApiURL'
import newModal from '@/components/newModal.vue'

const { showToast } = useShowToast()
const { getApiURL } = useApiURL()

const props = defineProps({
	tokens: {
		type: Array,
		default: () => []
	},
	remaining: {
		type: Number,
		default: 3
	}
})

const emit = defineEmits(['refresh'])
const isLoading = ref(false)
const newRemark = ref({})
const showModal = ref(false)
const confirmAction = ref({
	type: '',
	id: null,
	title: '',
	message: '',
	subMessage: '',
	confirmText: '',
	action: null
})
const resetWithPassword = ref(false)

function showConfirm(id, type) {
	const configs = {
		create: {
			title: '新建令牌',
			message: `您当前剩余可用令牌数量：${props.remaining}`,
			confirmText: '新建',
			action: () => handleCreate()
		}
	}

	if (id) {
		const token = props.tokens.find(t => t.id === id)
		if (type === 'updateRemark') {
			newRemark.value[id] = token.remark || ''
		}
		
		configs.delete = {
			title: '删除令牌',
			message: '确定要删除该令牌吗？删除后将无法恢复。',
			subMessage: `令牌ID：#${token.id}${token.remark ? ` (${token.remark})` : ''}`,
			confirmText: '删除',
			action: () => handleDelete(id)
		}
		configs.updateStatus = {
			title: token.enable ? '停用令牌' : '启用令牌',
			message: `确定要${token.enable ? '停用' : '启用'}该令牌吗？`,
			subMessage: `令牌ID：#${token.id}${token.remark ? ` (${token.remark})` : ''}`,
			confirmText: token.enable ? '停用' : '启用',
			action: () => handleUpdate(id, 'updateStatus')
		}
		configs.updateRemark = {
			title: '修改备注',
			message: '请输入新的备注信息：',
			subMessage: `令牌ID：#${token.id}${token.remark ? ` (${token.remark})` : ''}`,
			confirmText: '保存',
			action: () => handleUpdate(id, 'updateRemark')
		}
		configs.resetToken = {
			title: '重置令牌',
			message: '确定要重置该令牌吗？重置后原令牌将无法使用。',
			subMessage: `令牌ID：#${token.id}${token.remark ? ` (${token.remark})` : ''}`,
			confirmText: '重置',
			action: () => handleUpdate(id, 'resetToken')
		}
	}

	resetWithPassword.value = false
	confirmAction.value = {
		type,
		id,
		...configs[type]
	}
	showModal.value = true
}

async function handleCreate() {
	if (isLoading.value) return
	
	isLoading.value = true
	try {
		const res = await axios.post(getApiURL('subToken'))
		showToast('操作成功', res.data.message, 'success')
		emit('refresh')
	} catch (err) {
		console.error(err)
	} finally {
		isLoading.value = false
		showModal.value = false
	}
}

async function handleDelete(id) {
	if (isLoading.value) return
	
	isLoading.value = true
	try {
		const res = await axios.delete(`${getApiURL('subToken')}/${id}`)
		showToast('操作成功', res.data.message, 'success')
		emit('refresh')
	} catch (err) {
		console.error(err)
	} finally {
		isLoading.value = false
		showModal.value = false
	}
}

async function handleUpdate(id, type) {
	if (!['updateRemark', 'updateStatus', 'resetToken'].includes(type)) {
		showToast('操作失败', '操作类型错误', 'error')
		return
	}

	if (isLoading.value) return
	
	isLoading.value = true
	try {
		const res = await axios.put(`${getApiURL('subToken')}/${id}/${type}`, {
			remark: newRemark.value[id],
			enable: !props.tokens.find(token => token.id === id).enable,
			resetPassword: type === 'resetToken' ? resetWithPassword.value : undefined
		})
		showToast('操作成功', res.data.message, 'success')
		emit('refresh')
	} catch (err) {
		console.error(err)
	} finally {
		isLoading.value = false
		showModal.value = false
	}
}
</script>

<template>
	<div class="row">
		<!-- 标题栏 -->
		<div class="col-12 mb-4">
			<div class="d-flex justify-content-between align-items-center">
				<h6 class="mb-0 fw-bold">订阅令牌</h6>
				<button 
					class="btn btn-sm btn-primary mb-0"
					@click="showConfirm(null, 'create')"
					:disabled="isLoading || remaining <= 0"
				>
					<span v-if="isLoading" class="spinner-border spinner-border-sm me-1"></span>
					<i v-else class="fas fa-plus me-1"></i>
					新建令牌
				</button>
			</div>
		</div>

		<!-- 令牌列表 -->
		<div class="col-12">
			<div class="row g-3">
				<div class="col-xl-4 col-lg-6 col-12" v-for="token in tokens" :key="token.id">
					<div class="card border shadow-none mb-0" :class="token.enable ? 'border-success' : 'border-secondary'">
						<div class="card-body p-3">
							<div class="d-flex justify-content-between align-items-center mb-2">
								<div class="d-flex align-items-center gap-2">
									<span class="text-sm text-secondary">#{{ token.id }}</span>
									<span class="badge badge-sm" :class="token.enable ? 'bg-gradient-success' : 'bg-gradient-secondary'">
										{{ token.enable ? '正常' : '已停用' }}
									</span>
								</div>
								<div class="dropdown">
									<button 
										class="btn btn-icon-only btn-link text-secondary mb-0" 
										type="button" 
										data-bs-toggle="dropdown"
									>
										<i class="fas fa-ellipsis-v"></i>
									</button>
									<ul class="dropdown-menu dropdown-menu-end">
										<li>
											<button class="dropdown-item" @click="showConfirm(token.id, 'updateStatus')">
												<i class="fas fa-pause me-2"></i>
												{{ token.enable ? '停用' : '启用' }}
											</button>
										</li>
										<li>
											<button class="dropdown-item" @click="showConfirm(token.id, 'updateRemark')">
												<i class="fas fa-pencil-alt me-2"></i>
												修改备注
											</button>
										</li>
										<li>
											<button class="dropdown-item" @click="showConfirm(token.id, 'resetToken')">
												<i class="fas fa-sync me-2"></i>
												重置令牌
											</button>
										</li>
										<li>
											<button class="dropdown-item text-danger" @click="showConfirm(token.id, 'delete')">
												<i class="fas fa-trash me-2"></i>
												删除令牌
											</button>
										</li>
									</ul>
								</div>
							</div>

							<div class="form-group mb-2">
								<input 
									type="text" 
									class="form-control form-control-sm"
									:value="token.token"
									readonly
								>
							</div>

							<div class="d-flex align-items-center">
								<small class="text-xs text-secondary me-2">备注:</small>
								<span class="text-sm">{{ token.remark || '-' }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="tokens.length === 0" class="text-center py-4 text-secondary">
				暂无订阅令牌
			</div>
		</div>

		<!-- 确认操作模态框 -->
		<newModal
			v-model="showModal"
			:title="confirmAction.title"
			size="md"
		>
			<p class="mb-2">{{ confirmAction.message }}</p>
			
			<!-- 附加信息 -->
			<div v-if="confirmAction.subMessage" class="mb-2">
				<small class="text-secondary">{{ confirmAction.subMessage }}</small>
			</div>

			<!-- 备注修改表单 -->
			<div v-if="confirmAction.type === 'updateRemark'" class="mb-3">
				<div class="form-group">
					<input 
						type="text" 
						class="form-control form-control-sm"
						v-model="newRemark[confirmAction.id]"
						placeholder="请输入新备注"
					>
				</div>
			</div>

			<!-- 重置令牌选项 -->
			<div v-if="confirmAction.type === 'resetToken'" class="mb-3">
				<div class="form-check">
					<input 
						class="form-check-input" 
						type="checkbox"
						v-model="resetWithPassword"
						id="resetPasswordCheck"
					>
					<label class="form-check-label text-sm" for="resetPasswordCheck">
						同时重置连接密码
					</label>
				</div>
				<small class="text-secondary d-block mt-1">
					勾选此项将同时重置连接密码，可用于阻断已下发的配置文件
				</small>
			</div>

			<template #buttons>
				<button 
					class="btn btn-sm btn-warning me-2 mb-0"
					@click="confirmAction.action"
					:disabled="isLoading || (confirmAction.type === 'updateRemark' && !newRemark[confirmAction.id])"
				>
					<span v-if="isLoading" class="spinner-border spinner-border-sm me-1"></span>
					{{ confirmAction.confirmText }}
				</button>
			</template>
		</newModal>
	</div>
</template>

<style scoped>
/* 优化按钮样式 */
.btn-icon-only {
	padding: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0.5rem;
}

.btn-icon-only:hover {
	background-color: #f8f9fa;
}
</style> 