<template>
    <footer class="footer py-3 mt-auto" :class="{'d-none': hideFooter}">
        <div class="container-fluid">
            <div class="row align-items-center justify-content-lg-between">
                <div class="col-lg-6 mb-lg-0 mb-4">
                    <div class="copyright text-center text-sm text-muted text-lg-start">
                        © 2017- {{ year }}, made with <i class="ni ni-satisfied"></i> by
                        <a href="https://www.creative-tim.com" class="font-weight-bold" target="_blank">Creative Tim</a>
                        for a better web.
                    </div>
                </div>
                <div class="col-lg-6">
                    <ul class="nav nav-footer justify-content-center justify-content-lg-end">
                        <li class="nav-item">
                            <a href="https://www.creative-tim.com" class="nav-link text-muted" target="_blank">Creative Tim</a>
                        </li>
                        <li class="nav-item">
                            <a href="mailto://coolti_support@pm.me" class="nav-link text-muted" target="_blank">Oumig</a>
                        </li>
                        <li class="nav-item">
                            <a href="https://www.creative-tim.com/license" class="nav-link pe-0 text-muted" target="_blank">License</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>

    <!-- Plugins Area -->

    <div id="modalContainer"></div>

    <div aria-live="polite" aria-atomic="true" class="position-relative">
        <div class="toast-container position-fixed top-10 end-1" style="z-index: 10001;" id="toastContainer">
                
        </div>
    </div>
</template>

<script>
export default {
    name: 'cooltiFooter',
    props: {
        hideFooter: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            // this year
            year: new Date().getFullYear(),
        }
    },
}
</script>