<script setup>
    import axios from '@/Plugins/axios';
</script>

<template>
    <div class="card border-0 mb-0 authCard">
        <div class="card-header bg-transparent text-center">
            <h5 class="text-dark text-center mt-2 mb-3">重置密码</h5>
            <div class="text-center text-dark">
                <small class="fw-bold">您将在1-5分钟内收到一封带有重置链接的邮件</small>
            </div>
        </div>

        <div class="card-body px-lg-5 py-0">
            <div class="row" v-if="!token">
                <form role="form">    
                    <div class="mb-2">
                        <input type="email" class="form-control" placeholder="邮箱地址" aria-label="Email" autocomplete="off" v-model="email">
                    </div>

                    <div class="text-center">
                        <button type="button" class="btn bg-gradient-dark mb-0 mt-3 w-100" @click="sendResetURL">发送重置邮件</button>
                    </div>
                </form>
            </div>
            <div class="row" v-else>
                <form role="form">
                    <div class="row">
                        <div class="col-12 mb-3">
                            <input type="password" class="form-control" placeholder="新密码" aria-label="pwd" autocomplete="off" v-model="password">
                        </div>

                        <div class="col-12 mb-3">
                            <input type="password" class="form-control" placeholder="确认新密码" aria-label="repwd" autocomplete="off" v-model="repassword">
                        </div>

                        <div class="col-12 text-center">
                            <button type="button" class="btn bg-gradient-dark mb-0 mt-3 w-100" @click="resetPassword">确认重置</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="card-footer px-lg-5 pt-0">
            <p class="text-sm text-white mt-3 mb-0">记起来了？
                <a class="text-dark font-weight-bolder ps-2 custom-button cursor-pointer" @click="$emit('back', 'login')" v-if="!token">
                    返回登录
                </a>
                <router-link class="text-dark font-weight-bolder ps-2 custom-button cursor-pointer" :to="{name: '登录'}" @click="$emit('back', 'login')" v-else>
                    返回登录
                </router-link>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: '重置密码',
    props: {
        preToken: {
            type: String,
            default: '',
        },
    },
    emits: ['reset-success'],
    data() {
        return {
            email: '',
            password: '',
            repassword: '',

            token: this.$route.params.token,
        }
    },
    methods: {
        validEmail(val) {
            return String(val)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        },
        sendResetURL(trigger) {
            // 检查邮箱格式
            if (!this.validEmail(this.email)) {
                this.$showToast('操作失败', '邮箱格式不正确', 'error');
                return;
            }

            trigger.target.disabled = true;

            axios.post(this.$getApiURL('forgotPassword'), {
                email: this.email,
            })
            .then(res => {
                this.$showToast('操作成功', '重置邮件已发送', 'success');
            })
            .catch(err => { console.error(err); })
            .finally(() => {
                setTimeout(() => { trigger.target.disabled = false; }, 450);
            });
        },
        resetPassword(trigger) {
            // password and confirm password must be same
            if (this.password !== this.repassword) {
                this.$showToast('操作失败', '两次输入的密码不一致', 'error');
                return;
            }

            // password must be longer than 8 and shorter than 32
            if (this.password.length < 8 || this.password.length > 32) {
                this.$showToast('操作失败', '密码长度必须在 8 - 32 位之间', 'error');
                return;
            }

            trigger.target.disabled = true;

            axios.post(this.$getApiURL('resetPassword'), {
                email: this.$route.query.email,
                token: this.token,
                password: this.password,
                password_confirmation: this.repassword,
            }).then(res => {
                this.$showToast('操作成功', '重置密码成功', 'success');

                this.$emit('back', 'login');
            })
            .catch(err => { console.error(err); })
            .finally(() => {
                setTimeout(() => { trigger.target.disabled = false; }, 450);
            });
        }
    }
}
</script>