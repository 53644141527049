<script setup>
import { ref, nextTick, onMounted, computed }    from 'vue'
import { useStore }         from 'vuex'

import PasswordCard         from './Account/passwordCard.vue'
import ConnPassword         from './Account/connPasswordCard.vue'
import UserNameCard         from './Account/userNameCard.vue'
import TwoStepsVerifyCard   from './Account/twoStepsVerifyCard.vue'
import TgBindCard           from './Account/tgBindCard.vue'
import RemarkCard           from './Account/remarkCard.vue'
import SkeletonLoader       from '@/components/skeletonLoader.vue'

const store = useStore()

const activeTab = ref('username')
const isLoading = ref(false)
const isRemark  = computed(() => {
    return ['01', '02', '03', '04', '05'].includes(store.state.user?.remark);
})
const menuItems = computed(() => {
    let items = [
        {
            title: '用户名称',
            icon: 'ni ni-single-02',
            description: '修改您的用户名称',
            view: 'username'
        },
        {
            title: '两步验证',
            icon: 'ni ni-mobile-button',
            description: '管理您的两步验证设置',
            view: 'twoFactor'
        },
        {
            title: 'Telegram',
            icon: 'ni ni-send',
            description: '绑定您的 Telegram 账号',
            view: 'telegram'
        },
        {
            title: '登录密码',
            icon: 'ni ni-lock-circle-open',
            description: '修改您的账户登录密码',
            view: 'password'
        },
        {
            title: '连接密码',
            icon: 'ni ni-key-25',
            description: '重置您的连接密码',
            view: 'conn'
        }
    ]

    if (isRemark.value === true) {
        items.push({
            title: '批次特惠',
            icon: 'ni ni-tag',
            description: '创建优惠码供他人使用',
            view: 'remark'
        })
    }

    return items
})

onMounted(() => {
    if (!store.state.user) {
        store.dispatch('getUserData')
    }
})

const handleTabChange = async (tab) => {
    if (activeTab.value === tab) return

    isLoading.value = true
    activeTab.value = tab
    await nextTick()
    setTimeout(() => {
        isLoading.value = false
    }, 450)
}
</script>

<template>
    <div class="container-fluid py-4">
        <!-- 页面标题 -->
        <div class="row mb-4">
            <div class="col-12">
                <h3 class="mb-0">账户安全设置</h3>
            </div>
        </div>

        <div class="row">
            <!-- PC端左侧导航 -->
            <div class="col-lg-3 d-none d-lg-block">
                <div class="list-group">
                    <button v-for="item in menuItems"
                            :key="item.view"
                            class="list-group-item list-group-item-action d-flex align-items-center"
                            :class="{ active: activeTab === item.view }"
                            @click="handleTabChange(item.view)"
                            >
                        <div class="icon-box me-3">
                            <i :class="item.icon"></i>
                        </div>
                        <div class="flex-grow-1">
                            <h6 class="mb-1 fw-bold text-dark text-sm">{{ item.title }}</h6>
                            <p class="text-xs text-secondary mb-0">{{ item.description }}</p>
                        </div>
                        <div class="indicator" :class="{ 'indicator-active': activeTab === item.view }"></div>
                    </button>
                </div>
            </div>

            <!-- 移动端导航 -->
            <div class="col-12 d-lg-none">
                <div class="form-group mb-3">
                    <SlimSelect 
                        class="ss-account-menu" 
                        :modelValue="activeTab"
                        @update:modelValue="handleTabChange"
                        :settings="{showSearch: false}"
                    >
                        <option v-for="item in menuItems" :key="item.view" :value="item.view">
                            {{ item.title }}
                        </option>
                    </SlimSelect>
                </div>
            </div>

            <!-- 内容区域 -->
            <div class="col-12 col-lg-9">
                <div class="tab-content">
                    <!-- 骨架屏 -->
                    <SkeletonLoader height="400px" v-if="isLoading" />

                    <!-- 实际内容 -->
                    <div v-else>
                        <div v-if="activeTab === 'username'">
                            <UserNameCard />
                        </div>
                        <div v-if="activeTab === 'twoFactor'">
                            <TwoStepsVerifyCard />
                        </div>
                        <div v-if="activeTab === 'password'">
                            <PasswordCard />
                        </div>
                        <div v-if="activeTab === 'conn'">
                            <ConnPassword />
                        </div>
                        <div v-if="activeTab === 'telegram'">
                            <TgBindCard />
                        </div>
                        <div v-if="activeTab === 'remark'">
                            <RemarkCard />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
/* PC端样式 */
.list-group-item {
    border: none;
    padding: 0.875rem 1.25rem;
    margin-bottom: 0.5rem;
    border-radius: 0.75rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background-color: #fff;
    transition: all 0.2s ease;
    border: 1px solid transparent;
}

.list-group-item:hover {
    background-color: rgba(var(--bs-primary-rgb), 0.03);
    transform: translateX(3px);
}

.list-group-item.active {
    background-color: rgba(var(--bs-primary-rgb), 0.05);
    border-color: rgba(var(--bs-primary-rgb), 0.1);
}

.icon-box {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(var(--bs-primary-rgb), 0.08);
    transition: all 0.2s ease;
}

.active .icon-box {
    background-color: var(--bs-primary);
    box-shadow: 0 3px 6px rgba(var(--bs-primary-rgb), 0.2);
    transform: scale(1.05);
}

.icon-box i {
    font-size: 1.25rem;
    color: var(--bs-primary);
    transition: all 0.2s ease;
}

.active .icon-box i {
    color: #fff;
}

.indicator {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 0;
    background-color: var(--bs-primary);
    border-radius: 0 4px 4px 0;
    transition: all 0.3s ease;
    opacity: 0;
}

.indicator-active {
    height: 70%;
    opacity: 1;
}

.active p.text-muted {
    color: var(--bs-primary) !important;
    opacity: 0.8;
}
</style> 