export function useConsole() {
    const style = {
        info: 'color: #00a8ff',
        warn: 'color: #fbc531',
        error: 'color: #e84118',
        success: 'color: #4cd137',
    };

    const log = (title, msg) => {
        const message = `%c[${title}]%c ${msg}`;
        console.log(message, style['info'], 'color: #000');
    }

    const warn = (title, msg) => {
        const message = `%c[${title}]%c ${msg}`;
        console.warn(message, style['warn'], 'color: #000');
    }

    return {
        log,
        warn,
    }
}