<script setup>
    import { ref, onMounted } from 'vue'
    import { useUserApi } from '@/composables/useUserApi'

    // Props 定义
    const props = defineProps({
        type: {
            type: String,
            default: '0'
        },
        maxHeight: {
            type: [String, Number],
            default: '528'
        }
    })

    // 响应式状态
    const messageHTML = ref('Loading...')
    const hasOverflow = ref(false)
    const cardBody    = ref(null)

    // 检查内容溢出方法
    const checkOverflow = () => {
        if (cardBody.value) {
            hasOverflow.value = cardBody.value.scrollHeight > cardBody.value.clientHeight
        }
    }

    // 生命周期钩子
    onMounted(async () => {
        try {
            const res = await useUserApi().getAnn(props.type)
            messageHTML.value = res.data.content
            // 等待内容渲染后检查溢出
            setTimeout(checkOverflow, 100)
        } catch (err) {
            messageHTML.value = '获取公告失败，请尝试刷新页面'
            console.error('获取公告失败:', err)
        }
    })
</script>

<template>
    <div class="card ann-card h-100" :style="`max-height: ${maxHeight}px`">
        <!-- 头部区域 -->
        <div class="card-header d-flex justify-content-between ann-header pb-2">
            <h5 class="mb-0 text-capitalize">页面公告</h5>
            
            <div v-if="hasOverflow" class="scroll-indicator">
                <span class="badge bg-light text-secondary">
                    <i class="fas fa-arrows-alt-v me-1"></i>可滑动
                </span>
            </div>
        </div>

        <!-- 内容区域 -->
        <div 
            ref="cardBody"
            class="card-body ann-content"
            :style="`max-height: calc(${maxHeight}px - ${type === '0' ? 120 : 70}px)`"
        >
            <div v-if="messageHTML === 'Loading...'" class="d-flex justify-content-center py-4">
                <div class="spinner-border spinner-border-sm text-primary" role="status">
                    <span class="visually-hidden">加载中...</span>
                </div>
            </div>
            
            <div v-else-if="messageHTML.includes('获取公告失败')" class="text-center py-4 text-danger">
                <i class="fas fa-exclamation-circle mb-2"></i>
                <p class="mb-0">{{ messageHTML }}</p>
            </div>
            
            <div v-else class="ann" v-html="messageHTML"></div>
        </div>

        <!-- 底部按钮区域 -->
        <div v-if="type === '0'" class="card-footer py-2">
            <div class="d-flex justify-content-center gap-2">
                <a 
                    href="https://t.me/cooltiGroup" 
                    target="_blank"
                    class="btn btn-xs btn-outline-primary mb-0"
                >
                    <i class="fab fa-telegram me-1"></i>
                    加入TG群组
                </a>
                <a 
                    href="https://t.me/Oumig_bot" 
                    target="_blank"
                    class="btn btn-xs btn-outline-info mb-0"
                >
                    <i class="fab fa-telegram-plane me-1"></i>
                    加入TG频道
                </a>
            </div>
        </div>
    </div>
</template>

<style scoped>
/* 添加头部样式 */
.ann-header {
    border-bottom: 1px solid rgba(0,0,0,0.05);
    margin-bottom: 0;
}

.ann-card {
    transition: all 0.3s ease;
}

.ann-content {
    overflow-y: auto;
    padding: 1rem 1.5rem; /* 调整内容区域内边距 */
    scrollbar-width: thin;
    scrollbar-color: rgba(0,0,0,0.2) transparent;
}

.ann-content::-webkit-scrollbar {
    width: 4px;
}

.ann-content::-webkit-scrollbar-track {
    background: transparent;
}

.ann-content::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0.2);
    border-radius: 2px;
}

.scroll-indicator {
    font-size: 0.75rem;
    opacity: 0.8;
}

/* 保持原有的段落样式 */
.ann :deep(p) {
    margin-bottom: 4px;
    line-height: 1.5;
}

/* 为链接添加样式 */
.ann :deep(a) {
    color: var(--bs-primary);
    text-decoration: none;
    transition: color 0.2s ease;
}

.ann :deep(a:hover) {
    color: var(--bs-primary-darker);
    text-decoration: underline;
}
</style>