<script setup>
    import { ref, onMounted, onUnmounted } from 'vue';
    import { useRouter }    from 'vue-router';
    import { useStore }     from 'vuex';

    import NewModal from '@/components/newModal.vue';
    import QrcodeVue from 'qrcode.vue';
    import SkeletonLoader from '@/components/skeletonLoader.vue';

    import axios from '@/Plugins/axios';

    import { useCache }      from '@/composables/useCache';
    import { useApiURL }     from '@/composables/useApiURL';
    import { useShowToast }  from '@/composables/useShowToast';

    const { deleteCache } = useCache();
    const { getApiURL }   = useApiURL();
    const { showToast }   = useShowToast();
    const router          = useRouter();
    const store           = useStore();

    const currentView   = ref('loading');
    const activePayment = ref(null);
    const payData       = ref(null);
    const order         = ref(null);
    const paymentItems  = ref(null);
    const errorMessage  = ref(null);
    const isModalVisible = ref(false);
    const isDetailsVisible = ref(false);

    onMounted(() => {
        getOrder();
    });

    const setPayments = (datas) => {
        console.log(datas);
        const fullPayment = [
            { text: '请选择支付通道', value: null },
        ];
        const methodsAlias  = { Alipay: '支付宝', Wechat: '微信', stripe: 'Stripe', paypal: 'PayPal', balance: '余额', };
        
        for (const key in datas) {
            const item = datas[key];
            
            if (item.sdk_type === 'epay') {
                item.types.forEach(type => {
                    fullPayment.push({
                        text: `${item.remark} - ${methodsAlias[type]}`,
                        value: `${item.name}.${type}`,
                    });
                });
            } else {
                fullPayment.push({
                    text: item.remark,
                    value: item.name,
                });
            }
        }

        paymentItems.value = fullPayment;
    }

    const getOrder = async () => {
        currentView.value = 'loading';

        let url = getApiURL('order');
            url += `/${router.currentRoute.value.params.id}`;

        try {
            const res = await axios.get(url);
            const ORDER = res.data.order;

            if (!ORDER) {
                showToast('订单不存在', '请检查订单是否存在', 'error');
                return;
            }

            order.value = ORDER;

            if (ORDER.status === 0) {
                setPayments(res.data.payments);
            } else if (ORDER.status === 1) {
                deleteCache('shop');
                deleteCache('subscribe');
                store.dispatch('refreshUserData');
            } else if (ORDER.status === 3) {
                setTimeout(() => { getOrder() }, 3000);
            }

            currentView.value = 'detail';

            return ORDER;
        } catch (err) {
            console.error(err);
            currentView.value = 'error';
        }
    }

    const payOrderInvoice = async () => {
        let url = getApiURL('order');
            url += `/${router.currentRoute.value.params.id}/pay`;

        try {
            currentView.value = 'loading';

            const channel = activePayment.value.includes('.') ? activePayment.value.split('.')[0] : activePayment.value;
            const via     = activePayment.value.includes('.') ? activePayment.value.split('.')[1] : channel;

            const res = await axios.post(url, {
                channel: channel,
                via: via
            });

            payData.value = {
                type: res.data.type,
                data: res.data.data,
            }
            currentView.value = 'payment';

            if (res.data.type === 'url') {
                setTimeout(() => {
                    window.open(res.data.data, '_blank');
                }, 1200);
            }
        } catch (err) {
            console.error(err);
            errorMessage.value = err.response?.data?.message ?? '异常错误，建议汇报管理员';
            currentView.value = 'error';
        }
    }

    const checkOrderStatus = async () => {
        getOrder()
        .then(order => {
            if (order.status === 1) {
                showToast('状态检查', '订单已完成，正在刷新数据', 'success');
                // * Clear Cache
                deleteCache('shop');
                deleteCache('node');
                deleteCache('subscribe');

                store.dispatch('refreshUserData');
            } else {
                showToast('状态检查', '订单未完成，请稍后再试', 'error');
            }
        })
        .catch(err => {
            console.error(err);
        });
    }

    const cancel = async () => {
        let url = getApiURL('order');
        url += `/${router.currentRoute.value.params.id}`;

        try {
            const res = await axios.delete(url);

            store.dispatch('refreshUserData');

            showToast('订单取消', '订单已取消，正在返回套餐中心', 'success');
            setTimeout(() => { router.push({ name: '套餐中心' }); }, 1200);
        } catch (err) {
            console.error(err);
        }
    }

    const back = () => {
        currentView.value = 'detail';
        payData.value = null;
    }
</script>

<template>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-xl-6 col-lg-8 col-12 mx-auto" v-if="currentView === 'loading'">
                <SkeletonLoader 
                    type="card"
                    :height="600"
                />
            </div>

            <div class="col-xl-6 col-lg-8 col-12 mx-auto" v-else-if="currentView === 'detail'">
                <div class="card shadow-sm">
                    <div class="card-header py-3">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex align-items-center">
                                <i class="fas fa-cart-shopping text-primary me-3"></i>
                                <div>
                                    <h6 class="mb-0">订单详情</h6>
                                    <p class="text-sm text-secondary fw-semibold mb-0">订单号：#{{ order?.id }}</p>
                                </div>
                            </div>

                            <div class="text-center">
                                <i class="fas me-2" :class="[
                                    {'fa-pause text-secondary': order.status === 0},
                                    {'fa-check text-success': order.status === 1},
                                    {'fa-times text-danger': order.status === 2}
                                ]"></i>
                                <span :class="['text-secondary', 'text-success', 'text-danger'][order.status]">
                                    {{ { '0': '等待处理', '1': '已完成', '2': '已取消' }[order.status] }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="card-body pt-2">
                        <!-- 支付通道选择 -->
                        <div class="row g-3 mb-4" v-if="order.status === 0">
                            <div class="col-md-8">
                                <label class="text-sm text-secondary mb-1">选择支付通道</label>
                                <SlimSelect 
                                    :settings="{ showSearch: false }" 
                                    v-model="activePayment" 
                                    :data="paymentItems"
                                />
                            </div>
                            <div class="col-md-4 d-flex align-items-end">
                                <button 
                                    class="btn btn-sm w-100 mb-0" 
                                    :class="activePayment ? 'btn-primary' : 'btn-outline-dark'"
                                    :disabled="!activePayment"
                                    @click="payOrderInvoice"
                                >
                                    {{ activePayment ? '点击支付' : '请选择支付通道' }}
                                </button>
                            </div>
                        </div>

                        <!-- 商品信息表格 -->
                        <div class="table-responsive mb-4">
                            <table class="table">
                                <thead>
                                    <tr class="text-uppercase text-secondary text-xxs fw-bold">
                                        <th>商品信息</th>
                                        <th class="ps-2">项目价格</th>
                                        <th class="ps-2">优惠金额</th>
                                        <th class="ps-2">余额抵扣</th>
                                        <th class="ps-2">需要支付</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="d-flex align-items-center justify-content-between">
                                                <h6 class="text-sm mb-0">{{ order.contentName }}</h6>
                                                <button 
                                                    class="btn btn-link text-secondary p-0 mb-0" 
                                                    type="button"
                                                    @click="isDetailsVisible = !isDetailsVisible"
                                                    v-if="order.type === 1"
                                                >
                                                    <i class="fas" :class="isDetailsVisible ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
                                                </button>
                                            </div>
                                            <div 
                                                v-if="order.type === 1" 
                                                class="text-xs text-secondary border-top mt-2"
                                                v-show="isDetailsVisible"
                                            >
                                                <p class="text-xs mb-1">等级：Lv.{{ order.shop.level }}</p>
                                                <p class="text-xs mb-1">流量：{{ order.content.traffic }} G</p>
                                                <p class="text-xs mb-1">周期：{{ order.shop.duration }} 天</p>
                                                <p class="text-xs mb-0">重置：每月一号</p>
                                            </div>
                                        </td>
                                        <td class="fw-bold">{{ order.total_amount }} CNY</td>
                                        <td class="fw-bold">{{ order.discount_amount }} CNY</td>
                                        <td class="fw-bold">{{ order.balance_amount }} CNY</td>
                                        <td class="fw-bold text-dark">{{ order.handle_amount }} CNY</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- 提示信息 -->
                        <div class="row">
                            <div class="col-md-6 col-12 mb-md-0 mb-3">
                                <div class="border border-secondary rounded p-3 bg-white">
                                    <div class="d-flex align-items-center mb-2">
                                        <i class="fas fa-bell text-primary me-2"></i>
                                        <h6 class="mb-0 text-dark">温馨提示</h6>
                                    </div>
                                    <p class="text-sm text-secondary mb-1 fw-normal">1. 过期或取消后，将退回扣押余额</p>
                                    <p class="text-sm text-secondary mb-1 fw-normal">2. 无法支付请尝试更换支付通道</p>
                                    <p class="text-sm text-secondary mb-0 fw-normal">3. 支付完成后，将在三分钟内完成处理</p>
                                </div>
                            </div>
                            <div class="col-md-6 col-12 d-flex flex-column align-items-center justify-content-center">
                                <h6 class="text-dark mb-1 fw-bold">支付完成但未到账？</h6>
                                <p class="text-secondary text-sm mb-2 fw-normal">点击下方按钮重复检查账单状态</p>
                                <div class="d-flex gap-2">
                                    <button class="btn btn-outline-primary btn-sm" @click="checkOrderStatus">
                                        检查订单
                                    </button>
                                    <button class="btn btn-outline-danger btn-sm" @click="isModalVisible = true">
                                        取消订单
                                    </button>
                                </div>
                            </div>
                        </div>

                        <hr class="horizontal dark my-4">

                        <!-- 时间信息 -->
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <div class="d-flex align-items-center">
                                            <i class="fas fa-calendar-days text-primary me-2"></i>
                                            <label class="text-sm text-secondary mb-0">创建时间</label>
                                        </div>
                                        <p class="mb-0 text-dark fw-semibold ps-4 mt-1">{{ order.created_at }}</p>
                                    </div>
                                    
                                    <div class="col-md-6 col-12" v-if="order.status === 0">
                                        <div class="d-flex align-items-center">
                                            <i class="fas fa-clock text-warning me-2"></i>
                                            <label class="text-sm text-secondary mb-0">逾期时间</label>
                                        </div>
                                        <p class="mb-0 text-dark fw-semibold ps-4 mt-1">{{ order.expired_at ?? '未设定' }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-footer bg-light py-2">
                        <div class="d-flex justify-content-between align-items-center">
                            <p class="text-secondary text-sm fw-semibold mb-0">如果您有任何问题，请通过以下方式联系我们。</p>
                            <div class="">
                                <a class="btn btn-xs bg-gradient-info mb-0 me-2" href="mailto:coolti_support@pm.me" target="_blanket">电子邮件</a>
                                <a class="btn btn-xs bg-gradient-success mb-0" href="https://t.me/cooltiGroup" target="_blanket">Telegram</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-6 col-lg-8 col-12 mx-auto" v-else-if="currentView === 'payment'">
                <div v-if="!payData">
                    <SkeletonLoader 
                        type="card"
                        :height="400"
                    />
                </div>
                
                <div class="card shadow-sm" v-else>
                    <div class="card-header py-3">
                        <div class="row align-items-center">
                            <div class="col">
                                <div class="d-flex align-items-center">
                                    <div class="me-3">
                                        <i class="fas fa-credit-card text-lg text-primary"></i>
                                    </div>
                                    <div>
                                        <h6 class="mb-0">订单支付</h6>
                                        <p class="text-sm mb-0 text-secondary fw-semibold">
                                            订单号：#{{ order?.id }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto">
                                <button class="btn btn-sm btn-outline-secondary mb-0" @click="back">
                                    <i class="fas fa-arrow-left"></i>
                                    返回订单
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div v-if="payData.type === 'url'" class="text-center py-4">
                            <h5 class="mb-3">
                                <i class="fas fa-check-circle text-success text-lg"></i>
                                支付链接已生成
                            </h5>
                            <div class="row justify-content-center">
                                <div class="col-lg-8">
                                    <div class="alert alert-info text-white text-sm mb-4">
                                        <div class="d-flex">
                                            <i class="fas fa-bell pt-1"></i>
                                            <div class="ms-2">
                                                <span class="fw-bold">温馨提示：</span>
                                                {{ payData.data.includes('wxp://') ? '请在微信中打开支付链接' : '即将跳转至支付页面' }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card card-plain border">
                                        <div class="card-body">
                                            <p class="text-sm mb-3">如果没有跳转，请点击下方链接</p>
                                            <a 
                                                :href="payData.data" 
                                                target="_blank"
                                                class="text-primary text-decoration-underline"
                                            >
                                                {{ payData.data }}
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12" v-if="activePayment.includes('Wechat')">
                                    <div class="card card-plain border mt-4">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start mb-3">
                                                <div class="me-3">
                                                    <i class="fas fa-mobile-button text-primary text-lg"></i>
                                                </div>
                                                <div>
                                                    <h6 class="text-dark mb-1">无法支付？</h6>
                                                    <p class="text-sm text-secondary mb-0">
                                                        请按以下步骤操作：
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="steps ps-4">
                                                <div class="step mb-2">
                                                    <div class="d-flex align-items-center mb-1">
                                                        <span class="badge badge-sm bg-primary me-2">1</span>
                                                        <p class="text-sm mb-0">长按复制以下链接</p>
                                                    </div>
                                                    <div class="bg-gray-100 p-2 rounded ms-4">
                                                        <a 
                                                            :href="payData.data"
                                                            target="_blank" 
                                                            class="text-primary text-decoration-underline text-sm"
                                                        >
                                                            {{ payData.data }}
                                                        </a>
                                                    </div>
                                                </div>

                                                <div class="step mb-2">
                                                    <div class="d-flex align-items-center">
                                                        <span class="badge badge-sm bg-primary me-2">2</span>
                                                        <p class="text-sm mb-0">打开手机微信，发送给任意好友或文件传输助手</p>
                                                    </div>
                                                </div>

                                                <div class="step">
                                                    <div class="d-flex align-items-center">
                                                        <span class="badge badge-sm bg-primary me-2">3</span>
                                                        <p class="text-sm mb-0">点击打开发送的链接，即可跳转支付</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-else-if="payData.type === 'qrcode'" class="text-center py-4">
                            <div class="row justify-content-center">
                                <div class="col-lg-8">
                                    <div class="card card-plain border mb-4">
                                        <div class="card-body p-3">
                                            <qrcode-vue
                                                :value="payData.data"
                                                :size="256"
                                                level="L"
                                                render-as="svg"
                                                class="mb-3"
                                            />
                                            <p class="text-sm mb-0">
                                                <i class="fas fa-mobile-button"></i>
                                                <span class="ms-1">请使用对应软件扫码支付</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12" v-if="activePayment.includes('Wechat')">
                                    <div class="card card-plain border">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start mb-3">
                                                <div class="me-3">
                                                    <i class="fas fa-mobile-button text-primary text-lg"></i>
                                                </div>
                                                <div>
                                                    <h6 class="text-dark mb-1">扫码无法支付？</h6>
                                                    <p class="text-sm text-secondary mb-0">
                                                        请按以下步骤操作：
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="steps ps-4">
                                                <div class="step mb-2">
                                                    <div class="d-flex align-items-center mb-1">
                                                        <span class="badge badge-sm bg-primary me-2">1</span>
                                                        <p class="text-sm mb-0">长按复制以下链接</p>
                                                    </div>
                                                    <div class="bg-gray-100 p-2 rounded ms-4">
                                                        <a 
                                                            :href="payData.data"
                                                            target="_blank" 
                                                            class="text-primary text-decoration-underline text-sm"
                                                        >
                                                            {{ payData.data }}
                                                        </a>
                                                    </div>
                                                </div>

                                                <div class="step mb-2">
                                                    <div class="d-flex align-items-center">
                                                        <span class="badge badge-sm bg-primary me-2">2</span>
                                                        <p class="text-sm mb-0">打开手机微信，发送给任意好友或文件传输助手</p>
                                                    </div>
                                                </div>

                                                <div class="step">
                                                    <div class="d-flex align-items-center">
                                                        <span class="badge badge-sm bg-primary me-2">3</span>
                                                        <p class="text-sm mb-0">点击打开发送的链接，即可跳转支付</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-footer bg-light py-3">
                        <div class="d-flex align-items-center">
                            <i class="fas fa-clock text-primary"></i>
                            <span class="text-sm ms-2">支付完成后将自动处理，请耐心等待</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-6 col-lg-8 col-12 mx-auto" v-else-if="currentView === 'error'">
                <div class="card">
                    <div class="card-body">
                        <h5 class="text-dark fw-bolder text-center mt-2">
                            <i class="fas fa-exclamation-triangle text-danger"></i>
                            支付请求失败，请稍后再试或更换支付通道
                        </h5>

                        <p class="fs-6 text-bolder text-dark text-center mb-0">
                            {{ errorMessage }}
                        </p>

                        <button class="btn btn-outline-dark w-100 mt-5 mb-0" @click="currentView = 'detail'">
                            <i class="fas fa-arrow-left me-1"></i>
                            返回订单
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <NewModal v-model="isModalVisible" title="订单取消" size="md">
            <div class="p-3">
                <p class="mb-0">请确认是否取消订单 #{{ order?.id }}？</p>
            </div>
            
            <template #footer>
                <button class="btn btn-sm btn-outline-secondary me-2" @click="isModalVisible = false">
                    返回
                </button>
                <button class="btn btn-sm btn-primary" @click="cancel">
                    确认取消
                </button>
            </template>
        </NewModal>
    </div>
</template>