import axios from "axios";
import { useCache } from "./useCache";
import { useApiURL } from "./useApiURL";

const { getCache, storeCache } = useCache();
const { getApiURL } = useApiURL();

export function useThirdPartyReq() {
    const getYiYan = async () => {
        const cacheData = getCache('yiyan');
        if (cacheData) return cacheData;

        return await axios.post('https://v1.hitokoto.cn', null,
            {
                withCredentials: false,
                headers: {
                    'Authorization': undefined
                }
            })
            .then(res => {
                storeCache('yiyan', res.data, 24 * 60 * 60, 'local');

                return res.data;
            })
            .catch(err => { throw new Error(err); })
    }

    const getBingImage = async () => {
        const getBase64FromUrl = async (reqUrl) => {
            const data = await axios.get(reqUrl, { responseType: 'blob', withCredentials: false });
            const blob = new Blob([data.data], { type: 'image/jpeg' });
    
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        }

        const cacheData = getCache('bingImage');
        if (cacheData) return cacheData;

        return await axios.get(
            getApiURL('getBingImage'),
            { withCredentials: false }
        ).then(res => {
            const url = `https://www.bing.com${res.data.images[0].url}`;
            const result = getBase64FromUrl(url).then(base64 => {
                const result = { data: res.data, base64: base64 }
    
                storeCache('bingImage', result, 24 * 60 * 60, 'local');
    
                return result;
            })
    
            return result;
        })
        .catch(err => { console.error(err) })
    }

    return {
        getYiYan,
        getBingImage,
    }
}