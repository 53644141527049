<script setup>
import { ref, computed }          from 'vue'

import axios            from '@/Plugins/axios'

import { useStore }     from 'vuex'
import { useShowToast } from '@/composables/useShowToast'
import { useCache }     from '@/composables/useCache'
import { useApiURL }    from '@/composables/useApiURL'

const store         = useStore()
const { showToast } = useShowToast()
const { getApiURL } = useApiURL()

const localConnPasswd = computed(() => store.state.user?.connPass || 'waiting...')
const showDetail      = ref(false)
const isLoading       = ref(false)

const resetConnPasswd = async () => {
    try {
        isLoading.value = true

        const res = await axios.put(getApiURL('resetConnPass'))

        showToast('操作成功', '连接密码已重置，节点同步可能存在1-30分钟延迟', 'success')
        
        store.dispatch('refreshUserData')

        showDetail.value = false

        useCache().deleteCache('node')
    } catch (err) {
        console.error(err)
    } finally {
        isLoading.value = false
        showDetail.value = false
    }
}
</script>

<template>
    <div class="card">
        <div class="card-header border-bottom p-3 px-4 text-dark">
            <div class="d-flex align-items-center">
                <i class="ni ni-key-25 me-2"></i>
                <span>连接密码</span>
            </div>
        </div>

        <div class="card-body">
            <div v-if="!showDetail">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="d-block text-sm">当前连接密码</label>
                            <input type="text" class="form-control" :value="localConnPasswd" readonly />
                        </div>
                    </div>
                </div>

                <div class="mt-md-3 pt-md-3 mt-2 pt-2 border-top">
                    <h6 class="text-sm fw-bold text-dark" style="margin-bottom: 0.75rem;">相关提示</h6>
                    <ul class="tips-list">
                        <li>重置连接密码需同时重置订阅密钥方可阻断下发</li>
                        <li>重置连接密码将导致当前所有节点配置失效</li>
                    </ul>
                </div>

                <button class="btn btn-primary w-100 mt-4" @click="showDetail = true">
                    点击重置
                </button>
            </div>

            <div v-else>
                <p class="text-sm fw-semi-bold text-dark mb-0">
                    重置连接密码将导致当前所有节点配置失效，是否继续？
                </p>
                <div class="d-flex gap-3 mt-4">
                    <button class="btn btn-secondary flex-grow-1" type="button" @click="showDetail = false">
                        取消
                    </button>
                    <button class="btn btn-danger flex-grow-1" type="button" @click="resetConnPasswd" :disabled="isLoading">
                        <span v-if="isLoading" class="spinner-border spinner-border-sm me-2"></span>
                        确认重置
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
i {
    font-size: 1.125rem;
    color: var(--bs-primary);
}

.tips-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.tips-list li {
    position: relative;
    padding-left: 1.25rem;
    font-size: 0.8125rem;
    color: #67748e;
    margin-bottom: 0.5rem;
}

.tips-list li:last-child {
    margin-bottom: 0;
}

.tips-list li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0.5rem;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #67748e;
}
</style> 