<script setup>
    import axios from '@/Plugins/axios'
</script>

<template>
    <div class="card h-100">
        <div class="card-header pb-2">
            <div class="d-flex justify-content-between">
                <h5 class="mb-0 text-capitalize">余额储值</h5>
                <button class="btn btn-sm btn-primary mb-0" @click="recharge">储值</button>
            </div>
        </div>

        <div class="card-body border-0 mt-3 pt-0">
            <p class="fw-bold text-dark ms-3">
                <i class="ni ni-bag-17"></i>
                储值后可用于购买套餐或增值项目
            </p>

            <div class="form-group">
                <label>储值金额</label>
                <div class="input-group mb-3">
                    <input type="number" min="0.01" max="1000" class="form-control ps-3" placeholder="在此输入储值金额" v-model="rechargeVal">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '储值',
    data() {
        return {
            rechargeVal: null,
        }
    },
    methods: {
        recharge(trigger) {
            trigger.target.disabled = true;

            if (isNaN(this.rechargeVal) || this.rechargeVal <= 0) {
                this.$showToast('金额错误', '请输入正确的金额', 'error');
                trigger.target.disabled = false;
                return;
            }

            axios.post(this.$getApiURL('recharge'),{
                amount: this.rechargeVal
            })
            .then(res => {
                // message = '储值订单创建成功，正在跳转到订单页面......';
                const message = res.data.message + '，正在跳转到订单页面......';

                this.$showToast('操作成功', message, 'success');
                this.$router.push({ name: '订单页', params: { id: res.data.data?.id, } });
            })
            .catch(err => { console.error(err); })
            .finally(() => {
                setTimeout(() => { trigger.target.disabled = false; }, 1200);
            })
        },
    },
}
</script>