<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore }      from 'vuex'
import { useShowToast }  from '@/composables/useShowToast'
import { useApiURL }     from '@/composables/useApiURL'
import axios             from '@/Plugins/axios'
import QrcodeVue         from 'qrcode.vue'

const store         = useStore()
const { showToast } = useShowToast()
const { getApiURL } = useApiURL()

const showDetail = ref(false)
const isLoading  = ref(false)
const isEnabled  = ref(false)
const totpCode   = ref('')
const password   = ref('')
const secret     = ref('')
const totpQrcode = computed(() => {
    if (!secret.value) return ''
    return `otpauth://totp/${store.state.user?.email}?secret=${secret.value}&issuer=CoolTi`
})

onMounted(() => {
    if (!store.state.user) {
        store.dispatch('getUserData')
    }

    get2FA()
})

const get2FA = async () => {
    const res = await axios.get(getApiURL('2FA'))

    isEnabled.value = res.data.enabled
    if (!isEnabled.value) {
        secret.value = res.data.secret
    }
}

const toggleTwoFactor = async () => {
    // 验证输入
    if (isEnabled.value && !totpCode.value) {
        showToast('请输入验证码', 'warning')
        return
    }
    if (!isEnabled.value && !password.value) {
        showToast('请输入密码', 'warning')
        return
    }

    isLoading.value = true
    try {
        const res = await axios.put(getApiURL('2FA'), { 
            enable: !isEnabled.value,
            totpCode: totpCode.value,
            password: password.value
        })
        
        showToast('操作成功', `两步验证已${isEnabled.value ? '关闭' : '开启'}`, 'success')
        store.dispatch('refreshUserData')

        isEnabled.value = res.data.enabled

        showDetail.value = false
        totpCode.value = ''
        password.value  = ''

        get2FA()
    } catch (err) {
        console.error(err)
    } finally {
        isLoading.value = false
    }
}
</script>

<template>
    <div class="card">
        <div class="card-header border-bottom p-3 px-4 text-dark">
            <div class="d-flex align-items-center">
                <i class="ni ni-mobile-button me-2"></i>
                <span>两步验证</span>
            </div>
        </div>

        <div class="card-body">
            <div v-if="!showDetail">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <div class="form-check form-switch">
                                <input class="form-check-input opacity-10" type="checkbox" :checked="isEnabled" disabled>
                                <label class="form-check-label fw-bold opacity-10">当前{{ isEnabled ? '已开启' : '未开启' }}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-md-3 pt-md-3 mt-2 pt-2 border-top">
                    <h6 class="text-sm fw-bold text-dark" style="margin-bottom: 0.75rem;">相关提示</h6>
                    <ul class="tips-list">
                        <li>开启两步验证后，登录时需要输入动态验证码</li>
                        <li>请妥善保管您的验证器，避免无法登录</li>
                    </ul>
                </div>

                <button class="btn btn-primary w-100 mt-4" @click="showDetail = true">
                    {{ isEnabled ? '关闭两步验证' : '开启两步验证' }}
                </button>
            </div>

            <div v-else>
                <div class="row">
                    <div class="col-12" v-if="!isEnabled">
                        <h5 class="text-dark mb-4">使用身份验证软件启用两步验证</h5>
                        <div class="steps mb-4">
                            <p class="text-sm mb-2">1. 安装两步验证软件</p>
                            <p class="text-sm mb-2">2. 扫描二维码</p>
                            <p class="text-sm mb-2">3. 下方输入两步验证应用程序生成的代码</p>
                        </div>

                        <div class="qr-section mb-4">
                            <div class="qr-box border rounded p-3 text-center">
                                <!-- <vueQr 
                                    :text="totpQrcode" 
                                    :size="200"
                                    :margin="10"
                                    class="mb-3 mx-auto d-block"
                                /> -->
                                <qrcode-vue
                                    class="mb-3 mx-auto d-block"
                                    :value="totpQrcode"
                                    :size="200"
                                    :margin="10"
                                    level="L"
                                    :render-as="'svg'"
                                />
                                <div class="secret-key">
                                    <p class="text-sm mb-2">设置密钥是 
                                        <span class="fw-bold text-dark">{{ secret }}</span>
                                    </p>
                                    <p class="text-sm mb-0">请记得将密钥保存在安全的地方！</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <form>
                            <div class="form-group mt-4">
                                <label class="form-label text-sm">
                                    TOTP验证码
                                </label>
                                <input 
                                    class="form-control"
                                    type="text"
                                    maxlength="6"
                                    autocomplete="one-time-code"
                                    placeholder="请输入验证码"
                                    v-model="totpCode"
                                >
                            </div>
                        </form>
                    </div>

                    <div class="col-md-6">
                        <form>
                            <div class="form-group mt-4">
                                <label class="form-label text-sm">
                                    账户密码
                                </label>
                                <input 
                                    class="form-control"
                                    type="password"
                                    autocomplete="current-password"
                                    placeholder="请输入账户密码"
                                    v-model="password"
                                >
                            </div>
                        </form>
                    </div>
                </div>

                <div class="d-flex gap-3 mt-4">
                    <button class="btn btn-secondary flex-grow-1" @click="showDetail = false" :disabled="isLoading">
                        取消
                    </button>
                    <button class="btn btn-danger flex-grow-1" @click="toggleTwoFactor" :disabled="isLoading">
                        <span v-if="isLoading" class="spinner-border spinner-border-sm me-2"></span>
                        {{ isEnabled ? '关闭两步验证' : '开启两步验证' }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
i {
    font-size: 1.125rem;
    color: var(--bs-primary);
}

.tips-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.tips-list li {
    position: relative;
    padding-left: 1.25rem;
    font-size: 0.8125rem;
    color: #67748e;
    margin-bottom: 0.5rem;
}

.tips-list li:last-child {
    margin-bottom: 0;
}

.tips-list li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0.5rem;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #67748e;
}

.qr-box {
    max-width: 400px;
    margin: 0 auto;
}
</style> 