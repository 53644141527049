import { useUserApi } from "./useUserApi";
import { useConsole } from "./useConsole";

const { log, warn } = useConsole();

export function useCache() {
    // * 缓存管理键 - 键名
    const cacheKey = 'cacheData';
    const drivers = { session: sessionStorage, local: localStorage };
    const refreshAbleKey = ['user', 'node', 'shop', 'tickets'];

    /**
     * * 验证缓存键名
     * @param {String} key 缓存键名
     * @returns {Boolean} 是否有效
     */
    const validateKey = (key) => {
        if (!key || typeof key !== 'string') {
            warn('Cache', '无效的缓存键名');
            return false;
        }
        return true;
    };

    /**
     * * 验证存储位置
     * @param {String} storage 存储位置
     * @returns {Boolean} 是否有效
     */
    const validateStorage = (storage) => {
        if (!drivers[storage]) {
            warn('Cache', `无效的存储位置: ${storage}`);
            return false;
        }
        return true;
    };

    /**
     * * 获取缓存管理数据
     * @returns {Object} 缓存管理数据
     */
    const getCacheData = () => {
        try {
            return JSON.parse(localStorage.getItem(cacheKey) || '{}');
        } catch (e) {
            warn('Cache', `解析缓存管理数据失败: ${e.message}`);
            return {};
        }
    };

    /**
     * * 更新缓存管理数据
     * @param {Object} data 新的缓存管理数据
     */
    const updateCacheData = (data) => {
        try {
            localStorage.setItem(cacheKey, JSON.stringify(data));
            return true;
        } catch (e) {
            warn('Cache', `更新缓存管理数据失败: ${e.message}`);
            return false;
        }
    };

    /**
     * * 获取缓存
     * @param {String} key 缓存键名
     * @returns {Object|null} 缓存数据
     */
    const getCache = (key) => {
        try {
            if (!validateKey(key)) return null;

            const cacheData = getCacheData();
            if (!cacheData[key]) return null;

            const { expires, storage } = cacheData[key];
            if (!validateStorage(storage)) return null;

            if (Date.now() > expires) {
                deleteCache(key);
                return null;
            }

            const cacheValue = drivers[storage].getItem(key);
            if (!cacheValue) return null;

            try {
                return JSON.parse(cacheValue);
            } catch (e) {
                warn('Cache', `解析缓存数据失败: ${e.message}`);
                deleteCache(key);
                return null;
            }
        } catch (e) {
            warn('Cache', `获取缓存失败: ${e.message}`);
            return null;
        }
    };

    /**
     * * 存入缓存
     * @param {String} key 缓存键名
     * @param {*} value 缓存值
     * @param {Number} ttl 缓存有效期，单位：秒
     * @param {String} storage 缓存存储位置，可选值：session, local
     * @returns {Boolean} 是否成功
     */
    const storeCache = (key, value, ttl = 0, storage = 'session') => {
        try {
            if (!validateKey(key) || !validateStorage(storage)) return false;

            if (typeof ttl !== 'number' || ttl < 0) {
                warn('Cache', '无效的过期时间');
                return false;
            }

            const expiredDate = Date.now() + ttl * 1000;
            const cacheData = getCacheData();

            cacheData[key] = { expires: expiredDate, storage };
            if (!updateCacheData(cacheData)) return false;

            try {
                drivers[storage].setItem(key, JSON.stringify(value));
                return true;
            } catch (e) {
                warn('Cache', `存储缓存数据失败: ${e.message}`);
                deleteCache(key);
                return false;
            }
        } catch (e) {
            warn('Cache', `存入缓存失败: ${e.message}`);
            return false;
        }
    };

    /**
     * * 检查缓存，如果缓存过期则删除或刷新缓存
     */
    const checkCache = () => {
        try {
            const cacheData = getCacheData();
            const now = Date.now();

            Object.entries(cacheData).forEach(([key, data]) => {
                const expires = data?.expires ?? 0;
                if (now > expires) {
                    if (refreshAbleKey.includes(key)) {
                        refreshCache(key);
                        log('Cache', `缓存 ${key} 已过期，已刷新`);
                    } else {
                        deleteCache(key);
                        log('Cache', `缓存 ${key} 已过期，已删除`);
                    }
                }
            });
        } catch (e) {
            warn('Cache', `检查缓存失败: ${e.message}`);
        }
    };

    /**
     * * 删除缓存
     * @param {String} key 缓存键名
     * @returns {Boolean} 是否成功
     */
    const deleteCache = (key) => {
        try {
            if (!validateKey(key)) return false;

            const cacheData = getCacheData();
            if (!cacheData[key]) return true;

            const { storage } = cacheData[key];
            if (!validateStorage(storage)) return false;

            drivers[storage].removeItem(key);
            delete cacheData[key];
            return updateCacheData(cacheData);
        } catch (e) {
            warn('Cache', `删除缓存失败: ${e.message}`);
            return false;
        }
    };

    /**
     * * 刷新缓存
     * @param {String} key 缓存键名
     * @returns {Boolean} 是否成功
     */
    const refreshCache = async (key) => {
        try {
            if (!validateKey(key)) return false;

            if (!refreshAbleKey.includes(key)) {
                warn('Cache', `${key} 不支持刷新`);
                return false;
            }

            const { getUserData, getShopData } = useUserApi();
            const refresher = {
                user: getUserData,
                shop: getShopData,
            }[key];

            await refresher(false);
            return true;
        } catch (e) {
            warn('Cache', `刷新缓存失败: ${e.message}`);
            deleteCache(key);
            return false;
        }
    };

    return {
        getCache,
        storeCache,
        checkCache,
        deleteCache,
        refreshCache,
    };
}