<script setup>
import { ref, watch } from 'vue'
import { VueFinalModal } from 'vue-final-modal'

const props = defineProps({
    // 模态框标题
    title: {
        type: String,
        default: 'Modal Title'
    },
    // 模态框大小: sm, md, lg, xl
    size: {
        type: String,
        default: 'md'
    },
    // 是否显示模态框
    modelValue: {
        type: Boolean,
        default: false
    },
    // 是否显示关闭按钮
    showClose: {
        type: Boolean,
        default: true
    },
    // 是否显示底部
    showFooter: {
        type: Boolean,
        default: true
    },
    // 关闭按钮文本
    closeText: {
        type: String,
        default: '关 闭'
    },
    // 是否锁定滚动
    lockScroll: {
        type: Boolean,
        default: true
    },
    // 是否点击遮罩层关闭
    clickToClose: {
        type: Boolean,
        default: true
    },
})

const emit = defineEmits(['update:modelValue', 'close'])

const isVisible = ref(props.modelValue)

// 监听 modelValue 变化
watch(() => props.modelValue, (val) => {
    isVisible.value = val
})

// 监听 isVisible 变化
watch(() => isVisible.value, (val) => {
    emit('update:modelValue', val)
    if (!val) {
        emit('close')
    }
})

// 关闭模态框
function handleClose() {
    isVisible.value = false
}
</script>

<template>
    <VueFinalModal 
        v-model="isVisible"
        class="finalModal" 
        :content-class="`mx-2 fm-${size}`"
        :click-to-close="clickToClose"
        :lock-scroll="lockScroll"
        overlay-transition="vfm-fade"
        content-transition="vfm-slide-right"
    >
        <div class="card fm-card shadow-xl">
            <!-- 头部区域 -->
            <div class="card-header fm-divider py-3">
                <!-- 标题插槽 -->
                <slot name="title">
                    <h5 class="mb-0">{{ title }}</h5>
                </slot>
            </div>

            <!-- 内容区域 -->
            <div class="card-body fm-divider">
                <slot></slot>
            </div>

            <!-- 底部区域 -->
            <div v-if="showFooter" class="card-footer py-3">
                <div class="d-flex justify-content-end">
                    <!-- 自定义按钮插槽 -->
                    <slot name="footer">
                        <slot name="buttons"></slot>
                        <button 
                            v-if="showClose"
                            class="btn btn-sm btn-outline-dark mb-0" 
                            @click="handleClose"
                        >
                            {{ closeText }}
                        </button>
                    </slot>
                </div>
            </div>
        </div>
    </VueFinalModal>
</template>