<script setup>
import { ref, watch } from 'vue'
import axios from '@/Plugins/axios'
import { useApiURL } from '@/composables/useApiURL'
import { useShowToast } from '@/composables/useShowToast'

const { getApiURL } = useApiURL()
const { showToast } = useShowToast()

const accounts = ref([
    {
        username: 'Waiting...',
        password: 'Waiting...',
        status: '加载中',
        ready: false
    }
])
const showDetails = ref(false)
const isLoading   = ref(false)

const copyToClipboard = async (text) => {
    try {
        // 优先使用 Navigator Clipboard API
        if (navigator.clipboard && window.isSecureContext) {
            await navigator.clipboard.writeText(text)
            showToast('操作成功', '成功复制账号信息')
            return
        }

        // 降级使用 document.execCommand
        const textArea = document.createElement('textarea')
        textArea.value = text
        textArea.style.position = 'fixed'
        textArea.style.left = '-999999px'
        textArea.style.top = '-999999px'
        document.body.appendChild(textArea)
        
        textArea.focus()
        textArea.select()

        try {
            const successful = document.execCommand('copy')
            if (successful) {
                showToast('操作成功', '成功复制账号信息')
            } else {
                throw new Error('复制失败')
            }
        } catch (err) {
            showToast('操作失败', '复制失败，请手动复制')
        } finally {
            document.body.removeChild(textArea)
        }
    } catch (err) {
        showToast('操作失败', '复制失败，请手动复制')
        console.error('Failed to copy text: ', err)
    }
}

const getAccount = async () => {
    if (isLoading.value) return
    
    isLoading.value = true
    try {
        const res = await axios.get(getApiURL('getIOSData'))
        accounts.value = [{
            username: res.data.account,
            password: res.data.password,
            status: 'available',
            ready: true
        }]
        showDetails.value = true
    } catch (error) {
        showToast('获取失败', '获取账号信息失败，请稍后重试', 'error')
        console.error(error)
    } finally {
        isLoading.value = false
    }
}

// 监听详情面板打开，自动获取账号
watch(showDetails, (val) => {
    if (val && !accounts.value[0].ready) {
        getAccount()
    }
})
</script>

<template>
    <div class="card">
        <div class="card-body p-3">
            <div class="row align-items-center">
                <div class="col-6">
                    <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-1">iOS 共享账号</h6>
                    <p class="text-sm mb-0">用于下载付费客户端</p>
                </div>
                <div class="col-6 text-end">
                    <button class="btn btn-sm bg-gradient-success mb-0" 
                            @click="showDetails = !showDetails"
                            :disabled="isLoading">
                        <div class="d-flex align-items-center justify-content-center">
                            <template v-if="!isLoading">
                                <i class="fas fa-chevron-left me-1" v-if="showDetails"></i>
                                <i class="fas fa-play me-1" v-else></i>
                                <span>{{ showDetails ? '返回' : '查看' }}</span>
                            </template>
                            <template v-else>
                                <span class="spinner-border spinner-border-sm"></span>
                                <span class="ms-1">加载中</span>
                            </template>
                        </div>
                    </button>
                </div>
            </div>

            <!-- 账号详情面板 -->
            <div class="mt-4 expand" v-show="showDetails">
                <div class="table-responsive">
                    <table class="table align-items-center mb-0">
                        <tbody>
                            <tr v-for="(account, index) in accounts" :key="index">
                                <td>
                                    <div class="d-flex px-2 py-1">
                                        <div class="d-flex flex-column justify-content-center">
                                            <div class="d-flex align-items-center mb-1">
                                                <h6 class="mb-0 text-sm">{{ account.username }}</h6>
                                                <i class="fas fa-copy ms-2 cursor-pointer" 
                                                    @click="copyToClipboard(account.username)"></i>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <p class="text-xs text-secondary mb-0">
                                                    密码: {{ account.password }}
                                                </p>
                                                <i class="fas fa-copy ms-2 cursor-pointer" 
                                                    @click="copyToClipboard(account.password)"></i>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="align-middle text-end">
                                    <span class="badge badge-sm bg-gradient-success">
                                        可用
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="alert alert-danger text-white py-2 mt-3 mb-2">
                    <div class="d-flex align-items-center">
                        <i class="fas fa-exclamation-triangle text-lg me-2"></i>
                        <div class="text-sm fw-bold">
                            严禁开启双重认证或登录iCloud！
                        </div>
                    </div>
                </div>

                <a href="https://docs.wsl.icu/appleid.html" 
                    target="_blank"
                    class="docs-link bg-gradient-warning mt-2 mb-0">
                    <i class="fas fa-book-open me-2"></i>
                    查看共享账号使用教程
                    <i class="fas fa-chevron-right ms-auto"></i>
                </a>
            </div>
        </div>
    </div>
</template>

<style scoped>
.fa-copy {
    transition: all 0.2s ease;
}

.fa-copy:hover {
    transform: scale(1.1);
    color: var(--bs-primary);
}

.docs-link {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    color: white;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    transition: all 0.2s ease;
}

.docs-link:hover {
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(33, 82, 255, 0.3);
}

.action-btn {
    min-width: 86px;
    height: 31px;
    padding: 0.25rem 0.75rem;
    margin-bottom: 0 !important;
}

.action-btn .d-flex {
    height: 100%;
}

@media (max-width: 768px) {
    .action-btn {
        width: 100%;
    }
}
</style>