<script setup>
import { ref, computed, onMounted } from 'vue'
import { Line } from 'vue-chartjs'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'
import { useStore } from 'vuex'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

const store = useStore()
const user = computed(() => store.state.user)

const diffType = ref('')
const diffVal = ref(0)

const chartOptions = ref({
    responsive: true,
    maintainAspectRatio: false,
    interaction: { 
        intersect: false, 
        mode: 'index' 
    },
    scales: {
        x: {
            grid: { 
                display: false, 
                drawBorder: false, 
                drawOnChartArea: false, 
                drawTicks: false, 
                borderDash: [5, 5] 
            },
            ticks: { 
                display: true, 
                padding: 10 
            }
        },
        y: {
            grid: { 
                drawBorder: false, 
                display: true, 
                drawOnChartArea: true, 
                drawTicks: false, 
                borderDash: [5, 5] 
            },
            ticks: { 
                display: true, 
                padding: 10 
            }
        }
    },
    plugins: {
        legend: { 
            display: false 
        },
        tooltip: {
            enabled: true,
            callbacks: {
                label: function(context) {
                    return '当日流量： ' + context.formattedValue + 'G'
                }
            },
            displayColors: false
        }
    }
})

const chartData = ref({
    labels: [],
    datasets: [{
        label: '当日流量',
        tension: 0.4,
        borderWidth: 3,
        pointRadius: 0,
        borderColor: "#5e72e4",
        backgroundColor: "#66ccff",
        fill: true,
        data: []
    }]
})

const ready = ref(false)

onMounted(() => {
    const date = new Date()
    const label = []
    for (let i = 0; i < 30; i++) {
        date.setDate(date.getDate() - 1)
        label.push(`${date.getMonth() + 1}-${date.getDate()}`)
    }
    chartData.value.labels = label.reverse()

    const trafficData = user.value?.analytics.trafficChartData.map(item => Number(item)).reverse()
    chartData.value.datasets[0].data = trafficData

    diffType.value = trafficData[29] - trafficData[28] > 0 ? '增加' : '减少'
    diffVal.value = Math.abs(trafficData[29] - trafficData[28]).toFixed(2)

    ready.value = true
})
</script>

<template>
    <div class="card h-100" v-if="user !== null">
        <div class="card-header pb-0 pt-3 bg-transparent">
            <h6 class="text-capitalize">30日流量图</h6>
            <p class="text-sm mb-0">
                <span>
                    昨天比前天{{ diffType }}了<code class="fs-5">{{ diffVal }}G</code>流量使用
                </span>
            </p>
        </div>

        <div class="card-body border-0 mt-3 pt-0">
            <Line :options="chartOptions" :data="chartData" v-if="ready" />
        </div>
    </div>
</template>