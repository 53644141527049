<script setup>
	import { ref } from 'vue'

	defineProps({
		active: {
			type: [Number, String],
			default: 0,
			validator: (value) => !isNaN(Number(value))
		},
		commission: {
			type: [Number, String],
			default: 0,
			validator: (value) => !isNaN(Number(value))
		},
		rate: {
			type: [Number, String],
			default: 0,
			validator: (value) => !isNaN(Number(value))
		},
		details: {
			type: Array,
			default: () => []
		}
	})

	const showDetail = ref(false)
	const isLoading = ref(false)
</script>

<template>
	<div class="card">
		<div class="card-header p-3 pb-0">
			<div class="d-flex justify-content-between align-items-center">
				<h6 class="mb-0">收益统计</h6>
				<button class="btn btn-xs btn-outline-dark mb-0" 
					@click="showDetail = !showDetail"
					:disabled="isLoading">
					<span v-if="isLoading" class="spinner-border spinner-border-sm me-1"></span>
					<i v-else class="fas fa-info-circle me-1"></i>
					收益明细
				</button>
			</div>
		</div>
		<div class="card-body p-3">
			<div class="row g-3" v-if="!showDetail">
				<div class="col-md-4">
					<div class="card card-plain text-center h-100">
						<div class="card-body d-flex flex-column justify-content-center">
							<div class="number-wrapper mb-1">
								<h1 class="text-gradient text-primary text-nowrap mb-0">
									¥{{ parseFloat(commission).toFixed(2) }}
								</h1>
							</div>
							<h6 class="text-muted mb-0">当前总收益</h6>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="card card-plain text-center h-100">
						<div class="card-body d-flex flex-column justify-content-center">
							<div class="number-wrapper mb-1">
								<h1 class="text-gradient text-info text-nowrap mb-0">
									{{ parseInt(active) }}
								</h1>
							</div>
							<h6 class="text-muted mb-0">有效邀请人数</h6>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="card card-plain text-center h-100">
						<div class="card-body d-flex flex-column justify-content-center">
							<div class="number-wrapper mb-1">
								<h1 class="text-gradient text-success text-nowrap mb-0">
									{{ parseInt(rate) }}%
								</h1>
							</div>
							<h6 class="text-muted mb-0">当前返利比例</h6>
						</div>
					</div>
				</div>
			</div>

			<div v-else>
				<div class="table-responsive">
					<table class="table align-items-center mb-0">
						<thead>
							<tr>
								<th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">用户</th>
								<th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">订单金额</th>
								<th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">返利金额</th>
								<th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">时间</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="detail in details" :key="detail.id">
								<td>
									<div class="d-flex px-2 py-1">
										<div class="d-flex flex-column justify-content-center">
											<h6 class="mb-0 text-sm">#{{ detail.source_uid }}</h6>
										</div>
									</div>
								</td>
								<td>
									<p class="text-sm font-weight-bold mb-0">¥{{ parseFloat(detail.source_amount).toFixed(2) }}</p>
								</td>
								<td>
									<p class="text-sm text-success font-weight-bold mb-0">
										¥{{ parseFloat(detail.referral_amount).toFixed(2) }}
									</p>
								</td>
								<td>
									<p class="text-sm text-secondary mb-0">{{ detail.created_at || '未知' }}</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div v-if="details.length === 0" class="text-center py-4 text-secondary">
					暂无收益记录
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.number-wrapper {
    overflow: hidden;
}
.number-wrapper h1 {
    font-size: 1.75rem;
    min-width: 0;
}
@media (max-width: 768px) {
    .number-wrapper h1 {
        font-size: 1.5rem;
    }
}
</style>