<script setup>
import { ref, onUnmounted } from 'vue'
import { useRouter }        from 'vue-router'
import { useApiURL }        from '@/composables/useApiURL'
import { useShowToast }     from '@/composables/useShowToast'
import { useCheckEmpty }    from '@/composables/useCheckEmpty'
import { useCache }         from '@/composables/useCache'

import axios                from '@/Plugins/axios'

const props = defineProps({
    limitPlans: {
        type: Object,
        default: () => ({})
    }
})

const router = useRouter()
const { getApiURL } = useApiURL()
const { showToast } = useShowToast()
const { checkEmpty } = useCheckEmpty()
const { deleteCache, refreshCache } = useCache()

const isLoading = ref(false)
const now = ref(new Date().getTime())

// 更新当前时间
const timer = setInterval(() => {
    now.value = new Date().getTime()
}, 1000)

onUnmounted(() => {
    clearInterval(timer)
})

// 购买操作
const purchase = async (id) => {
    if (isLoading.value) return
    isLoading.value = true

    try {
        const res = await axios.post(getApiURL('purchase'), { id })

        if (!checkEmpty(res.data.order)) {
            showToast('操作失败', '余额不足', 'warning')
            router.push({ name: '订单页', params: { id: res.data.order } })
            return
        }

        showToast('操作成功', '套餐订购成功', 'success')
        deleteCache('miscData-invoice')
        deleteCache('miscData-order')
        refreshCache('shop')

        router.push({ name: '订单页', params: { id: res.data.order } })
    } catch (err) {
        console.error(err)
    } finally {
        isLoading.value = false
    }
}

// 倒计时计算
const getCountdown = (deadline) => {
    deadline = deadline * 1000
    const difference = deadline - now.value

    if (difference <= 0) return null

    const days = Math.floor(difference / (1000 * 60 * 60 * 24))
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((difference % (1000 * 60)) / 1000)

    return {
        days: days.toString().padStart(2, '0'),
        hours: hours.toString().padStart(2, '0'),
        minutes: minutes.toString().padStart(2, '0'),
        seconds: seconds.toString().padStart(2, '0')
    }
}

// 格式化时间
const formatDate = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleString('zh-CN', {
        timeZone: 'Asia/Shanghai',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    })
}

// 获取套餐状态
const getPlanStatus = (plan) => {
    if (getCountdown(plan.released_at)) {
        return {
            type: 'waiting',
            text: '等待开售',
            class: 'text-primary'
        }
    }
    if (plan.stock <= 0) {
        return {
            type: 'soldout',
            text: '已售罄',
            class: 'text-danger'
        }
    }
    return {
        type: 'selling',
        text: '正在售卖',
        class: 'text-success'
    }
}
</script>

<template>
    <div class="card">
        <div class="card-header p-3 pb-0">
            <div class="d-flex justify-content-between align-items-center">
                <h6 class="mb-0">限时特惠</h6>
            </div>
        </div>
        <div class="card-body p-3">
            <div class="alert alert-light border-0 mb-3">
                <div class="d-flex align-items-center">
                    <i class="fas fa-info-circle text-primary me-2"></i>
                    <span class="text-sm">限购套餐需要账户余额充足，无法通过订单支付购买</span>
                </div>
            </div>

            <div class="row g-3">
                <div v-for="plan in limitPlans" :key="plan.id" class="col">
                    <div class="card border mb-0" :class="{
                        'border-primary': getPlanStatus(plan).type === 'waiting',
                        'border-danger': getPlanStatus(plan).type === 'soldout',
                        'border-success': getPlanStatus(plan).type === 'selling'
                    }">
                        <div class="card-body p-3">
                            <!-- 标题和价格 -->
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <div>
                                    <h6 class="mb-1">{{ plan.name }}</h6>
                                    <span class="badge" :class="{
                                        'bg-primary': getPlanStatus(plan).type === 'waiting',
                                        'bg-danger': getPlanStatus(plan).type === 'soldout',
                                        'bg-success': getPlanStatus(plan).type === 'selling'
                                    }">
                                        {{ getPlanStatus(plan).text }}
                                    </span>
                                </div>
                                <div class="text-end">
                                    <h5 class="mb-1" :class="getPlanStatus(plan).class">¥{{ plan.price }}</h5>
                                    <p class="text-xs text-muted mb-0">{{ formatDate(plan.released_at) }} 开售</p>
                                </div>
                            </div>

                            <!-- 套餐信息 -->
                            <div class="bg-light rounded p-3 mb-3">
                                <div class="row">
                                    <div class="col-6 border-end">
                                        <div class="text-center">
                                            <h6 class="mb-1">{{ plan.traffic }}GB</h6>
                                            <p class="text-xs text-muted mb-0">月流量</p>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="text-center">
                                            <h6 class="mb-1">{{ plan.duration }}天</h6>
                                            <p class="text-xs text-muted mb-0">有效期</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- 倒计时 -->
                            <div v-if="getCountdown(plan.released_at)" class="bg-light rounded p-2 mb-3">
                                <div class="d-flex justify-content-center gap-2">
                                    <template v-for="(val, key) in getCountdown(plan.released_at)" :key="key">
                                        <div class="text-center">
                                            <span class="badge bg-primary">{{ val }}</span>
                                            <small class="d-block text-xs text-secondary">
                                                {{
                                                    { 'days': '天', 'hours': '时', 'minutes': '分', 'seconds': '秒' }[key]
                                                }}
                                            </small>
                                        </div>
                                    </template>
                                </div>
                            </div>

                            <!-- 库存信息 -->
                            <div class="d-flex justify-content-center mb-3">
                                <span :class="plan.stock <= 0 ? 'text-danger' : 'text-success'">
                                    <i class="fas fa-box me-2"
                                        :class="plan.stock <= 0 ? 'text-danger' : 'text-success'"></i>
                                    剩余 {{ plan.stock }} 份
                                </span>
                            </div>

                            <!-- 操作按钮 -->
                            <button class="btn btn-sm w-100 mb-0" :class="{
                                'btn-primary': getPlanStatus(plan).type === 'selling',
                                'btn-outline-primary': getPlanStatus(plan).type === 'waiting',
                                'btn-outline-danger': getPlanStatus(plan).type === 'soldout'
                            }" @click="purchase(plan.id)"
                                :disabled="isLoading || getCountdown(plan.released_at) || plan.stock <= 0">
                                <span v-if="isLoading" class="spinner-border spinner-border-sm me-2"></span>
                                <template v-else>
                                    <span v-if="getCountdown(plan.released_at)">
                                        <i class="fas fa-clock me-2"></i>等待开售
                                    </span>
                                    <span v-else-if="plan.stock <= 0">
                                        <i class="fas fa-times me-2"></i>已售罄
                                    </span>
                                    <span v-else>
                                        <i class="fas fa-shopping-cart me-2"></i>立即购买
                                    </span>
                                </template>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.card.border {
    transition: all 0.2s ease;
}

.card.border:hover {
    transform: translateY(-2px);
}

.badge {
    padding: 0.35em 0.65em;
}

.border-end {
    border-right: 1px solid #e9ecef;
}
</style>