<script setup>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

import axios from 'axios'

import PackageCard  from './newUser/PackageCard.vue'
import BalanceCard  from './newUser/BalanceCard.vue'
import IOSAccount   from './newUser/IOSAccount.vue'
import ConfigGuide  from './newUser/ConfigGuide.vue'
import TrafficChart from './newUser/TrafficChart.vue'
import CheckInCard  from './newUser/CheckInCard.vue'
import AnnounceCard from '@/components/argon-dashboard-pro-2/annCard.vue'

const store  = useStore()

const user   = computed(() => store.state.user)
const remark = computed(() => {
    if (!user.value.remark) {
        return '正常用户'
    }

    // if remark is in [ 01, 02, 03, 04, 05, 06 ]
    if (user.value.remark.match(/^0[1-6]$/)) {
        return '第' + num2Han(
            Number(user.value.remark)
        ) + '批用户'
    }

    return '正常用户'
})
const isExpired = computed(() => {
    if (!user.value.active_plan) {
        return false
    }
    
    return new Date(user.value.expired_at) < new Date()
})
const backgroundImage = ref('')

onMounted(() => {
    if (!store.state.user) {
        store.dispatch('getUserData')
    }
    fetchBGImg()
})

const fetchBGImg = async () => {
    try {
        const res = await axios.get('https://1.wsl.icu/api/bing')
        backgroundImage.value = res.data.url
    } catch (error) {
        console.error('获取背景图片失败:', error)
        // 设置一个默认的背景图片
        backgroundImage.value = 'https://bing.com/th?id=OHR.AustriaMarmot_EN-US0012248153_1920x1080.jpg'
    }
}

const num2Han = (num) => {
    const map = [ '零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十' ]
    return map[num]
}

</script>

<template>
    <div class="container-fluid py-4">
        <!-- 警告提示 -->
        <div class="row mb-4" v-if="user">
            <div class="col-lg-8 col-12 mx-auto" v-if="user.level === 0">
                <div class="alert alert-warning alert-dismissible text-white p-2 px-3" role="alert">
                    <span class="fw-bold">
                        <i class="ni ni-bell-55"></i>
                        您的账号已过期，请及时续费以继续使用服务。
                    </span>
                    <router-link :to="{ name: '套餐中心' }" class="btn btn-sm btn-white text-warning mb-0 ms-3">
                        前往续费
                    </router-link>
                </div>
            </div>
            <div class="col-lg-8 col-12 mx-auto" v-if="user.traffic.remain <= 0">
                <div class="alert alert-danger alert-dismissible text-white p-2 px-3" role="alert">
                    <span class="fw-bold">
                        <i class="ni ni-bell-55"></i>
                        您的流量已用尽，请购买流量包或升级套餐。
                    </span>
                </div>
            </div>
        </div>

        <div class="row" v-if="user">
            <!-- 欢迎语 -->
            <div class="col-lg-8 col-12 mb-4 mx-auto">
                <div class="card bg-bing-img overflow-hidden">
                    <!-- 背景图片 -->
                    <div class="position-absolute w-100 h-100 top-0 start-0">
                        <img :src="backgroundImage"
                             class="w-100 h-100 object-fit-cover cursor-none user-select-none" 
                             alt="background"
                             v-if="backgroundImage"
                             >
                        <img src="@/assets/img/repeating-triangles.svg"
                             class="w-100 h-100 object-fit-cover cursor-none user-select-none"
                             alt="background"
                             v-else>
                    </div>

                    <div class="card-body position-relative" style="height: 150px;">
                        <!-- 左下角用户信息 -->
                        <div class="position-absolute bottom-0 start-0 p-4">
                            <div class="d-flex flex-column">
                                <h4 class="text-white mb-2">欢迎回来，{{ user.user_name }}</h4>
                                <div class="d-flex flex-wrap gap-2">
                                    <span class="badge badge-secondary">Lv.{{ user.level }}</span>
                                    <span class="badge badge-secondary">{{ remark }}</span>
                                    <span class="badge badge-warning" v-if="isExpired">已过期</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 左列 -->
            <div class="col-lg-8 col-12 mx-auto">
                <div class="row">
                    <div class="col-lg-6 col-12">
                        <div class="mb-4">
                            <PackageCard :user="user" />
                        </div>
                        <div class="mb-4">
                            <CheckInCard />
                        </div>
                        <div class="mb-4">
                            <BalanceCard :user="user" />
                        </div>
                        <div class="mb-4">
                            <ConfigGuide />
                        </div>
                        <div class="mb-4">
                            <IOSAccount />
                        </div>
                    </div>

                    <!-- 右列 -->
                    <div class="col-lg-6 col-12">
                        <div class="mb-4">
                            <AnnounceCard type="0" />
                        </div>
                        <div class="mb-4" style="height: 300px;">
                            <TrafficChart />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 加载状态 -->
        <div v-else class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body p-3">
                        <div class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                            <span class="placeholder col-12"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.expand {
    overflow: hidden;
    animation: expand .75s ease;
}

@keyframes expand {
    0% {
        /* height: 72px; */
        max-height: 70px;
    }
    100% {
        /* height: 170px; */
        max-height: 300px;
    }
}
</style>

<style scoped>
.object-fit-cover {
    object-fit: cover;
}
</style>