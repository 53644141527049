<template>
	<div class="card h-100">
		<div class="card-header p-3 pb-0">
			<h6 class="mb-0">使用指南</h6>
		</div>
		<div class="card-body p-3">
			<div class="timeline timeline-one-side">
				<div class="timeline-block mb-3">
					<span class="timeline-step">
						<i class="ni ni-circle-08 text-primary"></i>
					</span>
					<div class="timeline-content">
						<h6 class="mb-0">分享邀请码</h6>
						<p class="text-sm mt-1 mb-0">
							将您的邀请码或邀请链接分享给好友
						</p>
					</div>
				</div>
				<div class="timeline-block mb-3">
					<span class="timeline-step">
						<i class="ni ni-user-run text-success"></i>
					</span>
					<div class="timeline-content">
						<h6 class="mb-0">好友注册</h6>
						<p class="text-sm mt-1 mb-0">
							好友使用您的邀请码完成注册
						</p>
					</div>
				</div>
				<div class="timeline-block">
					<span class="timeline-step">
						<i class="ni ni-money-coins text-warning"></i>
					</span>
					<div class="timeline-content">
						<h6 class="mb-0">获得收益</h6>
						<p class="text-sm mt-1 mb-0">
							好友支付订单后您将获得返利收益
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>