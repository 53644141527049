<script setup>
    import { computed, onMounted, ref, nextTick, onUnmounted } from 'vue'

    const props = defineProps({
        height: {
            type: [String, Number],
            default: '100%',
            validator(value) {
                if (typeof value === 'number') {
                    return value > 0;
                }
                return true;
            }
        },
        type: {
            type: String,
            default: 'card', // card | background
        },
        // 表格列数
        columns: {
            type: Number,
            default: 5
        },
        // 表格行数
        rows: {
            type: Number,
            default: 5  
        }
    })

    const lines      = ref([])
    const cardBody   = ref(null)
    const headerRef  = ref(null)
    const contentRef = ref(null)
    const lineRef    = ref(null)

    // 计算实际使用的高度值
    const computedHeight = computed(() => {
        if (props.type === 'background') {
            return '40px' // background 类型使用固定高度
        }

        return typeof props.height === 'number' ? `${props.height}px` : props.height
    })

    // 根据高度计算行数
    const calculateLines = async () => {
        await nextTick()

        if(props.type !== 'card') return

        // 确保所有必需的 ref 都已挂载
        if (!cardBody.value || !headerRef.value || !contentRef.value || !lineRef.value) {
            // 如果元素未挂载，使用默认值
            const linesCount = Math.floor((parseInt(props.height) || 400) / 32) - 3
            lines.value = Array(linesCount).fill(null).map(() => ({
                width: `${Math.floor(Math.random() * 40 + 60)}%`
            }))
            return
        }

        // 获取实际元素尺寸
        const headerHeight   = headerRef.value.offsetHeight
        const bodyStyle     = getComputedStyle(cardBody.value)
        const contentStyle  = getComputedStyle(contentRef.value)
        const bodyPadding   = (parseInt(bodyStyle.paddingTop) + parseInt(bodyStyle.paddingBottom))
        const contentPadding = (parseInt(contentStyle.paddingTop) + parseInt(contentStyle.paddingBottom))
        
        // 获取行高和间距
        const lineStyle    = getComputedStyle(lineRef.value)
        const lineHeight   = lineRef.value.offsetHeight
        const marginBottom = parseInt(lineStyle.marginBottom)

        const containerHeight = parseInt(props.height) || 400
        
        // 预留底部空间
        const bottomSpace     = 32 // 底部预留32px的空间
        const availableHeight = containerHeight - headerHeight - bodyPadding - contentPadding - bottomSpace
        const linesCount     = Math.floor(availableHeight / (lineHeight + marginBottom))
        
        // 生成随机宽度的行
        lines.value = Array(linesCount).fill(null).map(() => ({
            width: `${Math.floor(Math.random() * 40 + 60)}%` // 60% - 100% 之间的随机宽度
        }))
    }

    onMounted(() => {
        // 确保DOM完全渲染后再计算
        nextTick(() => {
            calculateLines()
        })

        // 监听窗口大小变化
        window.addEventListener('resize', calculateLines)
    })

    // 清理事件监听
    onUnmounted(() => {
        window.removeEventListener('resize', calculateLines)
    })
</script>

<template>
    <!-- Card 类型骨架屏 -->
    <div class="card" :style="{ height: computedHeight }" v-if="type === 'card'">
        <div ref="headerRef" class="card-header border-bottom p-3 px-4">
            <div class="skeleton-loader-header"></div>
        </div>
        <div ref="cardBody" class="card-body">
            <div ref="contentRef" class="py-3 px-0">
                <div 
                    v-for="(line, index) in lines" 
                    :key="index"
                    class="skeleton-loader-line"
                    :style="{ width: line.width }"
                    :ref="index === 0 ? lineRef : undefined"
                ></div>
            </div>
        </div>
    </div>

    <!-- Background 类型骨架屏 -->
    <div v-else-if="type === 'background'" 
         class="skeleton-loader-background">
        <div class="skeleton-center-container">
            <div class="skeleton-lines">
                <div v-for="n in 6" :key="n" class="skeleton-line"></div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    /* Card 类型动画 */
    @keyframes cardShimmer {
        0% {
            background-position: 200% 0;
        }
        100% {
            background-position: -200% 0;
        }
    }

    /* Card 类型基础样式 */
    .skeleton-loader-header,
    .skeleton-loader-line {
        background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
        background-size: 200% 100%;
        animation: cardShimmer 1.5s infinite;
        border-radius: 4px;
    }

    /* Card 类型特定样式 */
    .skeleton-loader-header {
        height: 24px;
        width: 120px;
    }

    .skeleton-loader-line {
        height: 16px;
        margin-bottom: 16px;
        transition: width 0.3s ease;
    }

    .skeleton-loader-line:last-child {
        margin-bottom: 0;
    }

    /* Background 类型动画 */
    @keyframes lineWidth {
        0%, 100% { 
            width: 60%;
            opacity: 0.04;
        }
        50% { 
            width: 90%;
            opacity: 0.08;
        }
    }

    .skeleton-loader-background {
        position: relative;
        width: 100%;
        height: calc(100vh - 240px);
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .skeleton-center-container {
        width: 60%;
        max-width: 800px;
        aspect-ratio: 16/9;
        position: relative;
        display: flex;
        align-items: center;
    }

    .skeleton-lines {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .skeleton-line {
        height: 16px;
        background: currentColor;
        border-radius: 8px;
        animation: lineWidth 2s ease-in-out infinite;
    }

    .skeleton-line:nth-child(1) { animation-delay: -0.4s; }
    .skeleton-line:nth-child(2) { animation-delay: -0.2s; }
    .skeleton-line:nth-child(3) { animation-delay: 0s; }
    .skeleton-line:nth-child(4) { animation-delay: 0.2s; }
    .skeleton-line:nth-child(5) { animation-delay: 0.4s; }
    .skeleton-line:nth-child(6) { animation-delay: 0.6s; }

    /* 移动端适配 */
    @media (max-width: 768px) {
        .skeleton-loader-background {
            height: calc(100vh - 180px);
        }
        
        .skeleton-center-container {
            width: 90%;
        }

        .skeleton-lines {
            gap: 12px;
        }

        .skeleton-line {
            height: 12px;
        }
    }
</style>