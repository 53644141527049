<script setup>
    import { ref } from 'vue';

    import axios             from '@/Plugins/axios'
    import { useStore }      from 'vuex';
    import { useApiURL }     from '@/composables/useApiURL'
    import { useShowToast }  from '@/composables/useShowToast'
    import { useCheckEmpty } from '@/composables/useCheckEmpty';

    const { showToast }  = useShowToast()
    const { getApiURL }  = useApiURL()
    const { checkEmpty } = useCheckEmpty()
    const store          = useStore()

    const isLoading      = ref(false)
    const giftCodeDetail = ref(null)
    const giftCodeVal    = ref('')
    const giftCodeType   = ref(null)
    const url            = ref(getApiURL('giftCode'))

    const queryGiftCode = async () => {
        if (checkEmpty(giftCodeVal.value)) {
            showToast('操作失败', '请输入礼品码', 'error');
            return;
        }
        
        isLoading.value = true
        try {
            const res = await axios.get(`${url.value}?code=${giftCodeVal.value}`)
            
            giftCodeDetail.value = res.data.content
            giftCodeType.value   = res.data.type
        } catch (error) {
            console.error(error)
        } finally {
            isLoading.value = false
        }
    }

    const redeem = async () => {
        if (checkEmpty(giftCodeVal.value)) {
            showToast('操作失败', '请输入礼品码', 'error');
            return;
        }
     
        isLoading.value = true
        try {
            await axios.post(`${url.value}`, {
                code: giftCodeVal.value
            })

            showToast('操作成功', '礼品码兑换成功', 'success');

            store.dispatch('refreshUserData')
        } catch (error) {
            console.error(error)
        } finally {
            isLoading.value = false
        }
    }
</script>

<template>
    <div class="card h-100">
        <div class="card-header pb-2">
            <div class="d-flex justify-content-between">
                <h5 class="mb-0 text-capitalize">礼品码兑换</h5>
                
                <button class="btn btn-sm btn-primary mb-0"  @click="queryGiftCode" v-if="!giftCodeDetail" :disabled="isLoading">查询</button>

                <div v-else>
                    <button class="btn btn-sm btn-secondary mb-0 me-2"  @click="giftCodeDetail = null">取消</button>
                    <button class="btn btn-sm btn-primary mb-0 me-2"  @click="redeem" :disabled="isLoading">兑换</button>
                </div>
            </div>
        </div>

        <div class="card-body border-0 mt-3 pt-0">
            <p class="fw-bold text-dark ms-3">
                <i class="ni ni-bag-17"></i>
                使用必须遵守随附的使用规则
            </p>

            <div class="form-group" v-if="!giftCodeDetail">
                <label>输入礼品码</label>
                <input type="text" class="form-control" placeholder="在此输入礼品码内容" v-model="giftCodeVal">
            </div>

            <div v-else>
                <label>礼品码内容</label>
                <div class="form-group border border-dashed border-dark border-radius-lg p-3 mb-0">
                    <p class="mb-0 text-bold text-xs ps-2">类型：{{ giftCodeType ?? '等待加载' }}</p>
                    <p class="mb-0 text-bolder text-dark ps-2">内容：{{ giftCodeDetail ?? '等待加载' }}</p>
                </div>
            </div>
        </div>
    </div>
</template>