<script setup>
import { ref } from 'vue'
import newModal from '@/components/newModal.vue'
import { useApiURL } from '@/composables/useApiURL'
import { useShowToast } from '@/composables/useShowToast'
import { useCheckEmpty } from '@/composables/useCheckEmpty'
import { useStore } from 'vuex'
import axios from '@/Plugins/axios'

const store = useStore()
const { getApiURL } = useApiURL()
const { showToast } = useShowToast()
const { checkEmpty } = useCheckEmpty()

const showRedeemModal = ref(false)
const redeemCode = ref('')
const isLoading = ref(false)
const giftCodeDetail = ref(null)

// 查询礼品码
const queryGiftCode = async () => {
	if (checkEmpty(redeemCode.value)) {
		showToast('操作失败', '请输入兑换码', 'error')
		return
	}
	
	isLoading.value = true
	try {
		const res = await axios.get(getApiURL('giftCode'), {
			params: { code: redeemCode.value }
		})
		
		giftCodeDetail.value = res.data
	} catch (error) {
		console.error(error)
	} finally {
		isLoading.value = false
	}
}

// 重置所有状态
const resetAllState = () => {
	redeemCode.value = ''
	giftCodeDetail.value = null
	isLoading.value = false
}

// 监听模态框关闭
const handleModalClose = () => {
	resetAllState()
	showRedeemModal.value = false
}

// 返回查询状态
const backToQuery = () => {
	giftCodeDetail.value = null
}

// 兑换礼品码
const handleRedeem = async () => {
	if (checkEmpty(redeemCode.value)) {
		showToast('操作失败', '请输入兑换码', 'error')
		return
	}

	isLoading.value = true
	try {
		await axios.post(getApiURL('giftCode'), {
			code: redeemCode.value
		})

		showToast('操作成功', '兑换成功，正在刷新数据...', 'success')
		store.dispatch('refreshUserData')
		
		handleModalClose()
	} catch (error) {
		console.error(error)
		isLoading.value = false
	}
}
</script>

<template>
	<div class="card h-100">
		<div class="card-body p-3">
			<div class="d-flex justify-content-between align-items-center">
				<div>
					<p class="text-sm mb-1 text-capitalize font-weight-bold">礼品码兑换</p>
					<h5 class="font-weight-bolder mb-0">使用兑换码</h5>
				</div>
				<button 
					class="btn btn-outline-primary btn-sm px-3 mb-0"
					@click="showRedeemModal = true"
				>
					兑换
				</button>
			</div>
		</div>
	</div>

	<!-- 兑换模态框 -->
	<newModal
		v-model="showRedeemModal"
		title="礼品码兑换"
		size="md"
		@close="handleModalClose"
	>
		<div class="form-group" v-if="!giftCodeDetail">
			<label class="form-control-label">兑换码</label>
			<div class="input-group">
				<input 
					type="text" 
					class="form-control"
					v-model="redeemCode"
					placeholder="请输入兑换码"
					:disabled="isLoading"
					@keyup.enter="queryGiftCode"
				>
				<button 
					class="btn btn-outline-primary mb-0"
					@click="queryGiftCode"
					:disabled="!redeemCode || isLoading"
				>
					<span v-if="isLoading" class="spinner-border spinner-border-sm"></span>
					<span v-else>查询</span>
				</button>
			</div>
			<small class="form-text text-muted">
				请输入您获得的兑换码，可用于兑换流量包或余额等
			</small>
		</div>

		<div v-else class="form-group">
			<label>兑换详情</label>
			<div class="alert alert-light border-radius-lg p-3 mb-0">
				<div class="d-flex align-items-center mb-2">
					<i class="fas fa-gift text-primary me-2"></i>
					<h6 class="mb-0">{{ giftCodeDetail.type }}</h6>
				</div>
				<p class="text-sm mb-0">
					<span class="font-weight-bold">内容：</span>
					{{ giftCodeDetail.content }}
				</p>
			</div>
		</div>

		<template #buttons>
			<button 
				v-if="giftCodeDetail"
				class="btn btn-sm btn-primary me-2 mb-0"
				@click="handleRedeem"
				:disabled="isLoading"
			>
				<span v-if="isLoading" class="spinner-border spinner-border-sm me-2"></span>
				确认兑换
			</button>
			<button 
				v-if="giftCodeDetail"
				class="btn btn-sm btn-secondary mb-0 me-2"
				@click="backToQuery"
				:disabled="isLoading"
			>
				返回修改
			</button>
		</template>
	</newModal>
</template> 