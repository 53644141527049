<script setup>
import { ref } from 'vue'
import newModal from '@/components/newModal.vue'
import { useRouter } from 'vue-router'
import { useApiURL } from '@/composables/useApiURL'
import { useShowToast } from '@/composables/useShowToast'
import axios from '@/Plugins/axios'

const props = defineProps({
	money: {
		type: [Number, String],
		default: '0.00'
	}
})

const showRechargeModal = ref(false)
const rechargeAmount    = ref('')
const isLoading         = ref(false)

const router = useRouter()
const { getApiURL } = useApiURL()
const { showToast } = useShowToast()

const quickAmounts = [
	{ value: 50, label: '¥50' },
	{ value: 100, label: '¥100' },
	{ value: 200, label: '¥200' },
	{ value: 500, label: '¥500' }
]

const selectQuickAmount = (amount) => {
	rechargeAmount.value = amount.toString()
}

const handleRecharge = async () => {
	if (!rechargeAmount.value || isNaN(rechargeAmount.value)) {
		showToast('错误', '请输入正确的金额', 'error')
		return
	}

	if (isLoading.value) return
	isLoading.value = true

	try {
		const res = await axios.post(getApiURL('recharge'), {
			amount: parseFloat(rechargeAmount.value)
		})

		showToast('成功', '充值订单创建成功，正在跳转...', 'success')
		setTimeout(() => {
			router.push({ name: '订单页', params: { id: res.data.data?.id } })
		}, 1200)

		showRechargeModal.value = false
		rechargeAmount.value = ''
	} catch (error) {
		console.error(error)
		showToast('错误', error.response?.data?.message || '创建订单失败', 'error')
	} finally {
		isLoading.value = false
	}
}
</script>

<template>
	<div class="card h-100">
		<div class="card-body p-3">
			<div class="d-flex justify-content-between align-items-center">
				<div>
					<p class="text-sm mb-1 text-capitalize font-weight-bold">账户余额</p>
					<h5 class="font-weight-bolder mb-0">¥{{ money }}</h5>
				</div>
				<button 
					class="btn btn-outline-primary btn-sm px-3 mb-0"
					@click="showRechargeModal = true"
				>
					<i class="fas fa-wallet me-2"></i>
					充值
				</button>
			</div>
		</div>
	</div>

	<!-- 充值模态框 -->
	<newModal
		v-model="showRechargeModal"
		title="余额充值"
		size="md"
	>
		<div class="form-group">
			<label class="form-control-label">充值金额</label>
			<div class="input-group mb-3">
				<span class="input-group-text">¥</span>
				<input 
					type="number" 
					class="form-control"
					v-model="rechargeAmount"
					placeholder="请输入充值金额"
					:disabled="isLoading"
				>
			</div>
			
			<!-- 快捷金额选择 -->
			<div class="d-flex gap-2">
				<button 
					v-for="amount in quickAmounts"
					:key="amount.value"
					class="btn btn-outline-secondary btn-sm flex-grow-1"
					:class="{ 'active': rechargeAmount === amount.value.toString() }"
					@click="selectQuickAmount(amount.value)"
					type="button"
				>
					{{ amount.label }}
				</button>
			</div>
		</div>

		<template #buttons>
			<button 
				class="btn btn-sm btn-primary me-2 mb-0"
				@click="handleRecharge"
				:disabled="!rechargeAmount || isLoading"
			>
				<span v-if="isLoading" class="spinner-border spinner-border-sm me-2"></span>
				确认充值
			</button>
		</template>
	</newModal>
</template>

<style scoped>
.btn-outline-secondary.active {
	background-color: #8392ab;
	border-color: #8392ab;
	color: white;
}
</style> 