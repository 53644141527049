<script setup>
import { useShowToast } from '@/composables/useShowToast'
import { computed } from 'vue'
import QrcodeVue from 'qrcode.vue'

const { showToast } = useShowToast()

const props = defineProps({
	node: {
		type: Object,
		required: true
	}
})

// 生成二维码内容
const qrContent = computed(() => {
	if (!props.node) return ''

	let result = ''
	
	// 根据不同类型生成不同格式的链接
	if (props.node.type === 'trojan') {
		result = trojan(props.node)
	} else if (props.node.type === 'ssr') {
		result = ssr(props.node)
	}
	
	return result
})

const trojan = (node) => {
	let schemaData = [
		'trojan://',
		node.config.password + '@',
		node.host + ':',
		node.port,
		`?peer=${node.config.sni}`,
		'&allowInsecure=' + node.config.allow_insecure,
		`#${encodeURIComponent(node.name)}`
	];

	return schemaData.join('');
}

const ssr = (node) => {
	let schemaData = [
		node.host + ':',
		node.port + ':',
		node.config.protocol + ':',
		node.config.cipher + ':',
		node.config.obfs + ':',
		base64Encode(node.config.password),
		`/?obfsparam=${base64Encode(node.config.params.obfs)}`,
		`&protoparam=${base64Encode(node.config.params.protocol)}`,
		`&remarks=${base64Encode(node.name)}`,
		`&group=${base64Encode('CoolTi')}`
	];
	return 'ssr://' + base64Encode(schemaData.join(''));
}

const base64Encode = (str) => {
	return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
		function toSolidBytes(match, p1) {
			return String.fromCharCode('0x' + p1);
		})
	);
}

// 快捷操作链接
const quickLinks = computed(() => {
	// if node.type is ssr, return ssr link
	let link = ''
	if (props.node.type === 'ssr') {
		link = ssr(props.node)
	} else if (props.node.type === 'trojan') {
		link = trojan(props.node)
	}

	return [
		{ name: props.node.type === 'ssr' ? 'SSR一键导入' : 'Trojan一键导入', link }
	]
})

const emit = defineEmits(['back'])

const copy = async (val) => {
    try {
        if (navigator.clipboard && window.isSecureContext) {
            await navigator.clipboard.writeText(val)
            showToast('操作成功', '复制成功')
            return
        }

        // 降级使用 document.execCommand
        const textArea = document.createElement('textarea')
        textArea.value = val
        textArea.style.position = 'fixed'
        textArea.style.left = '-999999px'
        textArea.style.top = '-999999px'
        document.body.appendChild(textArea)
        
        textArea.focus()
        textArea.select()

        try {
            const successful = document.execCommand('copy')
            if (successful) {
                showToast('操作成功', '复制成功')
            } else {
                throw new Error('复制失败')
            }
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err)
        } finally {
            document.body.removeChild(textArea)
        }
    } catch (err) {
        console.error('Failed to copy text: ', err)
    }
}
</script>

<template>
	<div class="row">
		<div class="col-lg-8 col-12 mx-auto">
			<div class="row">
				<!-- 左侧详情区域 -->
				<div class="col-lg-6 col-12 mb-lg-0 mb-3">
					<div class="card border-1 border-dark shadow-xl h-100">
						<div class="card-header py-3 pb-0">
							<div class="d-flex justify-content-between align-items-center">
								<h5 class="mb-0">{{ node?.name }}</h5>
								<button class="btn btn-outline-primary btn-sm" @click="$emit('back')">
									<i class="fas fa-arrow-left me-2"></i>
									返回节点列表
								</button>
							</div>
						</div>
						
						<div class="card-body">
							<!-- 基本信息 -->
							<div class="mb-4">
								<h6 class="text-uppercase text-body text-xs font-weight-bolder">基本信息</h6>
								<div class="table-responsive">
									<table class="table align-items-center mb-0">
										<tbody>
											<tr>
												<td class="text-sm">节点类型</td>
												<td class="text-sm text-end text-uppercase">
													<span class="badge bg-primary">{{ node?.type }}</span>
												</td>
											</tr>
											<tr>
												<td class="text-sm">节点地址</td>
												<td class="text-sm text-end">
													<i class="fas fa-copy cursor-pointer me-2" @click="copy(node?.host)"></i>
													<span class="badge badge-secondary">{{ node?.host }}</span>
												</td>
											</tr>
											<tr>
												<td class="text-sm">端口</td>
												<td class="text-sm text-end">
													<i class="fas fa-copy cursor-pointer me-2" @click="copy(node?.port)"></i>
													<span class="badge badge-secondary">{{ node?.port }}</span>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>

							<!-- 配置信息 -->
							<div>
								<h6 class="text-uppercase text-body text-xs font-weight-bolder">配置信息</h6>
								<div class="table-responsive">
									<table class="table align-items-center mb-0">
										<tbody>
											<tr v-for="(value, key) in node?.parsedConfig" :key="key">
												<td class="text-sm">{{ key }}</td>
												<td class="text-sm text-end text-monospace">
													<i class="fas fa-copy cursor-pointer me-2" @click="copy(value)"></i>
													<span class="badge badge-secondary">{{ value }}</span>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- 右侧功能区域 -->
				<div class="col-lg-6 col-12">
					<div class="card h-100">
						<div class="card-header py-3 pb-0">
							<h6 class="text-uppercase text-body h6 font-weight-bolder mb-3">快捷操作</h6>
						</div>

						<div class="card-body">
							<div class="row pb-4">
								<!-- 左侧二维码 -->
								<div class="col-5 border-end d-flex flex-column">
									<div class="text-center flex-grow-1 d-flex flex-column justify-content-center">
										<h6 class="text-uppercase text-body text-xs font-weight-bolder mb-3">扫码使用</h6>
										<div class="qr-wrapper bg-light rounded-3 p-3 mx-auto" style="width: fit-content;">
											<qrcode-vue 
												class="img-fluid" 
												:value="qrContent" 
												:size="160" 
												level="L" 
												:render-as="'svg'" 
											/>
										</div>
										<div class="mt-3 text-xs text-muted">
											支持移动端扫码导入
										</div>
									</div>
								</div>

								<!-- 右侧快捷操作 -->
								<div class="col-7 ps-4">
									<div class="d-grid gap-2">
										<a v-for="link in quickLinks" 
										:key="link.name"
										:href="link.link"
										target="_blank"
										class="btn btn-outline-light text-start client-btn"
										:class="`btn-outline-${link.color}`">
											<div class="d-flex align-items-center">
												<div class="flex-grow-1">
													<div class="text-dark text-sm">{{ link.name }}</div>
													<div class="text-secondary text-xs">点击导入</div>
												</div>
												<i class="fas fa-chevron-right opacity-50"></i>
											</div>
										</a>
									</div>
								</div>
							</div>
							
							<div class="text-xs text-muted">
								因各客户端支持不同，此处可能无法为所有客户端提供快捷操作
							</div>
							<div class="text-xs text-muted">
								优先建议使用订阅链接导入
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.badge {
	text-transform: none;
}

.text-monospace {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.qr-wrapper {
	box-shadow: inset 0 0 10px rgba(0,0,0,0.05);
	background: #f8f9fa;
}

.btn-lg {
	transition: all 0.2s ease;
}

.btn-lg:hover {
	transform: translateX(5px);
}

.btn-lg:hover .fa-external-link-alt {
	opacity: 1 !important;
}

/* 确保图标不会因为按钮hover效果而移位 */
.fa-external-link-alt {
	font-size: 0.875rem;
	transition: opacity 0.2s ease;
}

/* 调整边框样式 */
.border-end {
	border-color: rgba(0, 0, 0, 0.1) !important;
}

/* 调整按钮样式 */
.client-btn {
	padding: 0.75rem 1rem;
	border-radius: 0.5rem;
	transition: all 0.2s ease;
}

.client-btn:hover {
	transform: translateX(5px);
}

.fa-chevron-right {
	font-size: 0.75rem;
	transition: transform 0.2s ease;
}

.client-btn:hover .fa-chevron-right {
	transform: translateX(3px);
	opacity: 1 !important;
}
</style> 