<script setup>
import { ref, onMounted } from 'vue'
import { useApiURL } from '@/composables/useApiURL'
import { useCache } from '@/composables/useCache'
import { useShowToast } from '@/composables/useShowToast'
import newModal from '@/components/newModal.vue'
import axios from '@/Plugins/axios'

const emit = defineEmits(['close'])
const { getApiURL } = useApiURL()
const { showToast } = useShowToast()
const { storeCache, getCache } = useCache()

const plans = ref([])
const modal = ref({
	show: false,
	id: null,
	type: '',
	title: ''
})

const isLoading = ref(false)
const isOperating = ref(false)

// 获取套餐列表
const getPlans = async (refresh = false) => {
	if (isLoading.value) return
	isLoading.value = true
	
	try {
		if (!refresh && getCache('plans')) {
			plans.value = getCache('plans')
			return
		}

		const res = await axios.get(getApiURL('plan'))
		plans.value = res.data
		storeCache('plans', res.data, 60 * 60)
	} catch (err) {
		showToast('错误', err.response.data.message, 'error')
		plans.value = []
	} finally {
		isLoading.value = false
	}
}

// 套餐操作
const planOperate = async (planID, type) => {
	if (isOperating.value) return
	isOperating.value = true

	try {
		const url = `${getApiURL('plan')}/${planID}/${type}`
		const res = await axios.post(url, { id: planID })
		
		showToast('成功', res.data.message, 'success')
		await getPlans(true)
		modal.value.show = false
	} catch (err) {
		showToast('错误', err.response?.data?.message || '操作失败', 'error')
	} finally {
		isOperating.value = false
	}
}

// 工具函数
const byteConvert = (bytes) => {
	if (typeof bytes !== 'number' || isNaN(bytes) || bytes < 0) return '0 GB'
	return `${(bytes / 1024 / 1024 / 1024).toFixed(0)} GB`
}

const getResetDays = (date) => {
	const now = new Date()
	const resetDate = new Date(date)
	const diffTime = Math.abs(now - resetDate)
	return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
}

const getLevelAlias = (level) => {
	const alias = { 1: '基础', 2: '进阶', 3: '专业' }
	return alias[level] || ''
}

const getRenewCount = (plan) => {
	return plan.renew > 0 ? `可续费 ${plan.renew} 次` : '无法续费'
}

// 打开操作模态框
const openModal = (type, planID) => {
	modal.value = {
		id: planID,
		type,
		show: true,
		title: {
			'apply': '套餐应用确认',
			'refund': '套餐退款确认',
			'renew': '套餐自动续费关闭确认',
			'merge': '套餐合并确认',
			'reset': '套餐付费重置确认'
		}[type] || ''
	}
}

// 格式化日期
const formatDate = (date) => {
	return new Date(date).toLocaleDateString('zh-CN')
}

onMounted(() => {
	getPlans()
})
</script>

<template>
	<div class="mt-4">
		<div class="d-flex justify-content-between align-items-center mb-4">
			<div class="d-flex align-items-center">
				<h5 class="mb-0">套餐管理</h5>
			</div>
			<button 
				class="btn btn-outline-secondary btn-sm px-3 mb-0"
				@click="$emit('close')"
			>
				<i class="fas fa-arrow-left me-2"></i>
				返回
			</button>
		</div>

		<!-- 加载状态 -->
		<div v-if="isLoading && !plans.length" class="text-center py-5">
			<div class="spinner-border text-primary" role="status">
				<span class="visually-hidden">Loading...</span>
			</div>
		</div>

		<!-- 空状态 -->
		<div class="row" v-if="plans.length === 0">
			<div class="col-12 text-center py-3">
				<div class="empty-state">
					<i class="fas fa-box-open fa-2x text-secondary mb-2"></i>
					<p class="text-secondary mb-0">当前没有有效套餐</p>
				</div>
			</div>
		</div>

		<!-- 耗尽套餐 -->
		<div v-if="plans.filter(p => p.status === 3).length > 0">
			<div class="d-flex align-items-center gap-2 mb-3">
				<i class="fas fa-exclamation-circle text-warning"></i>
				<span class="text-sm">流量耗尽套餐</span>
				<span class="badge bg-secondary">{{ plans.filter(p => p.status === 3).length }}</span>
			</div>

			<div class="row g-3">
				<div class="col-lg-4 col-md-6 col-12" 
					v-for="plan in plans.filter(p => p.status === 3)" 
					:key="plan.id"
				>
					<div class="card border mb-0">
						<!-- 标签和操作 -->
						<div class="card-header p-3 d-flex justify-content-between">
							<div>
								<span class="badge bg-primary" v-if="plan.active">当前</span>
								<span class="text-xs text-secondary ms-1">#{{ plan.id }}</span>
							</div>

							<div class="dropdown">
								<button class="btn btn-xs btn-outline-secondary dropdown-toggle mb-0" 
									type="button" 
									data-bs-toggle="dropdown"
								>
									操作菜单
								</button>
								<ul class="dropdown-menu">
									<li v-if="!plan.active">
										<button class="dropdown-item" @click="openModal('merge', plan.id)">
											<i class="fas fa-code-merge me-2"></i>合并套餐
										</button>
									</li>
									<li>
										<button class="dropdown-item text-primary" @click="openModal('reset', plan.id)">
											<i class="fas fa-sync me-2"></i>付费重置
										</button>
									</li>
								</ul>
							</div>    
						</div>

						<!-- 主要信息 -->
						<div class="card-body p-3 pt-0">
							<div class="d-flex justify-content-between align-items-baseline">
								<div>
									<h5 class="mb-0">
										Lv.{{ plan.level }}
										<span class="text-xs text-secondary">({{ getLevelAlias(plan.level) }})</span>
									</h5>
									<span class="text-xs text-secondary">套餐等级</span>
								</div>
								<h5 class="mb-0">
									<small class="text-secondary me-1">￥</small>{{ plan.price }}
								</h5>
							</div>

							<div class="mt-3">
								<div class="d-flex justify-content-between text-xs mb-1">
									<span>每月流量 {{ plan.total }}GB</span>
									<span class="text-danger">已耗尽</span>
								</div>
								<div class="bg-light rounded p-2">
									<div class="d-flex justify-content-between align-items-center">
										<div class="d-flex align-items-center">
											<i class="fas fa-exclamation-circle text-warning me-2"></i>
											<span class="text-xs text-secondary">重置时间</span>
										</div>
										<span class="text-xs">{{ getResetDays(plan.reset_at) }} 天后重置</span>
									</div>
								</div>
							</div>

							<div class="d-flex justify-content-between align-items-center mt-3">
								<span class="badge" 
									:class="[
										plan.renew > 0 ? 'bg-gradient-success' : 'bg-gradient-warning'
									]"
								>
									{{ getRenewCount(plan) }}
								</span>
								<span class="text-xs text-secondary">{{ formatDate(plan.expired_at) }} 到期</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 有效套餐 -->
		<div v-if="plans.filter(p => p.status !== 3).length > 0" class="mt-4">
			<div class="d-flex align-items-center gap-2 mb-3">
				<i class="fas fa-box-open"></i>
				<span class="text-sm">当前可用套餐</span>
			</div>

			<div class="row g-3">
				<div class="col-lg-4 col-md-6 col-12" 
					v-for="plan in plans.filter(p => p.status !== 3)" 
					:key="plan.id"
				>
					<div class="card border mb-0">
						<!-- 标签和操作 -->
						<div class="card-header p-3 d-flex justify-content-between">
							<div>
								<span class="badge bg-primary" v-if="plan.active">当前</span>
								<span class="badge bg-danger ms-1" v-if="plan.isNew">新套餐</span>
								<span class="text-xs text-secondary ms-1">#{{ plan.id }}</span>
							</div>

							<div class="dropdown">
								<button class="btn btn-xs btn-outline-secondary dropdown-toggle mb-0" 
									type="button" 
									data-bs-toggle="dropdown"
								>
									操作菜单
								</button>
								<ul class="dropdown-menu">
									<li v-if="!plan.active">
										<button class="dropdown-item text-primary" @click="openModal('apply', plan.id)">
											<i class="fas fa-check me-2"></i>应用此套餐
										</button>
									</li>
									<li v-if="!plan.active">
										<button class="dropdown-item" @click="openModal('merge', plan.id)">
											<i class="fas fa-code-merge me-2"></i>合并套餐
										</button>
									</li>
									<li v-if="plan.isNew">
										<button class="dropdown-item text-danger" @click="openModal('refund', plan.id)">
											<i class="fas fa-undo me-2"></i>退款套餐
										</button>
									</li>
									<li v-if="plan.renew !== 0">
										<button class="dropdown-item text-warning" @click="openModal('renew', plan.id)">
											<i class="fas fa-times me-2"></i>关闭自动续费
										</button>
									</li>
									<li>
										<button class="dropdown-item text-primary" @click="openModal('reset', plan.id)">
											<i class="fas fa-sync me-2"></i>付费重置
										</button>
									</li>
								</ul>
							</div>    
						</div>

						<!-- 主要信息 -->
						<div class="card-body p-3 pt-0">
							<div class="d-flex justify-content-between align-items-baseline">
								<div>
									<h5 class="mb-0">
										Lv.{{ plan.level }}
										<span class="text-xs text-secondary">({{ getLevelAlias(plan.level) }})</span>
									</h5>
									<span class="text-xs text-secondary">套餐等级</span>
								</div>
								<h5 class="mb-0">
									<small class="text-secondary me-1">￥</small>{{ plan.price }}
								</h5>
							</div>

							<div class="mt-3">
								<div class="d-flex justify-content-between text-xs mb-1">
									<span>每月流量 {{ plan.total }}GB</span>
									<span>剩余 {{ byteConvert(plan.remain) }}</span>
								</div>
								<div class="progress">
									<div class="progress-bar bg-gradient-info" 
										:style="`width: ${((plan.remain) / (plan.total * 1024 * 1024 * 1024)) * 100}%`"
									>
									</div>
								</div>
							</div>

							<div class="d-flex justify-content-between align-items-center mt-3">
								<span class="badge" 
									:class="[
										plan.renew > 0 ? 'bg-gradient-success' : 'bg-gradient-warning'
									]"
								>
									{{ getRenewCount(plan) }}
								</span>
								<span class="text-xs text-secondary">{{ formatDate(plan.expired_at) }} 到期</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 操作确认模态框 -->
		<newModal v-model="modal.show" :title="modal.title" size="md">
			<div class="text-sm">
				<template v-if="modal.type === 'apply'">
					<p class="mb-2">应用新套餐后，节点数据可能需要 5-15 分钟完成同步，请耐心等待</p>
				</template>

				<template v-if="modal.type === 'renew'">
					<p class="mb-2">关闭自动续费后，系统将不会在套餐到期时自动生成续费订单</p>
					<p class="mb-0">请注意：此操作<code class="fs-6 text-bolder">不可撤销</code>，您需要手动购买新套餐</p>
				</template>

				<template v-else-if="modal.type === 'merge'">
					<p class="mb-2">合并后，系统将按照购买价格和权重计算，将目标套餐剩余时长折算到当前套餐中</p>
					<p class="mb-0">请注意：此操作<code class="fs-6 text-bolder">不可撤销</code>，套餐等级和流量不会变更</p>
				</template>

				<template v-else-if="modal.type === 'refund'">
					<p class="mb-2">退款金额将优先退回至账户余额，如需退回原支付方式请提交工单</p>
					<p class="mb-2">退款完成后，系统将自动应用最新购买的有效套餐</p>
					<p class="mb-0">请注意：此操作<code class="fs-6 text-bolder">不可撤销</code>，套餐将立即失效</p>
				</template>

				<template v-else-if="modal.type === 'reset'">
					<p class="mb-2">重置后，当前套餐已用流量将归零，不影响下次自动重置时间</p>
					<p class="mb-2">费用为当前等级月付价格的 80%</p>
					
					<div class="bg-light rounded p-3 mt-3">
						<div class="d-flex align-items-center mb-2">
							<i class="fas fa-calculator text-primary me-2"></i>
							<span class="text-dark">费用计算示例</span>
						</div>
						
						<div class="d-flex justify-content-between align-items-center mb-2">
							<span class="text-sm">Lv.1 套餐月付价格</span>
							<span class="badge bg-secondary">¥13.00</span>
						</div>
						
						<div class="d-flex justify-content-between align-items-center mb-2">
							<span class="text-sm">重置折扣</span>
							<span class="badge bg-info">80%</span>
						</div>
						
						<hr class="horizontal dark my-2">
						
						<div class="d-flex justify-content-between align-items-center">
							<span class="text-sm fw-bold">重置费用</span>
							<span class="badge bg-gradient-primary">¥10.40</span>
						</div>
					</div>
				</template>
			</div>

			<template #buttons>
				<button 
					class="btn btn-sm btn-primary mb-0 me-2" 
					@click="planOperate(modal.id, modal.type)"
					:disabled="isOperating"
				>
					<span v-if="isOperating" class="spinner-border spinner-border-sm me-2"></span>
					<i v-else class="fas fa-check me-2"></i>
					确认
				</button>
			</template>
		</newModal>
	</div>
</template>

<style scoped>
.empty-state {
	padding: 2rem;
	background: #f8f9fa;
	border-radius: 0.5rem;
}

/* Dropdown Menu */
.dropdown-menu {
	display: none !important;
	transform: translate3d(0, 100%, 0);
	min-width: 160px;
}

.dropdown-menu.show {
	display: block !important;
}
</style> 