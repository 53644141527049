import { createRouter, createWebHistory } from 'vue-router'

import Error404View      from '@/views/Error404View.vue'

import HomeView     	 from '@/views/HomeView.vue'
import SpecialSalesView  from '@/views/SpecialSalesView.vue'

import AuthView     	 from '@/views/AuthView.vue'
import ShopCenterView    from '@/views/ShopCenterView.vue'
// import subCenterView     from '@/views/subCenterView.vue'
import logsCenterView    from '@/views/logsCenterView.vue'
import ticketCenterView  from '@/views/ticketCenterView.vue'
import OrderView         from '@/views/OrderView.vue'
import AcountView        from '@/views/AcountView.vue'

import UserView       	 from '@/views/UserView.vue'
import NodeView       	 from '@/views/NodeView.vue'
import InviteView 		 from '@/views/InviteView.vue'
import SubView           from '@/views/SubView.vue'
import NewPlanView       from '@/views/NewPlanView.vue'

const baseUrl = '/' ?? import.meta.env.BASE_URL;

// * For Git Merge Test

const router = createRouter({
	history: createWebHistory(baseUrl),
	routes: [
		// * 促销活动页面
		{ path: '/sales/2025_chunjie', 		name: '春节活动', 	   component: SpecialSalesView, 	meta: { needAuth: false }},

		{ path: '/', 						name: '首页', 	       component: HomeView, 			meta: { needAuth: false }},
		{ path: '/login', 					name: '登录', 		   component: AuthView, 			meta: { needAuth: false }},
		{ path: '/passwordReset/:token', 	name: '重置密码', 	   component: AuthView, 			meta: { needAuth: false }},

		{ path: '/user', 					name: '用户中心', 	   component: UserView, 			meta: { needAuth: true }},
		{ path: '/user/node', 				name: '节点中心', 	   component: NodeView, 			meta: { needAuth: true }},
		{ path: '/user/plan', 				name: '套餐中心', 	   component: NewPlanView, 			meta: { needAuth: true }},
		{ path: '/user/oldplan', 			name: '旧版套餐中心', 	   component: ShopCenterView, 		meta: { needAuth: true }},
		{ path: '/user/referral', 			name: '推广中心', 	   component: InviteView, 			meta: { needAuth: true }},
		{ path: '/user/sub', 				name: '订阅中心', 	   component: SubView, 				meta: { needAuth: true }},
		{ path: '/user/log', 				name: '日志中心', 	   component: logsCenterView, 		meta: { needAuth: true }},
		{ path: '/user/ticket', 			name: '工单中心', 	   component: ticketCenterView, 	meta: { needAuth: true }},
		{ path: '/user/account', 			name: '账户设置', 	   component: AcountView, 			meta: { needAuth: true }},
		{ path: '/order/:id', 				name: '订单页', 	   component: OrderView, 			meta: { needAuth: true }},

		// /user/shop redirect to /user/plan
		{ path: '/user/shop', 			redirect: '/user/plan' },
		{ path: '/auth', 				redirect: '/login' },
		{ path: '/auth/login', 			redirect: '/login' },
		{ path: '/auth/register', 		redirect: '/login' },

		{ path: '/:pathMatch(.*)*', name: '404', 		   component: Error404View, 		meta: { needAuth: false }},
	],
});

router.beforeEach((to, from, next) => {
	document.title = document.title = `CoolTi - ${to.name}`;

	console.log(`%c[Router]%c ${from.name ?? 'Init'} -> ${to.name}`, 'color: #00a8ff', 'color: #000');

	window.scrollTo(0, 0);

	if (typeof gtag !== 'undefined') {
		// if from = login, and needAuth = true, then it's a login event
		if (from.name === '登录' && to.meta.needAuth) {
			gtag('event', 'login_success', { 'event_category': 'login', 'event_label': 'login_success' });
		}
	}

	next();
});

export default router
