<script setup>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useApiURL } from '@/composables/useApiURL'
import axios from '@/Plugins/axios'

const router = useRouter()
const { getApiURL } = useApiURL()

// 分页数据
const orders = ref([])
const pagination = ref({
	currentPage: 1,
	lastPage: 1,
	total: 0,
	perPage: 10
})
const isLoading = ref(false)

// 订单类型映射
const ORDER_TYPES = {
	1: '购买套餐',
	2: '续费套餐',
	3: '增值服务',
	4: '余额充值',
	5: '合并套餐'
}

// 订单状态映射
const ORDER_STATUS = {
	0: { text: '待支付', class: 'warning' },
	1: { text: '已完成', class: 'success' },
	2: { text: '已取消', class: 'secondary' }
}

// 格式化金额
const formatAmount = (amount) => Number(amount).toFixed(2)

// 格式化时间
const formatDate = (date) => {
	if (!date) return '-'
	return new Date(date).toLocaleString('zh-CN', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit'
	})
}

const getHistoryOrders = async (page = 1) => {
	if (isLoading.value) return
	
	isLoading.value = true
	try {
		const res = await axios.get(getApiURL('order'), {
			params: { page }
		})
		
		orders.value = res.data.data
		pagination.value = {
			currentPage: res.data.current_page,
			lastPage: res.data.last_page,
			total: res.data.total,
			perPage: res.data.per_page
		}
	} catch (err) {
		console.error(err)
	} finally {
		isLoading.value = false
	}
}

// 页码计算
const pageNumbers = computed(() => {
	const current = pagination.value.currentPage
	const last = pagination.value.lastPage
	const delta = 2
	const range = []
	
	for (let i = Math.max(2, current - delta); i <= Math.min(last - 1, current + delta); i++) {
		range.push(i)
	}
	
	if (current - delta > 2) {
		range.unshift('...')
	}
	if (current + delta < last - 1) {
		range.push('...')
	}
	
	range.unshift(1)
	if (last > 1) range.push(last)
	
	return range
})

// 页面切换
const changePage = (page) => {
	if (page === '...' || page === pagination.value.currentPage) return
	getHistoryOrders(page)
}

// 初始化加载
getHistoryOrders()

// 跳转到订单详情
const goToOrderDetail = (id) => {
	router.push({ 
		name: '订单页', 
		params: { id }
	})
}
</script>

<template>
	<div class="card">
		<div class="card-header pb-0">
			<h6 class="mb-0">订单历史</h6>
		</div>
		<div class="card-body p-3">
			<div class="table-responsive">
				<table class="table align-items-center mb-0">
					<thead>
						<tr>
							<th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-0">订单号</th>
							<th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-0">类型</th>
							<th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-0">金额</th>
							<th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-0">状态</th>
							<th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-0">创建时间</th>
							<th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-0">操作</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="!isLoading && orders.length">
							<tr v-for="order in orders" :key="order.id">
								<td>
									<p class="text-xs font-weight-bold mb-0">#{{ order.id }}</p>
								</td>
								<td>
									<p class="text-xs text-secondary mb-0">{{ ORDER_TYPES[order.type] || '未知类型' }}</p>
								</td>
								<td>
									<p class="text-xs font-weight-bold mb-0">
										¥{{ formatAmount(order.total_amount) }}
										<small v-if="order.discount_amount > 0" class="text-success ms-1">
											(-{{ formatAmount(order.discount_amount) }})
										</small>
									</p>
								</td>
								<td>
									<span 
										class="badge badge-sm" 
										:class="`bg-gradient-${ORDER_STATUS[order.status]?.class || 'secondary'}`"
									>
										{{ ORDER_STATUS[order.status]?.text || '未知状态' }}
									</span>
								</td>
								<td>
									<p class="text-xs text-secondary mb-0">{{ formatDate(order.created_at) }}</p>
								</td>
								<td>
									<button 
										class="btn btn-link btn-sm mb-0 p-0"
										@click="goToOrderDetail(order.id)"
									>
										<i class="fas fa-external-link-alt me-1"></i>
										查看订单
									</button>
								</td>
							</tr>
						</template>
						<tr v-else-if="isLoading">
							<td colspan="7" class="text-center py-4">
								<div class="spinner-border text-primary" role="status">
									<span class="visually-hidden">Loading...</span>
								</div>
							</td>
						</tr>
						<tr v-else>
							<td colspan="7" class="text-center py-4 text-secondary">
								暂无订单记录
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			
			<!-- 分页 -->
			<div v-if="!isLoading && orders.length" class="d-flex justify-content-between align-items-center mt-3">
				<p class="text-sm text-secondary mb-0">
					共 {{ pagination.total }} 条记录
				</p>
				<nav aria-label="Page navigation">
					<ul class="pagination pagination-sm justify-content-end mb-0">
						<li 
							class="page-item" 
							:class="{ disabled: pagination.currentPage === 1 }"
						>
							<a 
								class="page-link" 
								href="#" 
								@click.prevent="changePage(pagination.currentPage - 1)"
							>
								<i class="fas fa-chevron-left"></i>
							</a>
						</li>
						
						<li 
							v-for="page in pageNumbers" 
							:key="page"
							class="page-item"
							:class="{ active: page === pagination.currentPage }"
						>
							<a 
								class="page-link" 
								href="#"
								@click.prevent="changePage(page)"
							>
								{{ page }}
							</a>
						</li>
						
						<li 
							class="page-item"
							:class="{ disabled: pagination.currentPage === pagination.lastPage }"
						>
							<a 
								class="page-link" 
								href="#" 
								@click.prevent="changePage(pagination.currentPage + 1)"
							>
								<i class="fas fa-chevron-right"></i>
							</a>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	</div>
</template>