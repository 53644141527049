<template>
    <div class="loader-container">
		<div class="sk-folding-cube loader-animation">
			<div class="sk-cube1 sk-cube"></div>
			<div class="sk-cube2 sk-cube"></div>
			<div class="sk-cube4 sk-cube"></div>
			<div class="sk-cube3 sk-cube"></div>
		</div>
	</div>
</template>
<script>
export default {
    name: 'cooltiLoader',
}
</script>