<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import axios            from '@/Plugins/axios'
import { useStore }     from 'vuex'
import { useShowToast } from '@/composables/useShowToast'
import { useApiURL }    from '@/composables/useApiURL'

const store         = useStore()
const { showToast } = useShowToast()
const { getApiURL } = useApiURL()

const isLoading = ref(false)
const password  = ref('')
const discount  = ref(null)
const remark    = ref({
    '01': { count: 3, off: 25, coupon: '75折', },
    '02': { count: 2, off: 20, coupon: '8折', },
    '03': { count: 2, off: 15, coupon: '85折', },
    '04': { count: 1, off: 10, coupon: '9折', },
    '05': { count: 1, off: 5,  coupon: '95折', },
})
const userRemark = ref( store.state.user?.remark )
const email      = ref(store.state.user?.email)

onMounted(() => {
    if (!store.state.user) {
        store.dispatch('getUserData')
    }

    window.addEventListener('beforeunload', handlebeforeunload)
})

onUnmounted(() => {
    window.removeEventListener('beforeunload', handlebeforeunload)
})

const createDiscount = async () => {
    if (!password.value) {
        showToast('数据缺失', '请输入账户密码', 'warning')
        return
    }

    isLoading.value = true
    try {
        const res = await axios.post(getApiURL('createDiscount'), {
            password: password.value,
        })
        
        showToast('操作成功', res.data.message, 'success')
        discount.value = res.data.data
        password.value = ''
    } catch (err) {
        console.error(err)
    } finally {
        isLoading.value = false
    }
}

// 页面离开提示
const handlebeforeunload = (e) => {
    if (discount.value) {
        e.preventDefault()
        e.returnValue = ''
    }
}
</script>

<template>
    <div class="card">
        <div class="card-header border-bottom p-3 px-4 text-dark">
            <div class="d-flex align-items-center">
                <i class="ni ni-tag me-2"></i>
                <span>批次特惠</span>
            </div>
        </div>

        <div class="card-body">
            <div v-if="!discount">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label text-sm">优惠码创建</label>
                            <div class="ms-2">
                                <p class="text-sm text-dark">
                                    <i class="ni ni-bag-17"></i>
                                    您当前每月可创建<code>{{ remark[userRemark]?.count }}个</code>优惠码供他人使用
                                </p>
                                <p class="text-sm text-dark">
                                    <i class="ni ni-bag-17"></i>
                                    每个优惠码折扣为 <code>{{ remark[userRemark]?.off }}%（{{ remark[userRemark]?.coupon }}）</code>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <form>
                            <div class="form-group d-none">
                                <label class="d-block text-sm">邮箱</label>
                                <input type="email" autocomplete="email" class="form-control" v-model="email" disabled />
                            </div>

                            <div class="form-group">
                                <label class="form-label text-sm">登录密码</label>
                                <input 
                                    type="password" 
                                    class="form-control" 
                                    placeholder="请输入登录密码"
                                    v-model="password"
                                    autocomplete="current-password"
                                >
                            </div>
                        </form>
                    </div>
                </div>

                <button 
                    class="btn btn-primary w-100 mt-4" 
                    @click="createDiscount" 
                    :disabled="isLoading"
                >
                    <span v-if="isLoading" class="spinner-border spinner-border-sm me-2"></span>
                    创建优惠码
                </button>
            </div>

            <div v-else>
                <h5 class="text-danger text-center mb-4">以下内容仅展示一次，请妥善保存</h5>

                <div class="ms-2">
                    <p class="text-sm text-dark">
                        <i class="ni ni-tag"></i>
                        优惠码为 <code>{{ discount.code }}</code>
                    </p>
                    <p class="text-sm text-dark">
                        <i class="ni ni-money-coins"></i>
                        优惠码折扣为 <code>{{ discount.value }}%（{{ 100 - discount.value }}折）</code>
                    </p>
                    <p class="text-sm text-dark">
                        <i class="ni ni-calendar-grid-58"></i>
                        优惠码有效期至 <code>{{ discount.expired_at }}</code>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
i {
    font-size: 1.125rem;
    color: var(--bs-primary);
}

code {
    font-size: 1.1rem;
    padding-left: 0.2rem;
}

.ms-2 i {
    font-size: 1rem;
    margin-right: 0.5rem;
}
</style> 