<script setup>
import { ref } from 'vue'

import axios from '@/Plugins/axios'

import { useApiURL } from '@/composables/useApiURL'
import { useShowToast } from '@/composables/useShowToast'
import { useRouter } from 'vue-router'

const { getApiURL } = useApiURL()
const { showToast } = useShowToast()
const router        = useRouter()

const props = defineProps({
    user: {
        type: Object,
        required: true
    }
})

const showRecharge = ref(false)
const rechargeAmount = ref('')

const handleRecharge = async () => {
    if (!rechargeAmount.value || isNaN(rechargeAmount.value)) {
        showToast('错误', '请输入正确的金额', 'error')
        return
    }
    const amount = parseFloat(rechargeAmount.value)

    try {
        const res = await axios.post(getApiURL('recharge'), {
            amount: amount
        })

        showToast('成功', '充值订单创建成功，正在跳转到订单页面......', 'success')

        setTimeout(() => {
            router.push({ name: '订单页', params: { id: res.data.data?.id, } })
        }, 1200)

        showRecharge.value = false
        rechargeAmount.value = ''
    } catch (error) {
        console.error(error)
    }
}

const closeRecharge = () => {
    showRecharge.value = false
    rechargeAmount.value = ''
}
</script>

<template>
    <div class="card">
        <div class="card-body p-3">
            <div class="row align-items-center">
                <div class="col-6">
                    <div class="numbers">
                        <p class="text-sm mb-0 text-uppercase font-weight-bold">账户余额</p>
                        <h5 class="font-weight-bolder mb-0">
                            ¥ {{ user.money }}
                        </h5>
                    </div>
                </div>
                <div class="col-6 text-end">
                    <button class="btn btn-sm bg-gradient-success mb-0" @click="showRecharge = true" v-if="!showRecharge">
                        <i class="ni ni-money-coins me-1"></i> 充值
                    </button>
                    <button class="btn btn-sm btn-outline-secondary mb-0" @click="showRecharge = false" v-else>
                        <i class="fas fa-arrow-left me-1"></i>
                        收回
                    </button>
                </div>
            </div>

            <!-- 充值面板 -->
            <div class="mt-4" v-if="showRecharge">
                <div class="form-group">
                    <label class="form-control-label">充值金额</label>
                    <div class="input-group">
                        <span class="input-group-text">¥</span>
                        <input type="number" 
                               class="form-control"
                               placeholder="请输入充值金额"
                               v-model="rechargeAmount"
                               min="1"
                               step="0.01">
                    </div>
                    <small class="text-muted fw-bold text-xs ms-3">最低充值金额为1元</small>
                </div>
                
                <div class="d-flex justify-content-end mt-4">
                    <button class="btn btn-sm btn-outline-secondary mb-0 me-2" @click="closeRecharge">
                        取消
                    </button>
                    <button class="btn btn-sm bg-gradient-success mb-0" @click="handleRecharge">
                        确认充值
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>