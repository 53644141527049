<script setup>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import axios from '@/Plugins/axios'
import { useShowToast } from '@/composables/useShowToast'
import { useApiURL } from '@/composables/useApiURL'

import InviteStatsCard from './Invite/StatsCard.vue'
import InviteGuideCard from './Invite/GuideCard.vue'
import InviteRulesCard from './Invite/RulesCard.vue'
import InviteConvertCard from './Invite/ConvertCard.vue'

const store 		= useStore()
const { showToast } = useShowToast()
const { getApiURL } = useApiURL()

const inviteData = ref({
	code: '',
	link: '',
	active: 0,
	commission: 0,
	rate: 0,
	detail: []
})

const copy = async (val) => {
    try {
        if (navigator.clipboard && window.isSecureContext) {
            await navigator.clipboard.writeText(val)
            showToast('操作成功', '复制成功')
            return
        }

        // 降级使用 document.execCommand
        const textArea = document.createElement('textarea')
        textArea.value = val
        textArea.style.position = 'fixed'
        textArea.style.left = '-999999px'
        textArea.style.top = '-999999px'
        document.body.appendChild(textArea)
        
        textArea.focus()
        textArea.select()

        try {
            const successful = document.execCommand('copy')
            if (successful) {
                showToast('操作成功', '复制成功')
            } else {
                throw new Error('复制失败')
            }
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err)
        } finally {
            document.body.removeChild(textArea)
        }
    } catch (err) {
        console.error('Failed to copy text: ', err)
    }
}

const getInviteData = async () => {
	try {
		const res = await axios.get(getApiURL('referral'))
		inviteData.value = res.data
	} catch (err) {
		console.error(err)
	}
}

const refresh = () => {
	store.dispatch('refreshUserData')

	getInviteData();
}

onMounted(() => {
	getInviteData();
})
</script>

<template>
	<div class="container-fluid py-4 w-100 w-xl-90">
		<div class="row">
			<!-- 邀请码和链接卡片 -->
			<div class="col-lg-8 col-12 mx-auto mb-4">
				<div class="card">
					<div class="card-header p-3 pb-0">
						<h6 class="mb-0">邀请信息</h6>
					</div>
					<div class="card-body p-3">
						<div class="row">
							<div class="col-12 col-md-6 mb-3">
								<label class="form-label">邀请码</label>
								<div class="input-group">
									<input type="text" class="form-control" :value="inviteData.code" readonly>
									<button class="btn btn-outline-primary mb-0"
										@click="copy(inviteData.code)">
										复制
									</button>
								</div>
							</div>
							<div class="col-12 col-md-6">
								<label class="form-label">邀请链接</label>
								<div class="input-group">
									<input type="text" class="form-control" :value="inviteData.link" readonly>
									<button class="btn btn-outline-primary mb-0"
										@click="copy(inviteData.link)">
										复制
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 统计数据卡片 -->
			<div class="col-lg-8 col-12 mx-auto mb-4">
				<InviteStatsCard :active="inviteData.active"
					:commission="inviteData.commission"
					:rate="inviteData.rate"
					:details="inviteData.detail" />
			</div>

			<!-- 使用指南和规则 -->
			<div class="col-lg-8 col-12 mx-auto">
				<div class="row">
					<div class="col-12 col-md-6 mb-4">
						<InviteGuideCard />
					</div>
					<div class="col-12 col-md-6 mb-4">
						<InviteRulesCard />
					</div>
				</div>
			</div>

			<!-- 收益转换 -->
			<div class="col-lg-8 col-12 mx-auto mb-4">
				<InviteConvertCard @refresh="refresh" />
			</div>
		</div>
	</div>
</template>