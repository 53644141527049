<script setup>
    import limitPlanCard        from '@/views/Shop/limitPlanCard.vue'

    import { ref, computed, onMounted, watch, onUnmounted } from 'vue'
    import { useStore }         from 'vuex'
    import { useRouter }        from 'vue-router'
    import axios                from '@/Plugins/axios'
    import { useUserApi }       from '@/composables/useUserApi'
    import { useCheckEmpty }    from '@/composables/useCheckEmpty'
    import { useCache }         from '@/composables/useCache'
    import { useApiURL }        from '@/composables/useApiURL'
    import { useShowToast }     from '@/composables/useShowToast';

    const store                         = useStore()
    const router                        = useRouter()
    const { checkEmpty }                = useCheckEmpty()
    const { deleteCache, refreshCache } = useCache()
    const { getShopData }               = useUserApi()
    const { getApiURL }                 = useApiURL()
    const { showToast }                 = useShowToast()

    // 响应式状态
    const shopData     = ref(null)
    const price        = ref(null)
    const priceLoading = ref(false)
    const active       = ref({})
    const limits       = ref([])

    // 描述项数据
    const descItems = ref([
        { name: '限速', value: '等待选择', desc: '最高可达使用速率' },
        { name: '连接数', value: '等待选择', desc: '同时在线IP数，多于不覆盖' }
    ])

    // 范围选项
    const range = ref({
        level: [1, 2, 3],
        duration: [30, 90, 180, 360]
    })

    const activePlan = computed(() => {
        return store.state.user?.active_plan || false
    });

    const selectedParam = ref({
        level: null,
        duration: null,
        traffic: null,
        autoRenew: true,
        mergePlan: true,
    });

    const interval = ref(null)

    const isMobile = computed(() => {
        return window.innerWidth < 768
    })

    // 计算属性
    const planSelected = computed(() => !checkEmpty(active.value.id))
    const priceboard = computed(() => [
        { title: '套餐价格',    sub: '套餐原始价格',        prefix: '',     val: price.value?.total || '0.00', start: true },
        { title: ' »批次抵扣',  sub: '与优惠抵扣不兼容',    prefix: '-',    val: price.value?.remark || '0.00' },
        { title: ' »优惠抵扣',  sub: '百分比或固定金额',    prefix: '-',    val: price.value?.discount || '0.00' },
        { title: ' »余额抵扣',  sub: '抵扣不足则生成账单',  prefix: '-',    val: price.value?.balance || '0.00' },
        { title: '最终价格',    sub: '最终确定需支付金额',  prefix: '',     val: price.value?.final || '0.00', end: true }
    ])

    const checkPrice = async () => {
        try {
            let url = `${getApiURL('getShopData')}/${active.value.id}`
            const res = await axios.get(url, {
                params: {
                    discount: active.value?.discount ?? null
                }
            })
            
            // 格式化价格
            for (const key in res.data.amount) {
                res.data.amount[key] = Number(res.data.amount[key]).toFixed(2)
            }
            price.value = res.data.amount

            if (active.value?.discount) {
                showToast('操作成功', '优惠码已应用', 'success')
            }
        } catch (err) {
            console.error(err)
        }
    }

    const applyDiscount = () => {
        if (checkEmpty(active.value.discount)) {
            showToast('操作失败', '优惠码不能为空', 'error')
            return
        }
        checkPrice()
    }

    const orderPlan = async () => {
        if (checkEmpty(active.value.id)) {
            showToast('操作失败', '请完善套餐参数选择', 'error')
            return
        }

        try {
            const res = await axios.post(getApiURL('purchase'), {
                id: active.value.id,
                discount: active.value.discount,
                renew: selectedParam.value.autoRenew,
                merge: selectedParam.value.mergePlan
            })

            if (!checkEmpty(res.data.order)) {
                showToast('余额不足', '正在跳转至订单页', 'warning')
                setTimeout(() => {
                    router.push({ name: '订单页', params: { id: res.data.order } })
                }, 1200)
                return
            }

            showToast('操作成功', '套餐订购成功', 'success')

            // 清除缓存
            deleteCache('miscData-invoice')
            deleteCache('miscData-order')
            refreshCache('shop')

            checkModalHandler({ 
                id: Number(res.data.order), 
                type: 'order', 
                url: null, 
                show: true 
            })
        } catch (err) {
            console.error(err)
        }
    }

    const handlePlanChange = () => {
        if (checkEmpty([selectedParam.value.duration, selectedParam.value.level], true)) return

        const shop = shopData.value.common.find(
            item => item.duration === selectedParam.value.duration && item.level === selectedParam.value.level
        )

        if (!shop) {
            showToast('执行错误', '未找到对应套餐，请尝试重新选择', 'warning')
            return
        }

        descItems.value = [
            { name: '限速', desc: '最高可达使用速率', value: shop.content.speedlimit === 0 ? '无限速' : `${shop.content.speedlimit} M` },
            { name: '连接数', desc: '同时在线IP数，多于不覆盖', value: `${shop.content.ipLimit} 个` }
        ]

        selectedParam.value.traffic = shop.traffic

        active.value.id      = shop.id
        active.value.traffic = shop.traffic
        checkPrice()
    }

    // 监听器
    watch([selectedParam.value], handlePlanChange)

    watch(price, () => {
        priceLoading.value = true
        setTimeout(() => {
            priceLoading.value = false
        }, 250)
    }, { deep: true })

    // 生命周期钩子
    onMounted(async () => {
        const res = await getShopData()
        shopData.value = res

        // 设置限购商品
        if (!checkEmpty(res.limit, true)) {
            limits.value = res.limit
            // 计算开始倒计时
            interval.value = setInterval(() => {
                Object.keys(limits.value).forEach(key => {
                    limits.value[key].countdown = setInterval(() => {
                        limits.value[key].remainingTime = countdown(new Date(limits.value[key].released_at))
                    }, 1000)
                })
            }, 1000)
        }
    })

    // 清理定时器
    onUnmounted(() => {
        clearInterval(interval.value)
        limits.value.forEach(limit => {
            if (limit.countdown) {
                clearInterval(limit.countdown)
            }
        })
    })

    // 工具函数
    function countdown(deadline) {
        const difference = deadline.getTime() - new Date().getTime()
        if (difference <= 0) {
            return { days: '00', hours: '00', minutes: '00', seconds: '00' }
        }

        const days = Math.floor(difference / (1000 * 60 * 60 * 24))
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((difference % (1000 * 60)) / 1000)

        return {
            days: days < 10 ? '0' + days : days,
            hours: hours < 10 ? '0' + hours : hours,
            minutes: minutes < 10 ? '0' + minutes : minutes,
            seconds: seconds < 10 ? '0' + seconds : seconds
        }
    }
</script>

<template>
    <limitPlanCard :limitPlans="limits" v-if="!checkEmpty(limits, true)" />

    <div class="col-xxl-7 col-md-8 col-12 mx-auto me-0 mt-3">
        <div class="card h-100">
            <div class="card-header">
                <div class="d-flex justify-content-between">
                    <h5 class="mb-0">套餐选择</h5>

                    <span class="badge badge-primary mb-0" v-if="isMobile">选项可以左右滑动</span>
                </div>
            </div>

            <div class="card-body">
                <div class="row">
                    <div class="col-xl-9 col-lg-11 col-12">

                        <div class="row">
                            <div class="col-12">
                                <div class="form-group mb-0">
                                    <div class="d-flex flex-row">
                                        <span class="badge badge-primary mb-2">套餐等级</span>
                                        <span class="badge badge-light text-dark ms-5">-优先推荐购买进阶及以上</span>
                                    </div>

                                    <div class="hidden-scrollbar overflow-scroll">

                                        <div class="row mt-2 scrollArea">
                                            <div class="col-lg-3 col-sm-4 col-4" v-for="val in range.level" :key="val">
                                                <button class="btn btn-outline-secondary w-100 customField"
                                                    :class="{'planCustom-active': selectedParam.level === val}" @click="selectedParam.level = val" :disabled="!shopData">
                                                    <span class="text-sm">{{ ['基础', '进阶', '专业'][val - 1] }}</span>
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group mb-0 hidden-scrollbar overflow-scroll">
                                    <span class="badge badge-info mb-2">套餐周期</span>
                                    <div class="row mt-2 planButton">
                                        
                                        <div class="col-3" v-for="val in range.duration">
                                            <button class="btn btn-outline-secondary w-100 customField"
                                                :class="{'planCustom-active': selectedParam.duration === val}" @click="selectedParam.duration = val" :disabled="!shopData">
                                                <span class="text-sm">
                                                    {{ { '30': '月付', '90': '季付', '180': '半年', '360': '年付' }[val] }} ({{ val }} 天)
                                                </span>
                                            </button>
                                        </div>
            
                                    </div>
                                </div>
                            </div>

                            <div class="col-12" v-show="active.traffic">
                                <div class="form-group mb-0 hidden-scrollbar overflow-scroll">
                                    <span class="badge badge-danger mb-2">套餐流量</span>
                                    <div class="row mt-2 planButton w-100">
                                        
                                        <!-- 流量自选 -->
                                        <!-- <div class="col-12 col-lg-3 col-sm-4" v-for="val in range.traffic">
                                            <button class="btn btn-outline-secondary w-100 px-3" :class="{'planCustom-active': traffic === val}" @click="traffic = val">
                                                <span class="text-sm">{{ val }} GB</span>
                                            </button>
                                        </div> -->

                                        <div class="col-12 col-lg-3 col-sm-4">
                                            <button class="btn btn-outline-secondary w-100 customField planCustom-active">
                                                <span class="text-sm">{{ selectedParam.traffic }} GB</span>
                                            </button>
                                        </div>
            
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <hr class="horizontal-dark my-2">
                            </div>

                            <div class="col-12">
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <div class="form-group mb-0">
                                            <div>
                                                <span class="badge badge-secondary mb-0">自动续费</span>
                                            </div>
                                            <span class="badge badge-light text-dark m-0 ps-1">• 到期后自动续费套餐，部分情况下价格继承</span>
                                            
                                            <div class="row mt-2">
                                                <div class="col-6">
                                                    <button class="btn btn-outline-secondary w-100" 
                                                            :class="{'planCustom-active': selectedParam.autoRenew}"
                                                            @click="selectedParam.autoRenew = true">
                                                        <span class="text-sm">开启</span>
                                                    </button>
                                                </div>

                                                <div class="col-6">
                                                    <button class="btn btn-outline-secondary w-100" 
                                                            :class="{'planCustom-active': !selectedParam.autoRenew}"
                                                            @click="selectedParam.autoRenew = false">
                                                        <span class="text-sm">关闭</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-12" v-if="activePlan">
                                        <div class="form-group mb-0">
                                            <div>
                                                <span class="badge badge-secondary mb-0">合并套餐</span>
                                            </div>
                                            <span class="badge badge-light text-dark m-0 ps-1">• 新套餐将与当前套餐合并</span>

                                            <div class="row mt-2">
                                                <div class="col-6">
                                                    <button class="btn btn-outline-secondary w-100" :class="{'planCustom-active': selectedParam.mergePlan}" @click="selectedParam.mergePlan = true">
                                                        <span class="text-sm">开启</span>
                                                    </button>
                                                </div>

                                                <div class="col-6">
                                                    <button class="btn btn-outline-secondary w-100" :class="{'planCustom-active': !selectedParam.mergePlan}" @click="selectedParam.mergePlan = false">
                                                        <span class="text-sm">关闭</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="col-md-6 col-12 d-none">
                                        <div class="form-group mb-0">
                                            <span class="badge badge-primary mb-2">超选节点（未启用）</span>
                                            <span class="badge badge-light text-dark m-0 ps-1">• 额外获取其他组别节点的使用权限</span>

                                            <div class="row mt-2 planButton">
                                                <div class="col-6">
                                                    <button class="btn btn-outline-secondary w-100" :class="{'planCustom-active': active['beyondGroup'] === 1}" @click="active['beyondGroup'] = 1" disabled>
                                                        <span class="text-sm">开启</span>
                                                    </button>
                                                </div>
        
                                                <div class="col-6">
                                                    <button class="btn btn-outline-secondary w-100" :class="{'planCustom-active': active['beyondGroup'] === 0}" @click="active['beyondGroup'] = 0" disabled>
                                                        <span class="text-sm">关闭</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>

                            </div>

                        </div>

                    </div>

                    <div class="col-12 detail-padding">
                        <details class="detail-desc border-secondary">
                            <summary>点击此处查看特殊说明（购买套餐默认同意以下条款）</summary>
                            <div class="row mt-3">
                                <div class="col-md-6 col-12">
                                    <div class="d-flex pb-3">
                                        <div class="ps-1 pt-1">
                                            <i class="ni ni-bold-right text-secondary text-lg" aria-hidden="true"></i>
                                        </div>
                                        <div class="ps-2">
                                            <span class="text-sm">注意，本站所有套餐及增值项均不含人工支持，请优先查阅帮助文档。</span>
                                        </div>
                                    </div>
            
                                    <div class="d-flex pb-3">
                                        <div class="ps-1 pt-1">
                                            <i class="ni ni-bold-right text-secondary text-lg" aria-hidden="true"></i>
                                        </div>
                                        <div class="ps-2">
                                            <span class="text-sm text-danger">如已有套餐，请在购买后前往"可用套餐"操作处理</span>
                                        </div>
                                    </div>
                                </div>
            
                                <div class="col-md-6 col-12">
                                    <div class="d-flex pb-3">
                                        <div class="ps-1 pt-1">
                                            <i class="ni ni-bold-right text-secondary text-lg" aria-hidden="true"></i>
                                        </div>
                                        <div class="ps-2">
                                            <span class="text-sm">如无特殊说明，因用户方操作失误导致的损失不予补偿及退款。</span>
                                        </div>
                                    </div>
            
                                    <div class="d-flex pb-3">
                                        <div class="ps-1 pt-1">
                                            <i class="ni ni-bold-right text-secondary text-lg" aria-hidden="true"></i>
                                        </div>
                                        <div class="ps-2">
                                            <span class="text-sm">购买任意套餐及增值项，均视为同意并愿意遵守本站服务条款。</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </details>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="col-xxl-3 col-md-4 col-12 mx-auto ms-0 mt-3">
        <div class="row">

            <div class="col-12">
                <div class="card">
                    <div class="card-header pb-1">
                        <h5 class="mb-0">套餐信息</h5>
                    </div>

                    <div class="card-body pt-0">
                        <ul class="list-group">
                            <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg" v-for="item in descItems">
                                <div class="d-flex flex-column text-dark">
                                    <h6 class="mb-1 fw-bold text-sm">{{ item.name }}</h6>
                                    <span class="text-xs fw-bolder">{{ item.desc }}</span>
                                </div>
                                <div class="d-flex align-items-center">
                                    <span class="text-lg fw-bolder text-dark">{{ item.value }}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-12 mt-4">
                <div class="card">
                    <div class="card-header">
                        <h5 class="mb-0">计价板</h5>
                    </div>

                    <div class="card-body pt-0">
                        <ul class="list-group">
                            <li class="list-group-item border-0 d-flex justify-content-between ps-0 pe-0 mb-2 border-radius-lg" v-for="item in priceboard">
                                <div class="d-flex flex-column" :class="{ 'ps-3': !item?.start && !item?.end }">
                                    <h6 class="mb-1 text-dark font-weight-bold text-sm">{{ item.title }}</h6>
                                    <span class="text-xs">{{ item.sub }}</span>
                                </div>
                                <div class="d-flex align-items-center text-sm">
                                    <span class="text-lg fw-bolder text-dark" v-if="!priceLoading">
                                        {{ item.prefix }} 
                                        {{ item.val }} CNY
                                    </span>
                                    <div class="text-center" v-else>
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>

                        <hr class="horizontal-dark my-3" />

                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <div class="d-flex justify-content-between text-end">
                                        <label class="form-control-label">优惠码</label>
                                        <label class="form-control-label">使用优惠请应用后下单</label>
                                    </div>
                                    <div class="input-group">
                                        <button class="btn btn-outline-primary mb-0" type="button" :disabled="!planSelected" @click="applyDiscount" >应用</button>
                                        <input type="text" class="form-control ps-3" placeholder="在此输入优惠码" v-model="active.discount">
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 text-end">
                                <button class="btn btn-outline-secondary text-dark w-100 w-md-auto mb-0" type="button" disabled v-if="!planSelected">等待选择</button>
                                <button class="btn btn-primary w-100 w-md-auto mb-0" type="button" @click="orderPlan" v-else>确认下单</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .customField {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
        white-space: nowrap;
    }

    .planCustom-active {
        border-color: #2643e9 !important;
        color: #2643e9 !important;
        background-color: #e8f0fe !important;
        filter: grayscale(20%);
        box-shadow: 0px 0px 10px 5px #e8f0fe !important;
    }

    .planButton>div>button {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
        white-space: nowrap;
    }

    @media (max-width: 768px) {
        .scrollArea {
            width: max-content;
        }

        .scrollArea.unset {
            width: unset;
        }

        .planButton {
            width: max-content;
        }

        .planButton.unset {
            width: unset;
        }

        .customField {
            min-width: 7.25rem;
        }
    }

    .detail-desc {
        border: 1px solid transparent;
        border-radius: 10px;
        padding: 16px 16px 8px;
        line-height: 20px;
        border-color: #e8e8e8;
        background-color: #fff;
    }

    details>summary {
        font-weight: 700;
        cursor: pointer;
        margin: 0 0 8px;
    }

    .detail-padding {
        padding-top: 45px;
    }

    /* dark theme */
    @media (prefers-color-scheme: dark) {
        .planCustom-active {
            background-color: #353535 !important;
            box-shadow: 0px 0px 10px 5px #1E1E1E !important;
            color: #fff !important;
            border-color: #2643e9 !important;
        }

        .detail-desc {
            background-color: #1E1E1E;
            border-color: rgba(255, 255, 255, 0.5);
        }
    }
</style>