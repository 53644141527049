<script setup>
    import axios from '@/Plugins/axios';
</script>

<template>
    <div class="card border-0 mb-0 authCard">
        <div class="card-header bg-transparent text-center">
            <div v-if="passQuestion">
                <h5 class="text-dark text-center mt-2 mb-3">注册账号</h5>
                <span class="mb-0 text-sm">请勿使用临时邮箱，如为特殊情况可工单申请</span>
            </div>

            <div v-else>
                <h5 class="text-dark text-center mt-2 mb-3">请按照提示输入问题答案</h5>
                <span class="mb-0 text-sm text-dark text-bold" v-html="questionTips"></span>
            </div>
            
        </div>

        <div class="card-body px-lg-5 py-0">
            <form role="form" v-if="passQuestion">
                <div class="row">
                    <div class="col-12 mb-3">
                        <label>用户名</label>
                        <input type="text" class="form-control" placeholder="用户名" aria-label="Name" autocomplete="off" v-model="username">
                    </div>

                    <div class="col-12 mb-3">
                        <label>注册邮箱</label>
                        <input type="email" class="form-control" placeholder="注册邮箱" aria-label="Email" autocomplete="off" v-model="email">
                    </div>

                    <div class="col-12 mb-3">
                        <div class="row">
                            <div class="col-6">
                                <input type="text" class="form-control" placeholder="验证码" aria-label="Code" autocomplete="off" v-model="verifyCode">
                            </div>
                            <div class="col-6">
                                <button class="btn btn-primary text-xs w-100 mb-0 h-100" type="button" @click="sendVerifyCode" v-if="countDown === 0">发送验证码</button>
                                <button class="btn btn-primary text-xs w-100 mb-0 h-100" type="button" v-else>剩余 {{ countDown }}s</button>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mb-3">
                        <div class="row">
                            <div class="col-6">
                                <label>密码</label>
                                <input type="password" class="form-control" placeholder="密码" aria-label="Password" autocomplete="off" v-model="password">
                            </div>
                            <div class="col-6">
                                <label>确认密码</label>
                                <input type="password" class="form-control" placeholder="确认密码" aria-label="Password" autocomplete="off" v-model="confirmPassword">
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <label>邀请码（可选）</label>
                        <input type="text" class="form-control" placeholder="邀请码" autocomplete="off" v-model="referral">
                    </div>

                    <div class="col-12">
                        <div class="form-check form-check-info text-start">
                            <input class="form-check-input" type="checkbox" autocomplete="off" v-model="agreeTos">
                            <label class="form-check-label">
                                我同意遵守 <a href="/tos" target="_blank" class="text-dark font-weight-bolder">服务条例</a>
                            </label>
                        </div>

                        <div class="text-center">
                            <button type="button" class="btn bg-gradient-warning w-100 my-4 mb-2" v-if="(!username || !email || !password || !confirmPassword)">请填写基础信息</button>
                            <button type="button" class="btn bg-gradient-info w-100 my-4 mb-2" v-else-if="!verifyCode">请完成邮箱验证</button>
                            <button type="button" class="btn bg-gradient-info w-100 my-4 mb-2" v-else-if="!agreeTos">请同意服务条例</button>
                            <button type="button" class="btn bg-gradient-primary w-100 my-4 mb-2"  @click="register" v-else>确认注册</button>
                        </div>
                    </div>
                </div>
            </form>

            <div v-else>
                <div class="border-dashed border-1 border-secondary border-radius-md p-3">
                    <span class="text-dark text-bolder">{{ question ?? '加载中' }}</span>
                </div>

                <div class="form-group mt-3">
                    <input class="form-control mb-2" type="text" placeholder="请输入答案" autocomplete="off" v-model="questionAnswer">
                    <small class="text-dark fw-bold mt-3">提示，仅接受数字，公元前时间加 - 号</small>
                </div>

                <hr class="bg-dark mt-1">

                <button class="btn btn-primary my-0 w-100" @click="validQuestion">验证答案</button>
            </div>
        </div>

        <div class="card-footer px-lg-5 pt-0">
            <p class="text-sm text-white mt-3 mb-0">已有本站账号?
                <a class="text-dark font-weight-bolder ps-2 custom-button cursor-pointer" @click="$emit('back', 'login')">
                    返回登录
                </a>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'register',
    props: {
        preReferral: {
            type: String,
            default: '',
        },
    },
    emits: ['registerSuccess'],
    data() {
        return {
            username: '',
            email: '',
            password: '',
            confirmPassword: '',
            verifyCode: '',
            verifyToken: '',

            agreeTos: false,
            referral: this.preReferral,
            
            question:       null,
            questionID:     0,
            questionTips:   '加载中...',
            passQuestion:   false,
            questionAnswer: '',

            countDown: 0,
        };
    },
    mounted() {
        this.getQuestion();
    },
    methods: {
        validEmail(val) {
            return String(val)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        },
        register(trigger) {
            // password and confirm password must be same
            if (this.password !== this.confirmPassword) {
                this.$showToast('操作失败', '两次输入的密码不一致', 'error');
                return;
            }

            // password must be longer than 8 and shorter than 32
            if (this.password.length < 8 || this.password.length > 32) {
                this.$showToast('操作失败', '密码长度必须在 8 - 32 位之间', 'error');
                return;
            }

            // email format check
            if (!this.validEmail(this.email)) {
                this.$showToast('操作失败', '邮箱格式不正确', 'error');
                return;
            }

            // verify code must be 6 digits 
            if (this.verifyCode.length !== 6) {
                this.$showToast('操作失败', '验证码必须为 6 位数字', 'error');
                return;
            }

            trigger.target.disabled = true;
                
            axios.post(this.$getApiURL('register'), {
                username:               this.username,
                email:                  this.email,
                password:               this.password,
                password_confirmation:  this.confirmPassword,
                emailCode:              this.verifyCode,
                verifyToken:            this.verifyToken,
                invite_code:            this.referral,
            })
            .then(res => {
                if (res.data?.pass === false) {
                    this.$showToast('操作失败', '验证失败，请重新完成验证', 'danger');
                    this.passQuestion = false;
                    this.verifyToken  = '';
                    sessionStorage.removeItem('verifyQuestionToken');
                    return;
                }

                if (typeof gtag !== 'undefined') {
                    gtag('event', 'sign_up', { method: 'email' });
                }

                this.$showToast('操作成功', '注册成功，正在返回登录', 'success');

                this.$emit('registerSuccess', { 'email': this.email, 'password': this.password });
            })
            .catch(err => { console.log(err); })
            .finally(() => { 
                setTimeout(() => { trigger.target.disabled = false; }, 450);
            });
        },
        sendVerifyCode(trigger) {
            axios.post(this.$getApiURL('sendVerifyCode'), {
                email: this.email,
            })
            .then(res => {
                this.$showToast('操作成功', '验证码发送成功', 'success');

                // 60s 倒计时
                this.countDown = 60;
                trigger.target.disabled = true;
                trigger.target.innerText = `${this.countDown}s`;
            })
            .catch(err => { console.error(err); })
        },
        validQuestion(trigger) {
            trigger.target.disabled = true;

            axios.post(this.$getApiURL('verifyQuestion'), {
                id:     this.questionID,
                answer: this.questionAnswer,
            })
            .then(res => {
                this.$showToast('操作成功', '验证成功', 'success');
                this.passQuestion = true;
                
                sessionStorage.setItem('verifyQuestionToken', res.data.token);
                this.verifyToken = res.data.token;
            })
            .catch(err => { console.error(err); })
            .finally(() => { 
                setTimeout(() => { trigger.target.disabled = false; }, 450);
            });
        },
        getQuestion() {
            axios.get(this.$getApiURL('verifyQuestion'), {
                params: {
                    token: sessionStorage.getItem('verifyQuestionToken'),
                }
            })
            .then(res => {
                if (res.data.pass === true) {
                    this.$showToast('操作成功', '已存在验证信息，无需再次验证', 'success');
                    this.passQuestion = true;
                    this.verifyToken = sessionStorage.getItem('verifyQuestionToken');
                    return;
                }

                const tip = [
                    '请回答输入提及事件发生<code class="text-lg text-bolder">年份</code>',
                    '请回答提及人物出生<code class="text-lg text-bolder">年份</code>',
                    '请回答提及人物逝世<code class="text-lg text-bolder">年份</code>',
                ];
                
                this.question     = res.data.question;
                this.questionTips = tip[res.data.type];
                this.questionID   = res.headers['x-question-id'];
            })
            .catch(err => { this.$showToast('操作失败', err.message, 'error'); });
        },
    },
    watch: {
        questionAnswer(val) {
            if (val && !/^[0-9\-]+$/.test(val)) {
                this.$showToast('操作失败', '答案仅接受数字，公元前时间加 - 号', 'error');
                this.questionAnswer = '';
            }
        },
        username(val) {
            // 允许 大小写字母、数字、下划线、中文
            // 不允许 空格、除下划线以外的特殊字符
            if (val && !/^[a-zA-Z0-9_\u4e00-\u9fa5]+$/.test(val)) {
                this.$showToast('操作失败', '用户名仅允许大小写字母、数字、下划线、中文', 'error');
                this.username = '';
            }
        },
        countDown(val) {
            if (val > 0) setTimeout(() => { this.countDown--; }, 1000);
        },
    },
}
</script>