import { createApp }  from 'vue'
import App            from './App.vue'
import router         from './router'
import store          from './store'

// * Plugins
import "vue-toastification/dist/index.css";
import '@/assets/css/libs/simple-datatables.css';
import '@/assets/css/libs/slimselect.css';

// ? Argon Dashboard
import '@/assets/css/nucleo-icons.css';
import '@/assets/css/nucleo-svg.css';
import '@/assets/css/argon-dashboard.min.css';

// ? Custom
import '@/assets/css/dark-theme.css';
// import '@/assets/css/argon-dark-prefer.css';
import '@/assets/css/custom.css';
import '@/assets/css/libs/loader.css';
import '@/assets/css/libs/transition.css';

// * Libraries
import * as bootstrap from 'bootstrap'
import SlimSelect     from '@slim-select/vue'
import Toast          from "vue-toastification";
import { createVfm }  from 'vue-final-modal'
import 'vue-final-modal/style.css';
import * as Sentry from "@sentry/vue";

// * Self Libraries
// import '@/assets/js/argon-dashboard.js';
import { useShowToast }     from '@/composables/useShowToast';
import { useApiURL }        from '@/composables/useApiURL';

const app = createApp(App)

// register global properties
app.config.globalProperties.$showToast     = useShowToast().showToast;
app.config.globalProperties.$getApiURL     = useApiURL().getApiURL;

// app.component('v-select', vSelect)
app.component('SlimSelect', SlimSelect)

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        app,
        dsn: "https://93861908fc15d494743adb3fdb84f42b@o4505184843071488.ingest.us.sentry.io/4508561311989760",
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration(),
        ],

        // Tracing
        tracesSampleRate: 1.0,
        tracePropagationTargets: ["localhost", /^https:\/\/api\.coolti\.top\/api/],

        // Session Replay
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,

        ignoreErrors: [
            '401 Unauthorized',
            'Network Error',
            'AxiosError: Network Error',
            'The string did not match the expected pattern.',
            'AxiosError: Request aborted',
            'Method not found',
        ]
    });
}

app.use(bootstrap);
app.use(router);
app.use(store);
app.use(Toast, {
    position: "top-right",
    timeout: 6000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: false,
    // draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false,

    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true
});
app.use(createVfm());

app.mount('#app')

// const theme = window.matchMedia?.("(prefers-color-scheme: dark)").matches ? "dark" : "light"

// if (theme === "dark") {
//     await import('@/assets/css/dark-theme.css');
// }

// * LocalStorage 存在 Token 时, 将 Token 设置至 Vuex 和 Axios Header
if (localStorage.getItem('token')) {
    store.commit('setToken', localStorage.getItem('token'));
}