<script setup>
    import QrcodeVue        from 'qrcode.vue'
    import { Modal }        from 'bootstrap';

    import { useUserApi }   from '@/composables/useUserApi';
    import { useCache }     from '@/composables/useCache';
    import { useCheckEmpty } from '@/composables/useCheckEmpty';
</script>

<template>
    <div class="modal fade" tabindex="-1" aria-hidden="true" role="dialog" ref="modal">
        <div class="modal-dialog modal-md modal-dialog-centered" role="document" :class="{'shake': shake}">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">订单处理中...</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="border-dashed border-1 border-secondary border-radius-md text-center py-3">

                        <div v-if="type === 'invoice' && !isComplete">
                            <div v-if="url">
                                <p class="mb-3 fs-5 text-bolder text-dark text-center">如支付跳转失败，请手动点击下方按钮</p>
                                <a class="btn btn-sm btn-primary" :href="url" target="_blank">点击手动跳转支付</a>
                                <hr class="my-3 horizontal dark">
                            </div>

                            <div v-if="qrcode">
                                <qrcode-vue 
                                    :value="qrcode"
                                    :size="256"
                                    level="L"
                                    :render-as="'svg'"
                                />
                                <!-- <img :src="QRCodeImg" alt="QRCode" class="img-fluid" /> -->
                                <hr class="my-3 horizontal dark">
                                <p class="mb-3 fs-5 text-bolder text-dark text-center">请使用对应的支付方式扫描二维码完成支付</p>
                                <p class="mb-3 fs-6 text-bolder text-dark text-center">如为微信支付且扫描显示不支持从截图中识别二维码，请将下方链接发送至任一会话（文件传输助手）中打开</p>
                                <p class="mb-3 fs-6 text-bolder text-dark text-center">{{ qrcode }}</p>
                            </div>
                        </div>

                        <hr class="my-3 horizontal dark">

                        <h4 class="font-weight-bolder">
                            <div class="row mt-4" v-if="checking">
                                <div class="col-12 d-flex justify-content-center align-items-center mb-0" style="scale: 125%;">
                                    <div class="spinner-border text-primary" role="status"></div>
                                    <span class="ms-3 text-dark text-bold">等待中...</span>
                                </div>

                                <div class="col-12 pt-4 mb-0">
                                    <p class="mb-3 fs-5 text-bolder text-dark text-center">{{ noticeText[type] }}</p>
                                </div>

                                <div class="col-12 mb-0">
                                    <p class="mb-3 text-bolder text-dark text-center">如长时间等待可尝试刷新页面检查订单是否已完成</p>
                                </div>
                            </div>

                            <div class="row my-3" v-else>
                                <div class="col-12 d-flex justify-content-center align-items-center">
                                    <span class="ms-3 fs-3 text-dark text-bold">订单已完成</span>
                                </div>
                            </div>
                        </h4>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const { getDetailData } = useUserApi();

export default {
    name: 'checkModal',
    props: {
        type: {
            type: String,
            default: 'invoice',
        },
        id: {
            type: Number,
            default: '',
        },
        url: {
            type: String,
            default: '',
        },
        qrcode: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            modal: null,
            shake: false,
            checking: true,
            isComplete: false,
            QRCodeImg: '',
            noticeText: {
                order: '等待订单处理完成中...',
                invoice: '等待账单支付完成中...',
            }
        }
    },
    mounted() {
        this.init();

        // if (!checkEmpty(this.qrcode)) {
        //     QRCode.toDataURL(this.qrcode)
        //     .then(url => {
        //         this.QRCodeImg = url
        //     })
        //     .catch(err => {
        //         console.error(err)
        //     })
        // }
    },
    beforeUnmount() {
        if (this.modal) this.modal.hide();
    },
    methods: {
        init() {
            const { checkEmpty } = useCheckEmpty();
            if (
                    [ 'invoice', 'order' ].indexOf(this.type) === -1 || 
                    checkEmpty(this.id) ||
                    // checkEmpty(this.url) && this.type === 'invoice' ||
                    (this.type === 'invoice' && (checkEmpty(this.url) && checkEmpty(this.qrcode)))
                ) {
                this.$showToast('操作失败', '返回值异常', 'error');
                return;
            }

            document.querySelectorAll('.modal').forEach((modal) => {
                const modalObj = Modal.getInstance(modal);
                if (modalObj) modalObj.hide();
            });

            // show modal
            this.modal = new Modal(this.$el);
            this.modal.show();

            const handle = { order: this.orderCheck, invoice: this.invoiceCheck, };

            handle[this.type]();
        },
        orderCheck(id = this.id, loop = 0) {
            getDetailData(id, 'order')
            .then(res => {
                // if status pending, wait 5s and check again
                if (res.data.status === 0) {
                    setTimeout(() => { this.orderCheck(id, loop + 1) }, 5000);
                    return;
                }

                if (res.status !== 'success') {
                    this.$showToast('操作失败', res.data.message, 'error');
                    return;
                }

                // * 效果
                this.shake = true;
                setTimeout(() => { this.shake = false }, 1000);
                window.navigator.vibrate ? navigator.vibrate([300,50, 300,50, 300,50]) : '';
                this.checking = false;
                this.noticeText['order'] = '订单已完成支付，正在刷新数据';

                const { refreshCache, deleteCache } = useCache();
                refreshCache('user');
                refreshCache('node');
                refreshCache('shop');
                deleteCache('subscribe');

                setTimeout(() => {
                    this.modal.hide();

                    this.$showToast('操作成功', '订单已完成', 'success');

                    setTimeout(() => { this.$router.go(0) }, 1500);
                }, 500);
            })
            .catch(err => { console.error(err); })
        },
        invoiceCheck(id = this.id, loop = 0) {
            getDetailData(id, 'invoice')
            .then(res => {
                // if status pending, wait 5s and check again
                if (res.data.status === 0) {
                    setTimeout(() => { this.invoiceCheck(id, loop + 1) }, 5000);
                    return;
                }

                if (res.status !== 'success') {
                    this.$showToast('操作失败', res.data.message, 'error');
                    return;
                }

                // * 效果
                this.shake = true;
                setTimeout(() => { this.shake = false }, 1000);
                window.navigator.vibrate ? navigator.vibrate([300,50, 300,50, 300,50]) : '';
                this.checking = false;
                this.noticeText['invoice'] = '账单已完成支付，获取订单进度中...';
                this.isComplete = true;

                setTimeout(() => {
                    if (res.data.order) {
                        this.noticeText['invoice'] = '订单正在处理，请稍作等待...';
                        this.checking = true;

                        this.orderCheck(res.data.order.id);
                    } else {
                        this.modal.hide();

                        setTimeout(() => { this.$router.go(0) }, 1500);
                    }
                }, 450);
            })
            .catch(err => { console.error(err); })
        },
    },
}
</script>