<template>
    <aside class="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4 hidden-scrollbar"
		:class="{'sidenav-hover': hover, 'd-none': hideSidebar}"
		@mouseenter="hover = true" @mouseleave="hover = false"
	>
		<div class="sidenav-header d-flex navbar-brand m-0">

		<span class="navbar-brand pt-0 mb-0 text-primary text-uppercase" style="font-size: 25px;">C o o l T i</span>

		</div>
		<hr class="horizontal dark mt-0">
		<div class="collapse navbar-collapse w-auto h-auto h-100 hidden-scrollbar">
			<ul class="navbar-nav">

				<li class="nav-item">
					<router-link :to="{ name: '用户中心' }" class="nav-link" exact>
						<div class="icon icon-shape icon-sm text-center me-2 d-flex align-items-center justify-content-center">
							<i class="ni ni-tv-2 text-primary text-sm"></i>
						</div>
						<span class="nav-link-text ms-1">用户中心</span>
					</router-link>
				</li>

				<li class="nav-item">
					<router-link :to="{ name: '节点中心' }" class="nav-link" exact>
						<div class="icon icon-shape icon-sm text-center me-2 d-flex align-items-center justify-content-center">
							<i class="ni ni-sound-wave text-red text-sm"></i>
						</div>
						<span class="nav-link-text ms-1">节点中心</span>
					</router-link>
				</li>

				<li class="nav-item">
					<router-link :to="{ name: '套餐中心' }" class="nav-link">
						<div class="icon icon-shape icon-sm text-center me-2 d-flex align-items-center justify-content-center">
						<i class="ni ni-planet text-blue text-sm"></i>
						</div>
						<span class="nav-link-text ms-1">套餐中心</span>
					</router-link>
				</li>

				<li class="nav-item">
					<router-link :to="{ name: '推广中心' }" class="nav-link" exact>
						<div class="icon icon-shape icon-sm text-center me-2 d-flex align-items-center justify-content-center">
							<i class="ni ni-user-run text-yellow text-sm"></i>
						</div>
						<span class="nav-link-text ms-1">推广中心</span>
					</router-link>
				</li>

				<li class="nav-item">
					<router-link :to="{ name: '订阅中心' }" class="nav-link" exact>
						<div class="icon icon-shape icon-sm text-center me-2 d-flex align-items-center justify-content-center">
							<i class="ni ni-map-big text-teal text-sm"></i>
						</div>
						<span class="nav-link-text ms-1">订阅中心</span>
					</router-link>
				</li>

				<li class="nav-item">
					<hr class="horizontal dark" />
				</li>

				<!-- <li class="nav-item">
					<router-link :to="{ name: '春节活动' }" class="nav-link" exact>
						<div class="icon icon-shape icon-sm text-center me-2 d-flex align-items-center justify-content-center">
							<i class="ni ni-istanbul text-danger text-sm shake"></i>
						</div>
						<span class="nav-link-text ms-1">春节活动</span>
					</router-link>
				</li>

				<li class="nav-item">
					<hr class="horizontal dark" />
				</li> -->

				<li class="nav-item">
					<router-link :to="{ name: '日志中心' }" class="nav-link" exact>
						<div class="icon icon-shape icon-sm text-center me-2 d-flex align-items-center justify-content-center">
							<i class="ni ni-tie-bow text-dark text-sm"></i>
						</div>
						<span class="nav-link-text ms-1">日志中心</span>
					</router-link>
				</li>

				<li class="nav-item">
					<router-link :to="{ name: '账户设置' }" class="nav-link" exact>
						<div class="icon icon-shape icon-sm text-center me-2 d-flex align-items-center justify-content-center">
							<i class="ni ni-ui-04 text-dark text-sm"></i>
						</div>
						<span class="nav-link-text ms-1">账户设置</span>
					</router-link>
				</li>

				<li class="nav-item">
					<router-link :to="{ name: '工单中心' }" class="nav-link" exact>
						<div class="icon icon-shape icon-sm text-center me-2 d-flex align-items-center justify-content-center">
							<i class="ni ni-favourite-28 text-dark text-sm"></i>
						</div>
						<span class="nav-link-text ms-1">工单中心</span>
					</router-link>
				</li>

			</ul>
		</div>
		<div class="sidenav-footer mx-3 my-3">
			<div class="card card-plain shadow-none">
				<img class="w-60 mx-auto icon-documentation-img" alt="sidebar_illustration">
				<div class="card-body text-center p-3 w-100 pt-0">
					<div class="docs-info">
						<h6 class="mb-0">碰到问题了?</h6>
						<p class="text-xs font-weight-bold">请优先查阅文档，而后工单</p>
						<a href="https://docs.wsl.icu" target="_blank" class="btn btn-primary btn-sm w-100 mb-0">点击查看</a>
					</div>
				</div>
			</div>
		</div>
	</aside>
</template>

<script>
export default {
    name: 'cooltiSidebar',
	props: {
		hideSidebar: {
			type: Boolean,
			default: false
		},	
	},
	data() {
		return {
			hover: false
		};
	},
}
</script>

<style>
.sidenav-hover>.sidenav-footer {
	display: inline-block !important;
}
</style>