import { useToast } from "vue-toastification";

export function useShowToast() {
    const showToast = (title, body, color = 'success', type = 'toast') => {
        switch (type) {
            case 'bootstrap':
                // ! For Some Reason, This Doesn't Work
                break;
            case 'toast':
                const toast = useToast();

                toast(body, {
                    type: color,
                    timeout: 5000,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                });
                break;
            
            default:
                break;
        }
    }

    return { showToast };
}