<script setup>
import { ref, onMounted, watch } from 'vue'
import { Tooltip } from 'bootstrap'

import { useUserApi } from '@/composables/useUserApi'
import { useShowToast } from '@/composables/useShowToast'

// 直接导入 SVG
import android from '@/assets/svg/android.svg'
import ios from '@/assets/svg/ios.svg'
import macos from '@/assets/svg/macos.svg'
import windows from '@/assets/svg/windows.svg'

// 视图状态定义
const VIEW = {
    WELCOME: 'welcome',
    PLATFORM: 'platform',
    CLIENT: 'client',
    SUBLINK: 'sublink',
    SUBLINKS: 'sublinks'
}

// API 方法解构
const {
    getSubData,
    getClientData
} = useUserApi()

const { showToast } = useShowToast()

// 状态管理
const state = ref({
    currentView: VIEW.PLATFORM,
    selectedPlatform: null,
    subLinks: {},
    clients: {},
    selectedClients: null,
    selectedSubLink: null,
    currentSubLink: '',
    tooltipList: []
})

// 平台数据配置
const platforms = [
    {
        name: 'iOS',
        svg: ios,
        desc: '适用于iPhone/iPad设备'
    },
    {
        name: 'Android',
        svg: android,
        desc: '适用于安卓手机/平板'
    },
    {
        name: 'Windows',
        svg: windows,
        desc: '适用于Windows电脑'
    },
    {
        name: 'macOS',
        svg: macos,
        desc: '适用于Mac电脑'
    }
]

// 初始化函数优化
async function initSubs() {
    try {
        const { data: { hosts, tokens, schemas } } = await getSubData()
        
        state.value.subLinks = schemas.reduce((acc, schema) => {
            acc[schema.name] = schema.strModel
                .replace('$domain$', hosts[0])
                .replace('$token$', tokens[0].token)
            return acc
        }, {})
    } catch (err) {
        console.error('Failed to initialize subscription data:', err)
    }
}

async function initClients() {
    try {
        state.value.clients = await getClientData()
    } catch (err) {
        console.error('Failed to initialize client data:', err)
    }
}

// 工具函数优化
function initTooltips() {
    setTimeout(() => {
        const tooltips = Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
            .map(el => new Tooltip(el))
        state.value.tooltipList = tooltips
    }, 150)
}

function disposeTooltips() {
    return new Promise(resolve => {
        state.value.tooltipList.forEach(tooltip => {
            tooltip.dispose()
            tooltip._activeTrigger = {};
            tooltip._element = document.createElement('noscript');
        })
        state.value.tooltipList = []
        // 给予足够的时间让 tooltip 完全销毁
        setTimeout(resolve, 100)
    })
}

// 视图切换逻辑优化
async function goToView(view, data = null) {
    // 等待 tooltips 销毁完成
    await disposeTooltips()
    
    state.value.currentView = view
    
    if (view === VIEW.CLIENT && data) {
        state.value.selectedPlatform = data
        initTooltips()
    } else if (view === VIEW.SUBLINK && data) {
        state.value.currentSubLink = data
    } else {
        initTooltips()
    }
}

// 复制功能优化
async function copySubLink(link) {
    try {
        await navigator.clipboard.writeText(link)
        showToast('操作成功', '成功复制配置链接')
    } catch (err) {
        console.error('Failed to copy:', err)
        goToView(VIEW.SUBLINK, link)
    }
}

// 导入链接生成优化
function generateImportUrl(clientName) {
    const client = state.value.clients.find(client => client.name === clientName)
    if (!client?.schema || !client.subType) return null
    
    const subLink = state.value.subLinks[client.subType]
    if (!subLink) return null
    
    return client.schema.replace('$URL', encodeURIComponent(subLink))
}

// 监听器优化
watch(() => state.value.selectedPlatform, (platform) => {
    if (!platform) return
    
    const platformName = platform === 'macOS' ? 'Mac' : platform
    state.value.selectedClients = Object.keys(state.value.clients)
        .filter(key => state.value.clients[key].platform === platformName)
})

onMounted(() => {
    Promise.all([initSubs(), initClients()])
})
</script>

<template>
    <div class="card">

        <!-- 二级：选择平台 -->
        <div class="card-body p-3" v-if="state.currentView === VIEW.PLATFORM">
            <div class="d-flex justify-content-between align-items-start mb-3">
                <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-0">
                    开始使用
                </h6>
                <button class="btn btn-sm btn-outline-secondary mb-0" @click="goToView(VIEW.SUBLINKS)">
                    <i class="fas fa-arrow-right me-1"></i>
                    选择订阅
                </button>
            </div>
            
            <div class="row g-3">
                <div class="col-6" v-for="platform in platforms" :key="platform.name">
                    <div class="card shadow-none border cursor-pointer h-100" 
                         @click="goToView(VIEW.CLIENT, platform.name)">
                        <div class="card-body p-3">
                            <div class="text-center">
                                <img class="mb-3" :src="platform.svg" height="32" width="32" alt="" />
                                <p class="text-xs text-secondary mb-0">{{ platform.desc }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 三级：选择客户端 -->
        <div class="card-body p-3 pb-0 expand" v-if="state.currentView === VIEW.CLIENT">
            <div class="d-flex justify-content-between align-items-start mb-3">
                <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-0">
                    选择客户端
                </h6>
                <button class="btn btn-sm btn-outline-secondary mb-0" @click="goToView(VIEW.PLATFORM)">
                    <i class="fas fa-arrow-left me-1"></i>
                    返回
                </button>
            </div>

            <div class="scrollable-content">
                <div class="row g-3">
                    <div class="col-12" v-for="clientName in state.selectedClients" :key="clientName">
                        <div class="card shadow-none border">
                            <div class="card-body p-3">
                                <div class="d-flex flex-column">
                                    <!-- 客户端信息 -->
                                    <div class="d-flex justify-content-between align-items-start">
                                        <div class="client-info">
                                            <h6 class="text-sm mb-1 text-truncate">{{ state.clients[clientName].name }}</h6>
                                            <p class="text-xs text-muted mb-0" v-if="state.clients[clientName].version">
                                                版本 {{ state.clients[clientName].version }}
                                            </p>
                                        </div>
                                        <!-- PC端按钮组 -->
                                        <div class="d-none d-md-flex gap-2">
                                            <a :href="generateImportUrl(state.clients[clientName].name)" target="_blank"
                                                class="btn btn-sm bg-gradient-info px-3 mb-0 btn-tooltip"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                title="一键导入"
                                                data-container="body"
                                                data-animation="true"
                                                v-if="state.clients[clientName]?.schema">
                                                <i class="fas fa-gear"></i>
                                            </a>
                                            <a :href="state.clients[clientName].url" target="_blank"
                                                class="btn btn-sm bg-gradient-primary px-3 mb-0 btn-tooltip"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                title="下载客户端"
                                                data-container="body"
                                                data-animation="true">
                                                <i class="fas fa-download"></i>
                                            </a>
                                            <button class="btn btn-sm bg-gradient-success px-3 mb-0 btn-tooltip"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                title="复制配置链接"
                                                data-container="body"
                                                data-animation="true"
                                                v-if="state.clients[clientName].subType && state.subLinks[state.clients[clientName].subType]"
                                                @click="copySubLink(state.subLinks[state.clients[clientName].subType])">
                                                <i class="fas fa-copy"></i>
                                            </button>
                                        </div>

                                        <div class="d-flex d-md-none gap-2">
                                            <a :href="state.clients[clientName].url" target="_blank"
                                                class="btn btn-xs btn-outline-dark px-3 mb-0"
                                                >
                                                <i class="fas fa-download"></i>
                                                下载
                                            </a>
                                        </div>
                                    </div>

                                    <!-- 移动端按钮组 -->
                                    <div class="d-md-none mt-3">
                                        <div class="row g-2">
                                            <div class="col-6 col-sm-4" v-if="state.clients[clientName]?.schema">
                                                <a :href="generateImportUrl(state.clients[clientName].name)" 
                                                    target="_blank"
                                                    class="mobile-action-btn">
                                                    <i class="fas fa-gear text-info fs-5 mb-2"></i>
                                                    <span class="text-xs text-secondary">一键导入</span>
                                                </a>
                                            </div>
                                            <div class="col-6 col-sm-4" v-if="state.clients[clientName].subType && state.subLinks[state.clients[clientName].subType]">
                                                <button class="mobile-action-btn"
                                                    @click="copySubLink(state.subLinks[state.clients[clientName].subType])">
                                                    <i class="fas fa-copy text-success fs-5 mb-2"></i>
                                                    <span class="text-xs text-secondary fw-normal">复制配置链接</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 订阅链接展示 -->
        <div class="card-body p-3 pb-0 expand" v-if="state.currentView === VIEW.SUBLINK">
            <div class="d-flex justify-content-between align-items-start mb-3">
                <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-0">
                    配置链接
                </h6>
                <button class="btn btn-sm btn-outline-secondary mb-0" @click="goToView(VIEW.CLIENT)">
                    <i class="fas fa-arrow-left me-1"></i>
                    返回
                </button>
            </div>

            <div class="alert py-2 alert-secondary text-white mb-3">
                <div class="d-flex align-items-center">
                    <i class="fas fa-info-circle me-2"></i>
                    <p class="text-sm mb-0">
                        请手动选择并复制以下配置链接
                    </p>
                </div>
            </div>

            <div class="form-group">
                <div class="input-group">
                    <input type="text" 
                           class="form-control"
                           :value="state.currentSubLink"
                           readonly
                           ref="linkInput">
                </div>
            </div>
        </div>

        <!-- 全部订阅链接 -->
        <div class="card-body p-3 pb-0 expand" v-if="state.currentView === VIEW.SUBLINKS">
            <div class="d-flex justify-content-between align-items-start mb-3">
                <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-0">
                    选择配置
                </h6>
                <button class="btn btn-sm btn-outline-secondary mb-0" @click="goToView(VIEW.PLATFORM)">
                    <i class="fas fa-arrow-left me-1"></i>
                    返回
                </button>
            </div>

            <div class="alert py-2 alert-secondary text-white mb-3">
                <div class="d-flex align-items-center">
                    <i class="fas fa-info-circle me-2"></i>
                    <p class="text-sm mb-0">
                        请根据您的客户端选择对应的配置链接
                    </p>
                </div>
            </div>

            <div class="scrollable-content">
                <div class="row g-3">
                    <div class="w-80">
                        <SlimSelect class="ms-1 me-2" v-model="state.selectedSubLink">
                            <option value="">请选择配置链接</option>
                            <option v-for="(link, type) in state.subLinks" :key="type" :value="link">
                                {{ type }}
                            </option>
                        </SlimSelect>
                    </div>

                    <!-- button copy -->
                    <div class="w-10 ms-1">
                        <button class="btn btn-sm bg-gradient-success px-3 mb-0 btn-tooltip"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="复制配置链接"
                                data-container="body"
                                data-animation="true"
                                @click="copySubLink(state.selectedSubLink)"
                                :disabled="!state.selectedSubLink">
                            <i class="fas fa-copy"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-footer p-3 pt-0" v-if="state.currentView !== VIEW.PLATFORM">
            <a href="https://docs.wsl.icu/getting-started"
               target="_blank"
               class="docs-link bg-gradient-info my-0">
                <i class="fas fa-book-open me-2"></i>
                查看详细使用教程
                <i class="fas fa-chevron-right ms-auto"></i>
            </a>
        </div>
        
    </div>
</template>

<style scoped>
.card:hover {
    border-color: #5e72e4 !important;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, .15);
}

.docs-link {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    color: white;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    transition: all 0.2s ease;
}

.docs-link:hover {
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(33, 82, 255, 0.3);
}

@media (max-width: 768px) {
    .d-flex.gap-2 {
        gap: 0.375rem !important;
    }
}

.scrollable-content {
    max-height: 250px;
    overflow-y: auto;
    margin: 0 -1rem;
    padding: 0 1rem;
    margin-bottom: 1.5rem;
}

/* 自定义滚动条样式 */
.scrollable-content::-webkit-scrollbar {
    width: 6px;
}

.scrollable-content::-webkit-scrollbar-track {
    background: transparent;
}

.scrollable-content::-webkit-scrollbar-thumb {
    background-color: #8392ab;
    border-radius: 3px;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
    background-color: #5e72e4;
}

/* Firefox滚动条样式 */
.scrollable-content {
    scrollbar-width: thin;
    scrollbar-color: #8392ab transparent;
}

/* 确保底部文档链接不被遮挡 */
.docs-link {
    position: relative;
    z-index: 1;
}

/* 移动端适配 */
@media (max-width: 768px) {
    .scrollable-content {
        max-height: 300px;
    }
}

.mobile-action-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.75rem 0.5rem;
    background: transparent;
    border: 1px solid #dee2e6;
    border-radius: 0.5rem;
    transition: all 0.2s ease;
}

.mobile-action-btn:hover {
    background: #f8f9fa;
    border-color: #5e72e4;
    text-decoration: none;
}

.mobile-action-btn:active {
    background: #e9ecef;
}

/* 确保按钮内的文字不会换行 */
.mobile-action-btn span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.client-info {
    min-width: 0;
    flex: 1;
    margin-right: 1rem;
}
</style>