<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import axios from '@/Plugins/axios'
import { useApiURL } from '@/composables/useApiURL'
import { useShowToast } from '@/composables/useShowToast'

const store = useStore()
const { getApiURL } = useApiURL()
const { showToast } = useShowToast()

const isLoading    = ref(false)
const isRefreshing = ref(false)

const handleCheckIn = async () => {
    if (isLoading.value) return
    
    isLoading.value = true
    try {
        const res = await axios.post(getApiURL('checkin'))
        showToast('操作成功', res.data.message)
        
        // 自动刷新用户数据
        await store.dispatch('getUserData')
    } catch (error) {
        if (error.response?.data?.message) {
            showToast('签到失败', error.response.data.message, 'error')
        } else {
            showToast('签到失败', '请稍后重试', 'error')
        }
        console.error(error)
    } finally {
        isLoading.value = false
    }
}

const refreshUserData = async () => {
    if (isRefreshing.value) return
    
    isRefreshing.value = true
    try {
        await store.dispatch('refreshUserData')
        showToast('刷新成功', '已更新用户数据')
    } catch (error) {
        showToast('刷新失败', '请稍后重试', 'error')
        console.error(error)
    } finally {
        isRefreshing.value = false
    }
}
</script>

<template>
    <div class="card">
        <div class="card-body p-3">
            <div class="row align-items-center">
                <div class="col-6">
                    <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-1">每日签到</h6>
                    <p class="text-sm mb-0">签到可获得免费流量</p>
                </div>
                <div class="col-6 text-end">
                    <div class="d-flex gap-2 justify-content-end">
                        <button class="btn btn-sm btn-outline-secondary action-btn" 
                                @click="refreshUserData"
                                :disabled="isRefreshing">
                            <div class="d-flex align-items-center justify-content-center">
                                <template v-if="!isRefreshing">
                                    <i class="fas fa-sync-alt me-1"></i>
                                    <span>刷新</span>
                                </template>
                                <template v-else>
                                    <span class="spinner-border spinner-border-sm me-1"></span>
                                    <span>刷新中</span>
                                </template>
                            </div>
                        </button>
                        <button class="btn btn-sm bg-gradient-success action-btn" 
                                @click="handleCheckIn"
                                :disabled="isLoading">
                            <div class="d-flex align-items-center justify-content-center">
                                <template v-if="!isLoading">
                                    <i class="fas fa-gift me-1"></i>
                                    <span>签到</span>
                                </template>
                                <template v-else>
                                    <div class="d-flex align-items-center">
                                        <span class="spinner-border spinner-border-sm me-1"></span>
                                        <span>签到中</span>
                                    </div>
                                </template>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.action-btn {
    height: 31px;
    padding: 0.25rem 0.75rem;
    margin-bottom: 0 !important;
    white-space: nowrap;
}

.action-btn .d-flex {
    height: 100%;
}

.action-btn:first-child {
    min-width: 72px;
}

.action-btn:last-child {
    min-width: 72px;
}

@media (max-width: 768px) {
    .d-flex.gap-2 {
        gap: 0.375rem !important;
    }
}
</style> 