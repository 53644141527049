<template>
    <div class="container py-5">
        <div class="hero text-center my-4">
            <h1 class="display-5">
                <!-- <i class="bi bi-emoji-dizzy text-danger mx-3"></i> -->
                <svg class="fill-danger" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="64" height="64" viewBox="0 0 48 48">
                    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" d="M35.4,38.8c-3.2,2.4-7.1,3.9-11.4,3.9C13.7,42.7,5.3,34.3,5.3,24c0-2.6,0.6-5.2,1.5-7.4"></path>
                    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" d="M12.1,9.6C15.3,7,19.5,5.3,24,5.3c10.3,0,18.7,8.4,18.7,18.7c0,2.3-0.4,4.5-1.2,6.6"></path>
                    <line fill="none" x1="31.1" x2="16.9" y1="16.9" y2="31.1" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3"></line>
                    <line fill="none" x1="31.1" x2="16.9" y1="31.1" y2="16.9" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3"></line>
                </svg>
            </h1>
            <h1 class="display-5 fw-bold">404 - Not Found</h1>
            <p class="lead">The requested resource could not be found but may be available in the future.</p>
            <p>
                <!-- <a href='aa' class="btn btn-outline-success btn-lg">返回</a> -->
                <router-link :to="{ name: '首页' }" class="btn btn-lg btn-outline-success mb-0">返 回 首 页</router-link>
            </p>
        </div>

        <div class="content">
            <div class="row  justify-content-center py-3">
                <div class="col-md-6">
                    <div class="my-5 p-5 card shadow-lg">
                        <h3>发生什么事了?</h3>
                        <p class="fs-5">
                            当前访问的页面不存在
                        </p>
                    </div>

                    <div class="my-5 p-5 card shadow-lg">
                        <h3>我可以做什么?</h3>
                        <p>点击返回按钮重试操作，或反馈此情况至 <a href="mailto:coolti_support@pm.me">coolti_support@pm.me</a>邮箱及其他SNS渠道</p>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Error404View",
    inject: ['SwitchFramework'],
    mounted() {
        this.SwitchFramework(true);
    },
    beforeUnmount() {
        this.SwitchFramework(false);
    },
}
</script>

<style scoped>
    .fill-danger {
        fill: #dc3545;
        stroke: #dc3545;
    }
</style>