<script setup>
    import { ref, onMounted } from 'vue';
    import { useRouter } from 'vue-router';

    import newModal from '@/components/newModal.vue';

    import { useCache }      from '@/composables/useCache';
    import { useApiURL }     from '@/composables/useApiURL'
    import { useShowToast }  from '@/composables/useShowToast';
    import axios             from '@/Plugins/axios'

    const { getApiURL } = useApiURL();
    const { showToast } = useShowToast();
    const router        = useRouter();
    const { storeCache, getCache } = useCache();

    const plans    = ref([]);
    const modal    = ref({ show: false, id: null, type: '', title: '', });

    onMounted(() => {
        getPlans();
    });


    const getPlans = async (refresh = false) => {
        if (!refresh && getCache('plans')) {
            plans.value = getCache('plans');
            return;
        }

        try {
            const res = await axios.get(getApiURL(`plan`));
            plans.value = res.data;

            // 缓存数据
            storeCache('plans', res.data, 60 * 60);
        } catch (err) {
            showToast('错误', err.response.data.message, 'error');
        }
    }

    const planOperate = (planID, type) => {
        console.log('data', planID, type);
        if (!['reset', 'apply', 'merge', 'refund', 'disableRenew'].includes(type)) {
            showToast('错误', '操作类型错误', 'error');
            return;
        }

        // base url = /api/plan
        // merge,refund,disableRenew,apply,reset
        // url = /api/plan/$id/$type
        let url = getApiURL('plan');
            url = `${url}/${planID}/${type}`;

        axios.post(url, {
            id: planID
        })
        .then(res => {
            showToast('成功', res.data.message, 'success');

            if (type === 'merge') {
                router.push({ name: '订单页', params: { id: res.data.order } });
            }

            setTimeout(() => {
                getPlans(true);
            }, 1200);
        })
        .catch(err => {console.log(err);})
        .finally(() => {
            if (modal.value.show) {
                modal.value.show = false;
            }
        });
    }

    const byteConvert = (bytes, max =  'GB') => {
        // 检查输入是否为数值并且非负数
        if (typeof bytes !== 'number' || isNaN(bytes) || bytes < 0) {
            return 'Invalid input';
        }
     
        // 如果字节数小于1024，直接返回带单位的字节数
        if (bytes < 1024) return bytes + ' B';

        // 单位列表
        let k = 1024;
        let sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        // 找到指定最大单位的索引
        let maxIndex = sizes.indexOf(max);
        if (maxIndex === -1) {
            return 'Invalid max unit';
        }
        
        // 根据字节数确定当前单位
        let i = Math.floor(Math.log(bytes) / Math.log(k));

        // 如果超过指定的最大单位，就把索引设置为最大单位
        if (i > maxIndex) {
            i = maxIndex;
        }

        // 计算转换后的值并格式化为三位小数
        let value = (bytes / Math.pow(k, i)).toFixed(0);

        // 返回带单位的结果
        return value + ' ' + sizes[i];
    }

    const getRenewCount = (plan) => {
        return plan.renew > 0 ? `剩余 ${plan.renew} 次` : '无法续费';
    }

    const getResetDays = (date) => {
        const now = new Date();
        const resetDate = new Date(date);
        const diffTime = Math.abs(now - resetDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    }
    
    const getLevelAlias = (level) => {
        const alias = {
            1: '基础',
            2: '进阶',
            3: '专业',
        };
        return alias[level] || '';
    }

    const openModal = (type, planID) => {
        modal.value.id = planID;
        modal.value.type = type;
        const titles = {
            'apply': '套餐应用确认',
            'refund': '套餐退款确认',
            'disableRenew': '套餐自动续费关闭确认',
            'merge': '套餐合并确认',
            'reset': '套餐付费重置确认',
        };
        modal.value.title = titles[type] || '';

        modal.value.show = true;
    }
</script>

<template>
    <div class="container-fluid">
        <!-- 空状态 -->
        <div class="row" v-if="plans.length === 0">
            <div class="col-12 text-center py-3" >
                <div class="empty-state">
                    <i class="fas fa-box-open fa-2x text-secondary mb-2"></i>
                    <p class="text-secondary mb-0">当前没有有效套餐</p>
                </div>
            </div>
        </div>

        <!-- 耗尽套餐 -->
        <div class="row mb-4" v-if="plans.filter(p => p.status === 3).length > 0">
            <div class="col-12 mt-4" v-if="plans.filter(p => p.status === 3).length > 0">
                <div class="d-flex align-items-center gap-2 mb-0">
                    <i class="fas fa-exclamation-circle text-warning"></i>
                    <span class="text-sm">流量耗尽套餐</span>
                    <span class="badge bg-secondary">{{ plans.filter(p => p.status === 3).length }}</span>
                </div>
            </div>

            <!-- 耗尽套餐 -->
            <div class="col-lg-4 col-md-6 col-12 g-3" 
                 v-for="plan in plans.filter(p => p.status === 3)" 
                 :key="plan.id">
                <div class="card">
                    <!-- 标签和操作 -->
                    <div class="card-header p-3 d-flex justify-content-between">
                        <div>
                            <span class="badge bg-primary" v-if="plan.active">当前</span>
                            <span class="text-xs text-secondary ms-1">#{{ plan.id }}</span>
                        </div>

                        <div class="dropdown">
                            <button class="btn btn-xs btn-outline-secondary dropdown-toggle mb-0" 
                                    type="button" 
                                    data-bs-toggle="dropdown" 
                                    aria-expanded="false">
                                操作菜单
                            </button>
                            <ul class="dropdown-menu">
                                <li v-if="!plan.active">
                                    <button class="dropdown-item" @click="openModal('merge', plan.id)">
                                        <i class="fas fa-code-merge me-2"></i>合并套餐
                                    </button>
                                </li>
                                <li v-if="plan.renew !== 0">
                                    <button class="dropdown-item text-warning" @click="openModal('renew', plan.id)">
                                        <i class="fas fa-times me-2"></i>关闭自动续费
                                    </button>
                                </li>
                                <li>
                                    <button class="dropdown-item text-primary" @click="openModal('reset', plan.id)">
                                        <i class="fas fa-sync me-2"></i>付费重置
                                    </button>
                                </li>
                            </ul>
                        </div>    
                    </div>

                    <!-- 主要信息 -->
                    <div class="card-body p-3 pt-0">
                        <div class="d-flex justify-content-between align-items-baseline">
                            <div>
                                <h5 class="mb-0">
                                    Lv.{{ plan.level }}
                                    <span class="text-xs text-secondary">({{ getLevelAlias(plan.level) }})</span>
                                </h5>
                                <span class="text-xs text-secondary">套餐等级</span>
                            </div>
                            <h5 class="mb-0">
                                <small class="text-secondary me-1">￥</small>{{ plan.price }}
                            </h5>
                        </div>

                        <div class="mt-3">
                            <div class="d-flex justify-content-between text-xs mb-1">
                                <span>每月流量 {{ plan.total }}GB</span>
                                <span class="text-danger">已耗尽</span>
                            </div>
                            <div class="bg-light rounded p-2">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex align-items-center">
                                        <i class="fas fa-exclamation-circle text-warning me-2"></i>
                                        <span class="text-xs text-secondary">重置时间</span>
                                    </div>
                                    <span class="text-xs">{{ getResetDays(plan.reset_at) }} 天后重置</span>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between align-items-center mt-3">
                            <span class="badge" 
                                :class="[
                                        plan.renew > 0 ? 'bg-gradient-success' : 'bg-gradient-warning',
                                        { 'cursor-pointer': plan.renew !== 0 }
                                    ]">
                                {{ getRenewCount(plan) }}
                            </span>
                            <span class="text-xs text-secondary">{{ new Date(plan.expired_at).toLocaleDateString() }} 到期</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- 有效套餐 -->
        <div class="row" v-if="plans.filter(p => p.status !== 3).length > 0">
            <!-- 标题 -->
            <div class="col-12">
                <div class="d-flex align-items-center gap-2 mb-0">
                    <i class="fas fa-box-open me-2"></i>
                    <span class="text-sm">当前可用套餐</span>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 g-3 " 
                 v-for="plan in plans.filter(p => p.status !== 3)" 
                 :key="plan.id">
                <div class="card">
                    <!-- 标签和操作 -->
                    <div class="card-header p-3 d-flex justify-content-between">
                        <div>
                            <span class="badge bg-primary" v-if="plan.active">当前</span>
                            <span class="badge bg-danger ms-1" v-if="plan.isNew">新套餐</span>
                            <span class="text-xs text-secondary ms-1">#{{ plan.id }}</span>
                        </div>

                        <div class="dropdown">
                            <button class="btn btn-xs btn-outline-secondary dropdown-toggle mb-0" 
                                    type="button" 
                                    data-bs-toggle="dropdown" 
                                    aria-expanded="false">
                                操作菜单
                            </button>
                            <ul class="dropdown-menu">
                                <li v-if="!plan.active">
                                    <button class="dropdown-item text-primary" @click="openModal('apply', plan.id)">
                                        <i class="fas fa-check me-2"></i>应用此套餐
                                    </button>
                                </li>
                                <li v-if="!plan.active">
                                    <button class="dropdown-item" @click="openModal('merge', plan.id)">
                                        <i class="fas fa-code-merge me-2"></i>合并套餐
                                    </button>
                                </li>
                                <li v-if="plan.isNew">
                                    <button class="dropdown-item text-danger" @click="openModal('refund', plan.id)">
                                        <i class="fas fa-undo me-2"></i>退款套餐
                                    </button>
                                </li>
                                <li v-if="plan.renew !== 0">
                                    <button class="dropdown-item text-warning" @click="openModal('disableRenew', plan.id)">
                                        <i class="fas fa-times me-2"></i>关闭自动续费
                                    </button>
                                </li>
                                <li>
                                    <button class="dropdown-item text-primary" @click="openModal('reset', plan.id)">
                                        <i class="fas fa-sync me-2"></i>付费重置
                                    </button>
                                </li>
                            </ul>
                        </div>    
                    </div>

                    <!-- 主要信息 -->
                    <div class="card-body p-3 pt-0">
                        <div class="d-flex justify-content-between align-items-baseline">
                            <div>
                                <h5 class="mb-0">
                                    Lv.{{ plan.level }}
                                    <span class="text-xs text-secondary">({{ getLevelAlias(plan.level) }})</span>
                                </h5>
                                <span class="text-xs text-secondary">套餐等级</span>
                            </div>
                            <h5 class="mb-0">
                                <small class="text-secondary me-1">￥</small>{{ plan.price }}
                            </h5>
                        </div>

                        <div class="mt-3">
                            <div class="d-flex justify-content-between text-xs mb-1">
                                <span>每月流量 {{ plan.total }}GB</span>
                                <span>剩余 {{ byteConvert(plan.remain) }}</span>
                            </div>
                            <div class="progress">
                                <div class="progress-bar bg-gradient-info" 
                                     :style="`width: ${((plan.remain) / (plan.total * 1024 * 1024 * 1024)) * 100}%`">
                                </div>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between align-items-center mt-3">
                            <span class="badge" 
                                :class="[
                                        plan.renew > 0 ? 'bg-gradient-success' : 'bg-gradient-warning',
                                        { 'cursor-pointer': plan.renew !== 0 }
                                    ]">
                                {{ getRenewCount(plan) }}
                            </span>
                            <span class="text-xs text-secondary">{{ new Date(plan.expired_at).toLocaleDateString() }} 到期</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- Modal -->
        <newModal v-model="modal.show"  :title="modal.title" size="md">
            <div class="text-sm">
                <template v-if="modal.type === 'apply'">
                    <p class="mb-2">应用新套餐后，节点数据可能需要 5-15 分钟完成同步，请耐心等待</p>
                </template>

                <template v-if="modal.type === 'disableRenew'">
                    <p class="mb-2">关闭自动续费后，系统将不会在套餐到期时自动生成续费订单</p>
                    <p class="mb-0">请注意：此操作<code class="fs-6 text-bolder">不可撤销</code>，您需要手动购买新套餐</p>
                </template>

                <template v-else-if="modal.type === 'merge'">
                    <p class="mb-2">合并后，系统将按照购买价格和权重计算，将目标套餐剩余时长折算到当前套餐中</p>
                    <p class="mb-0">请注意：此操作<code class="fs-6 text-bolder">不可撤销</code>，套餐等级和流量不会变更</p>
                </template>

                <template v-else-if="modal.type === 'refund'">
                    <p class="mb-2">退款金额将优先退回至账户余额，如需退回原支付方式请提交工单</p>
                    <p class="mb-2">退款完成后，系统将自动应用最新购买的有效套餐</p>
                    <p class="mb-0">请注意：此操作<code class="fs-6 text-bolder">不可撤销</code>，套餐将立即失效</p>
                </template>

                <template v-else-if="modal.type === 'reset'">
                    <p class="mb-2">重置后，当前套餐已用流量将归零，不影响下次自动重置时间</p>
                    <p class="mb-2">费用为当前等级月付价格的 80%</p>
                    
                    <div class="bg-light rounded p-3 mt-3">
                        <div class="d-flex align-items-center mb-2">
                            <i class="fas fa-calculator text-primary me-2"></i>
                            <span class="text-dark">费用计算示例</span>
                        </div>
                        
                        <div class="d-flex justify-content-between align-items-center mb-2">
                            <span class="text-sm">Lv.1 套餐月付价格</span>
                            <span class="badge bg-secondary">¥13.00</span>
                        </div>
                        
                        <div class="d-flex justify-content-between align-items-center mb-2">
                            <span class="text-sm">重置折扣</span>
                            <span class="badge bg-info">80%</span>
                        </div>
                        
                        <hr class="horizontal dark my-2">
                        
                        <div class="d-flex justify-content-between align-items-center">
                            <span class="text-sm fw-bold">重置费用</span>
                            <span class="badge bg-gradient-primary">¥10.40</span>
                        </div>
                    </div>
                </template>
            </div>

            <template #buttons>
                <button class="btn btn-sm btn-primary mb-0 me-2" @click="planOperate(modal.id, modal.type)">
                    <i class="fas fa-check me-2"></i>
                    确认
                </button>
            </template>
        </newModal>
    </div>
</template>

<style scoped>
.card {
    transition: all 0.2s ease;
    border: 1px solid #e9ecef;
}

.card:hover {
    border-color: #5e72e4;
}

.empty-state {
    padding: 2rem;
    background: #f8f9fa;
    border-radius: 0.5rem;
}

/* if dropdown-menu not with show, display none */
.dropdown-menu {
    display: none !important;
}

.dropdown-menu.show {
    display: block !important;
}
</style>