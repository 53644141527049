<script setup>
	import { useThirdPartyReq } from '@/composables/useThirdPartyReq';
	import { ref, onMounted, onUnmounted, inject } from 'vue';

	const SwitchFramework = inject('SwitchFramework')
	const { getYiYan, getBingImage } = useThirdPartyReq();

	const hitokoto   = ref('Loading ...')
	const isMenuOpen = ref(false)
	const menuBtn    = ref(null)

	const getHitokoto = () => {
		getYiYan().then((result) => {
            hitokoto.value = result.hitokoto;
        })
		.catch((err) => {
			console.log(err);
		});
	}

	const handleClickOutside = (event) => {
		const target  = event.target;
		const sidebar = document.querySelector('.sidebar');
		if (isMenuOpen.value && !sidebar.contains(event.target) && target !== menuBtn.value) {
			isMenuOpen.value = false;
		}
	}

	const toggleMenu = () => {
		console.log(isMenuOpen.value);
		isMenuOpen.value = !isMenuOpen.value;
	}

	onMounted(() => {
		if (SwitchFramework) {
			SwitchFramework(true);
		}

		getHitokoto();
		getBingImage();

		document.addEventListener('click', handleClickOutside);
	})

	onUnmounted(() => {
		if (SwitchFramework) {
			SwitchFramework(false);
		}

		document.removeEventListener('click', handleClickOutside);
	})
</script>

<template>
	<div class="home-wrapper">
		<!-- 导航栏 -->
		<div class="coolti-index-header">
			<div class="container">
				<div class="row align-items-center">
					<div class="col-4 ps-md-0">
						<span class="text-primary fs-3 fw-bolder">CoolTi</span>
					</div>
					
					<div class="col-8 d-flex align-items-center justify-content-end">
						<!-- PC端导航 -->
						<div class="d-md-flex gap-2 d-none align-items-center">
							<a class="nav-link">首页</a>
							<router-link :to="{ name: '登录' }" class="nav-link">登录</router-link>
							<a class="nav-link" href="https://docs.wsl.icu/tos" target="_blank">服务条例</a>
							<a class="nav-link" href="https://docs.wsl.icu/" target="_blank">文档中心</a>
						</div>

						<!-- 移动端导航 -->
						<div class="d-flex gap-2 d-md-none align-items-center">
							<button class="btn btn-primary btn-sm nav-toggle" ref="menuBtn" @click="toggleMenu">
								<i class="ni ni-active-40 me-2"></i>展开菜单
							</button>

							<!-- 移动端侧边栏 -->
							<div class="sidebar" :class="{ 'start-0': isMenuOpen }">
								<div class="sidebar-logo">
									<span class="text-start text-white fs-3 fw-bolder letter-spacing-4">CoolTi</span>
								</div>
								<div class="p-3">
									<router-link :to="{ name: '用户中心' }" class="menu-item">
										<img src="@/assets/svg/dashboard.svg">
										<div class="menu-title">
											用户中心
										</div>
									</router-link>
									<router-link :to="{ name: '登录' }" class="menu-item">
										<img src="@/assets/svg/login.svg">
										<div class="menu-title">
											登录
										</div>
									</router-link>
									<router-link :to="{ name: '登录' }" class="menu-item">
										<img src="@/assets/svg/register.svg">
										<div class="menu-title">
											注册
										</div>
									</router-link>
									<a class="menu-item" href="https://docs.wsl.icu/tos" target="_blank">
										<img src="@/assets/svg/tos.svg">
										<div class="menu-title">
											服务条例
										</div>
									</a>
									<a class="menu-item" href="https://docs.wsl.icu/" target="_blank">
										<img src="@/assets/svg/help.svg">
										<div class="menu-title">
											文档中心
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 主要内容区 -->
		<section class="hero-section">
			<div class="container position-relative">
				<div class="row align-items-center min-vh-80">
					<!-- 左侧内容 -->
					<div class="col-lg-6 col-md-7 hero-content">
						<h1 class="display-4 fw-bold text-dark mb-3">
							高速流量重定向服务
						</h1>
						<p class="text-muted mb-4 hitokoto-text">
							<span class="text-sm me-2">_></span>
							{{ hitokoto }}
						</p>
						<div class="hero-buttons">
							<router-link :to="{ name: '登录' }" class="btn btn-primary me-3">
								<i class="ni ni-controller me-2"></i>
								开始使用
							</router-link>
							<router-link :to="{ name: '登录' }" class="btn btn-outline-primary">
								<i class="ni ni-book-bookmark me-2"></i>
								了解更多
							</router-link>
						</div>
					</div>

					<!-- 右侧图片 -->
					<div class="col-lg-6 col-md-5 hero-image">
						<img 
							src="https://static.wsl.icu/2025/home-CvelMR0X.webp"
							alt="home"
							class="img-fluid floating-image"
							fetchpriority="high"
							width="600"
							height="573"
						/>
					</div>
				</div>
			</div>

			<!-- 背景动画 -->
			<ul class="animation-squares">
				<li v-for="n in 10" :key="n"></li>
			</ul>
		</section>
	</div>
</template>

<style scoped>
.home-wrapper {
	min-height: 100vh;
	position: relative;
	overflow: hidden;
}

.btn {
	border: 0 !important;
	padding: 6px 12px !important;
	margin-left: 4px !important;
	margin-bottom: 16px !important;

	font-size: 16px !important;
	font-weight: 400 !important;
	line-height: 24px !important;
	letter-spacing: -0.4px !important;

	text-decoration-color: rgb(255, 255, 255);
	text-decoration-style: solid;
	text-decoration-line: none;
	text-decoration-thickness: auto;

	opacity: 0;
	animation: fadeIn 0.5s ease forwards 0.6s;
}

.btn-primary {
	background-color: rgb(13, 110, 253) !important;
}

/* 导航栏样式 */
.coolti-index-header {
	background: rgba(255, 255, 255, 0.95);
	backdrop-filter: blur(10px);
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	z-index: 1030;
	position: relative;
}

/* @media (prefers-color-scheme: dark) {
	.coolti-index-header {
		background: #121212;
	}
} */

.nav-link {
	display: inline-block;
	font-weight: 600;
	font-size: 0.875rem;
	letter-spacing: 0.5px;
	padding: 0.5rem 1rem;
	color: var(--bs-gray-700);
	transition: color 0.2s ease;
	text-decoration: none;
}

.nav-link:hover {
	color: var(--bs-primary);
}

.nav-toggle {
	padding: 0.5rem 1rem;
	font-weight: 500;
}

/* 主要内容区 */
.hero-section {
	position: relative;
	min-height: calc(100vh - 70px);
	display: flex;
	align-items: center;
	padding: 4rem 0;
}

.hero-content {
	position: relative;
	z-index: 2;
}

.hitokoto-text {
	font-size: 1.1rem;
	line-height: 1.6;
	opacity: 0;
	animation: fadeIn 0.5s ease forwards 0.3s;
}

.hero-image {
	position: relative;
	z-index: 2;
	opacity: 0;
	animation: slideIn 0.8s ease forwards 0.3s;
}

.floating-image {
	animation: float 6s ease-in-out infinite;
	user-select: none;
	pointer-events: none;
}

/* 移动端侧边栏样式 */
.sidebar {
    position: fixed;
    top: 0;
    left: -200px; /* 初始位置在屏幕外 */
    width: 200px;
    height: 100vh;
    background: linear-gradient(135deg, #1e3c72 0%, #2a5298 100%);
    transition: left 0.3s ease;
    z-index: 1031; /* 确保在导航栏之上 */
}

.sidebar-logo {
    padding: 1.5rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.menu-item {
    display: flex;
    align-items: center;
    padding: 0.75rem;
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
    transition: all 0.2s ease;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
}

.menu-item:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
}

.menu-item img {
    width: 24px;
    height: 24px;
    margin-right: 1rem;
    opacity: 0.8;
}

.menu-title {
    font-size: 0.9rem;
    font-weight: 500;
}

/* 动画方块 */
.animation-squares {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 1;
	pointer-events: none;
	margin: 0;
	padding: 0;
}

.animation-squares li {
	position: absolute;
	display: block;
	width: 24px;
	height: 24px;
	bottom: -150px;
	background: rgba(47, 85, 212, 0.15);
	animation: animate linear infinite;
}

/* 动画关键帧 */
@keyframes float {
	0% { transform: translateY(0px); }
	50% { transform: translateY(-20px); }
	100% { transform: translateY(0px); }
}

@keyframes fadeIn {
	from { opacity: 0; transform: translateY(20px); }
	to { opacity: 1; transform: translateY(0); }
}

@keyframes slideIn {
	from { opacity: 0; transform: translateX(50px); }
	to { opacity: 1; transform: translateX(0); }
}

@keyframes animate {
	0% {
		transform: translateY(0) rotate(0deg);
		opacity: 1;
		border-radius: 10px;
	}
	100% {
		transform: translateY(-1000px) rotate(720deg);
		opacity: 0;
	}
}

/* 动画方块位置 */
.animation-squares li:nth-child(1) { left: 25%; animation-duration: 25s; }
.animation-squares li:nth-child(2) { left: 10%; animation-duration: 12s; animation-delay: 2s; }
.animation-squares li:nth-child(3) { left: 70%; animation-duration: 25s; animation-delay: 4s; }
.animation-squares li:nth-child(4) { left: 40%; animation-duration: 18s; }
.animation-squares li:nth-child(5) { left: 65%; animation-duration: 25s; }
.animation-squares li:nth-child(6) { left: 75%; animation-duration: 25s; animation-delay: 3s; }
.animation-squares li:nth-child(7) { left: 35%; animation-duration: 25s; animation-delay: 7s; }
.animation-squares li:nth-child(8) { left: 50%; animation-duration: 45s; animation-delay: 15s; }
.animation-squares li:nth-child(9) { left: 20%; animation-duration: 35s; animation-delay: 2s; }
.animation-squares li:nth-child(10) { left: 85%; animation-duration: 11s; }

/* 响应式调整 */
@media (max-width: 768px) {
    .hero-section {
        padding: 1rem 0;
        min-height: calc(100vh - 60px);
    }

    .hero-content {
        text-align: start;
        padding: 2rem 0 1rem;
    }

    .hero-image {
        margin-top: 1rem;
        padding: 0 1rem;
    }

    .floating-image {
        max-width: 100%;
        height: auto;
        margin: 0 auto;
    }

    /* 调整标题和文字大小 */
    .display-4 {
        font-size: calc(1.2rem + 2vw);
        margin-bottom: 1rem;
    }

    .hitokoto-text {
        font-size: 0.95rem;
        padding: 0 1rem;
    }

    /* 导航栏移动端优化 */
    .nav-toggle {
		margin-top: 10px;
		margin-bottom: 10px;
        padding: 0.4rem 0.8rem;
        font-size: 0.875rem;
    }

    /* 确保内容不会被侧边栏遮挡 */
    .home-wrapper {
        position: relative;
        width: 100%;
        overflow-x: hidden;
    }
}
</style>