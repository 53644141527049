import axios from 'axios'
import { setupCache } from 'axios-cache-interceptor'
import { useShowToast } from '@/composables/useShowToast'

import store  from '@/store'
import router from '@/router'

const { showToast } = useShowToast();

const instance = axios.create(
    {
        withCredentials: false,
    }
);

const axiosCache = setupCache(instance);

// 请求拦截器
let isNotified = false
axiosCache.interceptors.request.use(
    config => {
        // 从 store 获取最新的 token
        const token = store.state.token
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    error => {
        console.error('Request Error:', error)
        return Promise.reject(error)
    }
)

// 响应拦截器
axiosCache.interceptors.response.use(
    response => response,
    error => {
        let message = error.response?.data?.message || error.message || '请求失败, 请稍后再试'
        message = message === 'Network Error' ? '网络错误, 请稍后再试' : message
        const isCheckToken = error.config.url.includes('validLoginToken')

        if (isCheckToken) {
            return Promise.reject(error)
        }

        if (error.response?.status === 401) {
            store.commit('clearToken')

            if (router.currentRoute.value.meta.needAuth === true) {
                router.push({ name: '登录' })
            }

            message = '授权失效或未授权，请重新登录'

            if (!isNotified) {
                showToast('error', message, 'warning')
                isNotified = true
            }
        } else {
            showToast('error', message, 'warning')
        }

        return Promise.reject(error)
    }
)

// 初始化 token
const token = localStorage.getItem('token')
if (token) {
    axiosCache.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

// 重置通知状态的方法
export function resetNotificationStatus() {
    isNotified = false
}

// 订阅 store mutations
store.subscribe((mutation) => {
    if (mutation.type === 'setUser') {
        resetNotificationStatus()
    }
})

export default axiosCache;