<script setup>
// import cooltiHeader from './components/argon-dashboard-pro-2/header.vue';

import cooltiNavbar  from '@/components/argon-dashboard-pro-2/navbar.vue';
import cooltiSidebar from '@/components/argon-dashboard-pro-2/sidebar.vue';
import cooltiFooter  from '@/components/argon-dashboard-pro-2/footer.vue';
import cooltiLoader  from '@/components/argon-dashboard-pro-2/loader.vue';
import checkModal 	 from '@/components/argon-dashboard-pro-2/checkModal.vue';

import { ModalsContainer } 	from 'vue-final-modal'
import { useCache }			from '@/composables/useCache';
</script>

<template>
	<!-- hide in doc ready-->
	<cooltiLoader v-if="!ready" />

	<cooltiSidebar :hideSidebar="hideFramework" />

	<!-- if hideFramework, class '', else 'main-content position-relative border-radius-lg' -->
	<main class="main-content position-relative border-radius-lg d-flex flex-column min-vh-100" :class="{ 'ms-0' : hideFramework }">
		<cooltiNavbar v-if="!hideFramework" />

		<!-- 显示 views 内容 -->
		<div class="flex-grow-1">
			<router-view v-slot="{ Component }">
				<transition name="coolti-scale">
					<component :is="Component" />
				</transition>
			</router-view>
		</div>

		<!-- CheckModal -->
		<checkModal v-if="checkModal_Show" :type="checkModal_Type" :id="checkModal_ID" :url="checkModal_URL" :qrcode="checkModal_QRCode" />

		<cooltiFooter :hideFooter="hideFramework" />
	</main>

	<ModalsContainer />
</template>

<script>
const { checkCache } 	= useCache();

export default {
	components: {
		checkModal, cooltiNavbar, cooltiSidebar, cooltiFooter, cooltiLoader, ModalsContainer
	},
	data() {
		return {
			ready: this.$route.path !== '/' ? false : true,

			checkModal_Show: false,
			checkModal_Type: 'order',
			checkModal_ID: null,
			checkModal_URL: null,
			checkModal_QRCode: null,

			hideFramework: false,
			bodyClass: '',
		}
	},
	mounted() {
		// * 每 1 分钟运行一次 缓存控制
		setInterval(() => {  checkCache(); }, 60000);

		// * 1 秒后显示页面
		setTimeout(() => { this.ready = true }, 1000);

		// * UA 检查
		// MicroMessenger
		if (navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1) {
			alert('请勿使用微信浏览器，请使用正常浏览器访问');
		}

		// if router.meta.needAuth
		if (this.$route.meta.needAuth) {
			this.$store.dispatch('getUserData')
		}
	},
	provide() {
		return {
			checkModalHandler: this.checkModalHandler,
			SwitchFramework: this.SwitchFramework,
		}
	},
	methods: {
		checkModalHandler(data) {
			this.checkModal_Show  = data.show || false;
			this.checkModal_ID    = data.id || null;
			this.checkModal_Type  = data.type || 'order'
			this.checkModal_URL   = data.url || null;
			this.checkModal_QRCode= data.qrcode || null;
		},
		SwitchFramework(op) {
			if (op === true) {
				this.hideFramework = true;
				this.bodyClass = document.body.classList.toString();
				document.body.classList = '';
			} else {
				this.hideFramework = false;
				document.body.classList = this.bodyClass;
				this.bodyClass = '';
			}
		},
	},
}
</script>

<style>
/* ... existing code ... */

/* 添加滚动条样式 */
body {
  	overflow-y: scroll;
	scrollbar-width: thin;
	scrollbar-color: rgba(0,0,0,0.2) transparent;
}

::-webkit-scrollbar {
  	width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--bs-gray-100);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: var(--bs-gray-400);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--bs-gray-500);
}
</style>